import { useTheme } from '@mui/material/styles';

import { TypingLoader } from '@/components/elements/TypingLoader';

import { MessageSender } from '../../types';
import { ChatMessage } from '../ChatMessage';

export const LoadingResponseMessage = () => {
  const { palette } = useTheme();

  return (
    <ChatMessage
      assistantIconPulse
      author={MessageSender.assistant}
      message={<TypingLoader color={palette.app.secondary} />}
    />
  );
};
