import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { MessageContentBox } from './MessageContentBox';

interface Props {
  message: string | React.ReactNode;
}

export const UserMessage = ({ message }: Props) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="flex-start"
      pl={[4, 10, 20]}
      alignSelf="flex-end"
      data-test="assistant-message-user"
    >
      <MessageContentBox message={message} />
      {/* <Box height={32} width={32} display="flex" alignItems="center" justifyContent="center" pt={1}>
        <UserMessageAvatar />
      </Box> */}
    </Stack>
  );
};

export const UserMessageAvatar = () => {
  const { palette } = useTheme();

  return <Box bgcolor={palette.secondarySpectrum[90]} borderRadius="50%" height={32} width={32} />;
};
