import AutoRenewIcon from '@mui/icons-material/Autorenew';

import { useAppNavigate } from '@/hooks';
import { Pages } from '@/navigation';
import { OrderGtmEvent, trackEvent, trackGTMEvent } from '@/services';
import { useAppTranslation } from '@/translation';

import { useShowPlanBoxes } from '../hooks/useShowPlanBoxes';

import { HomeBox } from './HomeBox';

export const ExpiringPlanBox = () => {
  const { t } = useAppTranslation();
  const { navigate } = useAppNavigate();
  const { hoursToExpire, showExpiringPlanBox, daysToExpire, currentPlan } = useShowPlanBoxes();

  const currentPlanType = currentPlan?.type;

  if (!showExpiringPlanBox) return null;

  const openOrderSummaryWithSelectedPlan = () => {
    if (!currentPlanType) return;

    trackGTMEvent(OrderGtmEvent.extendFromHome);
    trackEvent.ecommerce.extendPlan();

    navigate(Pages.userSubscriptionSummary, {}, { plan: currentPlanType });
  };

  const title =
    daysToExpire <= 1
      ? t('home:yourPlanIsAboutToExpireInHours', { planName: currentPlanType, count: hoursToExpire })
      : t('home:yourPlanIsAboutToExpireInDays', { planName: currentPlanType, count: daysToExpire });

  return (
    <HomeBox
      title={title}
      message={t('home:toNotLooseAccessToPlanFeaturesRenewYourPlan', {
        planName: currentPlanType,
      })}
      buttonLabel={t('common:extendPlan')}
      onClick={openOrderSummaryWithSelectedPlan}
      surfaceVariant="secondaryContainer"
      buttonIcon={<AutoRenewIcon />}
    />
  );
};
