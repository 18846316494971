import { useCallback, useMemo } from 'react';

import { Subscriptions } from '@/appTypes/Subscription';
import { useAppSelector } from '@/hooks';

export enum Features {
  quizHistory = 'quizHistory',
  quizHistoryUpTo10 = 'quizHistoryUpTo10',
  selectingArticles = 'selectingArticles',
  learningMode = 'learningMode',
  explanationAnswers = 'explanationAnswers',
}

const freePlanFeatures = {
  [Features.quizHistory]: false,
  [Features.quizHistoryUpTo10]: true,
  [Features.selectingArticles]: false,
  [Features.learningMode]: false,
  [Features.explanationAnswers]: false,
};

const proPlanFeatures = {
  [Features.quizHistory]: true,
  [Features.quizHistoryUpTo10]: false,
  [Features.selectingArticles]: true,
  [Features.learningMode]: true,
  [Features.explanationAnswers]: true,
};

export const useFeatureAvailable = () => {
  const { currentUser } = useAppSelector((state) => state.user);
  const currentPlan = currentUser?.subscription?.type;

  const isFeatureAvailable = useCallback(
    (feature: Features) => {
      switch (currentPlan) {
        case Subscriptions.FREE:
          return freePlanFeatures[feature];
        case Subscriptions.PRO:
          return proPlanFeatures[feature];
        default:
          return false;
      }
    },
    [currentPlan],
  );

  const features = useMemo(
    () =>
      Object.keys(Features).reduce(
        (acc, feature) => {
          acc[feature as Features] = isFeatureAvailable(feature as Features);
          return acc;
        },
        {} as Record<Features, boolean>,
      ),
    [isFeatureAvailable],
  );

  return features;
};
