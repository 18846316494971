import { Stack, Box } from '@mui/material';

import { Article, ArticlesArray, GroupsOfArticles } from '@/appTypes';
import { PlanLimitBadge, Text } from '@/components/elements';
import { TranslationKeys, useAppTranslation } from '@/translation';

import { ArticleGroupContainer } from './styled';

interface ArticleGroupProps {
  groupKey: string;
  articles: Article[];
  disabled: boolean;
  isSelected: boolean;
  isSelectingArticlesAvailable: boolean;
  onClick: () => void;
}

export const ArticleGroup = ({
  articles,
  groupKey,
  disabled,
  isSelected,
  isSelectingArticlesAvailable,
  onClick,
}: ArticleGroupProps) => {
  const { t } = useAppTranslation();

  const handleOnClick = () => {
    if (disabled) return;

    onClick();
  };
  const textColor = isSelected ? 'onPrimaryContainer' : 'onSurfaceVariant';

  return (
    <Box position="relative">
      <ArticleGroupContainer isSelected={isSelected} variant="outlined" onClick={handleOnClick} disabled={disabled}>
        <Stack direction="column" spacing={1} sx={{ opacity: disabled ? 0.7 : 1 }} px={4} py={3}>
          <Text
            variant="labelLarge"
            translationKey={('common:articleGroup.' + groupKey) as TranslationKeys}
            color={textColor}
          />

          <Stack>
            {articles.map((article) => {
              const articleNumber = ArticlesArray.findIndex((a) => a === article) + 1;

              return (
                <Stack key={article} spacing={1} direction="row">
                  <Text variant="bodySmall" display="inline-block" color={textColor}>
                    {articleNumber}
                    {'.'}
                  </Text>
                  <Text variant="bodySmall" display="inline-block" color={textColor}>
                    {t(('common:articlesX.' + article) as TranslationKeys)}
                  </Text>
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      </ArticleGroupContainer>

      {!isSelectingArticlesAvailable && groupKey === GroupsOfArticles.fieldAndBall && (
        <Box
          position="absolute"
          left="0"
          right="0"
          top="0"
          bottom="0"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <PlanLimitBadge forPlan="PRO" extendedMessage />
        </Box>
      )}
    </Box>
  );
};
