import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { ListResponse, Quiz } from '@/appTypes';
import { useFeatureAvailable } from '@/features/User';
import { axiosInstance } from '@/services';

export type GetQuizzesResponse = ListResponse<Quiz>;

export const GetQuizzesRequestName = 'quizzes';

const pageSize = 15;

export const useGetQuizzes = () => {
  const { quizHistory, quizHistoryUpTo10 } = useFeatureAvailable();
  const isUserAllowedToFetchQuizzes = quizHistory || quizHistoryUpTo10;

  const getQuizzesRequest = async (page = 1) => {
    const response = await axiosInstance.get<GetQuizzesResponse>(`/quizzes/`, {
      params: {
        page,
        pageSize,
      },
    });

    if (quizHistoryUpTo10) {
      return {
        results: response.data.results.slice(0, 10),
        count: Math.min(response.data.count, 10),
        next: null,
        previous: null,
      } as GetQuizzesResponse;
    }

    return response.data || [];
  };

  const { data, hasNextPage, isLoading, fetchNextPage, isFetching, isFetchingNextPage } = useInfiniteQuery({
    queryKey: [GetQuizzesRequestName],
    initialPageParam: 1,
    queryFn: ({ pageParam }) => getQuizzesRequest(pageParam),
    refetchOnMount: isUserAllowedToFetchQuizzes,
    placeholderData: keepPreviousData,
    enabled: isUserAllowedToFetchQuizzes,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.next) return allPages.length + 1;

      return undefined;
    },
  });

  const quizzes: GetQuizzesResponse['results'] = useMemo(() => {
    if (!data) return [];

    return data.pages.flatMap((page) => page.results);
  }, [data]);

  const totalCount = data?.pages[data.pages.length - 1].count;

  const handleFetchNextPage = () => {
    if (!isUserAllowedToFetchQuizzes || isLoading || isFetching || isFetchingNextPage || !hasNextPage) return;

    fetchNextPage();
  };

  return {
    quizzes,
    totalCount,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage: handleFetchNextPage,
    isUserAllowedToFetchQuizzes,
  };
};
