import { useMutation } from '@tanstack/react-query';

import { axiosInstance } from '@/services';

interface ReportQuestionPayload {
  questionId: string;
  reason: string;
}

export const useReportQuestion = () => {
  const reportQuestionRequest = async (payload: ReportQuestionPayload) => {
    const response = await axiosInstance.post(`/question_report/`, {
      question: payload.questionId,
      content: payload.reason,
    });

    return response.data;
  };

  const { mutateAsync, isPending: isLoading } = useMutation({
    mutationFn: reportQuestionRequest,
  });

  return { reportQuestion: mutateAsync, isLoading };
};
