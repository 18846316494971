import { useCallback } from 'react';

import { ArticlesArray, GroupedArticles, GroupsOfArticles } from '@/appTypes';
import { useAppTranslation } from '@/translation';

export const useFormatRelatedArticlesToGroups = () => {
  const { t } = useAppTranslation();

  const formatRelatedArticlesToGroups = useCallback(
    (relatedArticles: number[]) => {
      const groups: GroupsOfArticles[] = [];

      relatedArticles.forEach((articleNumber) => {
        const articleKey = ArticlesArray[articleNumber - 1];

        const group: GroupsOfArticles | undefined = (Object.keys(GroupedArticles) as GroupsOfArticles[]).find(
          (groupKey) => {
            const articleKeysInGroup = GroupedArticles[groupKey as GroupsOfArticles];
            return articleKeysInGroup.includes(articleKey);
          },
        );

        if (group && !groups.includes(group)) groups.push(group);
      });

      return groups.map((group) => t(`common:articleGroup.${group}`)).join(', ');
    },
    [t],
  );

  return { formatRelatedArticlesToGroups };
};
