import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Text } from '@/components/elements';
import { TranslationKeys, useAppTranslation } from '@/translation';

interface CookieDataProps {
  name: TranslationKeys;
  value: string;
}

const CookieData = ({ name, value }: CookieDataProps) => {
  return (
    <Stack spacing={2} direction="row">
      <Text variant="bodySmall" sx={{ minWidth: 75 }} translationKey={name} />
      <Text variant="bodySmall">{value}</Text>
    </Stack>
  );
};

export interface Cookie {
  name: string;
  duration: {
    years?: number;
    months?: number;
    days?: number;
    hours?: number;
  };
  description: TranslationKeys;
  service: string;
}

interface SingleCookieProps {
  cookie: Cookie;
}

export const SingleCookie = ({ cookie }: SingleCookieProps) => {
  const { t } = useAppTranslation();
  const { palette } = useTheme();

  const constructDuration = (duration: Cookie['duration']) => {
    const { years, months, days, hours } = duration;

    const yearsString = years ? t('common:years', { count: years }) : '';
    const monthsString = months ? t('common:months', { count: months }) : '';
    const daysString = days ? t('common:days', { count: days }) : '';
    const hoursString = hours ? t('common:hours', { count: hours }) : '';

    return `${yearsString} ${monthsString} ${daysString} ${hoursString}`;
  };

  return (
    <Stack spacing={1} sx={{ p: 2, borderRadius: 2, bgcolor: palette.app.surfaceContainerLow }}>
      <CookieData name="common:name" value={cookie.name} />
      <CookieData name="common:service" value={cookie.service} />
      <CookieData name="common:expiresIn" value={constructDuration(cookie.duration)} />
      <CookieData name="common:purpose" value={t(cookie.description)} />
    </Stack>
  );
};
