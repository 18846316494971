import { Helmet } from 'react-helmet';

import { useAppConfig, useGetSeoStructuredData } from '@/features/SEO';

export const HeadHelmet = () => {
  const {
    category,
    bannerAlt,
    bannerHeight,
    bannerUrl,
    bannerWidth,
    appName,
    currentLanguage,
    loginPageDescription,
    sameAs,
    loginPageTitle,
  } = useAppConfig();

  const { seoStructuredData } = useGetSeoStructuredData();

  return (
    <Helmet titleTemplate={`${appName} - %s`} defaultTitle={appName}>
      <html lang={currentLanguage} />
      <meta lang={currentLanguage} />
      <meta name="description" content={loginPageDescription} />
      <meta name="author" content={appName} />
      <meta name="application-name" content={appName} />
      <script type="application/ld+json">{JSON.stringify(seoStructuredData)}</script>
      <meta property="og:title" content={loginPageTitle} />
      <meta property="og:description" content={loginPageDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={bannerUrl} />
      <meta property="og:image:alt" content={bannerAlt} />
      <meta property="og:image:width" content={`${bannerWidth}px`} />
      <meta property="og:image:height" content={`${bannerHeight}px`} />
      <meta property="og:locale" content={currentLanguage} />
      <meta property="og:site_name" content={appName} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:see_also" content={sameAs.join(',')} />
      <meta property="og:category" content={category} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@refereewise" />
      <meta name="twitter:creator" content="@refereewise" />
      <meta name="twitter:title" content={loginPageTitle} />
      <meta name="twitter:description" content={loginPageDescription} />
      <meta name="twitter:image" content={bannerUrl} />
      <meta name="twitter:image:alt" content={bannerAlt} />
      <meta name="twitter:image:width" content={`${bannerWidth}px`} />
      <meta name="twitter:image:height" content={`${bannerHeight}px`} />
    </Helmet>
  );
};
