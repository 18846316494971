import { Box, Stack } from '@mui/material';

import { AssistantIcon } from '../AssistantIcon';

import { MessageContentBox } from './MessageContentBox';

interface Props {
  message: string | React.ReactNode;
  showRating?: boolean;
  hideAvatar?: boolean;
  onRateClick?: (rate: number) => void;
  rate?: number;
  isRatingLoading?: boolean;
  assistantIconPulse?: boolean;
}

export const AssistantMessage = ({
  message,
  showRating,
  hideAvatar,
  onRateClick,
  rate,
  isRatingLoading,
  assistantIconPulse,
}: Props) => {
  return (
    <Stack direction="row" spacing={2} alignItems="flex-start" pr={[4, 10, 20]} data-test="assistant-message-assistant">
      <Box
        height={32}
        width={32}
        display="flex"
        alignItems="center"
        justifyContent="center"
        visibility={hideAvatar ? 'hidden' : 'visible'}
        pt={1}
      >
        <AssistantIcon pulse={assistantIconPulse} />
      </Box>
      <MessageContentBox
        message={message}
        showRating={showRating}
        onRateClick={onRateClick}
        rate={rate}
        isRatingLoading={isRatingLoading}
      />
    </Stack>
  );
};
