import { Box } from '@mui/material';
import { CellContext } from '@tanstack/react-table';

import { Text, TextProps } from '../Text';

type DefaultCellProps<T> = CellContext<T, unknown>;

export const DefaultCell = <T extends object>({ cell, renderValue }: DefaultCellProps<T>) => {
  return (
    <Cell>
      {cell?.getValue<never>()}
      {renderValue<never>()}
    </Cell>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Cell = ({ children, ref, ...props }: TextProps) => {
  return (
    <Box display="flex" flex={1} width="100%" justifyContent="center" alignItems="center">
      <Text flex={1} variant="bodyMedium" {...props}>
        {children}
      </Text>
    </Box>
  );
};
