import { Box, Button, Stack } from '@mui/material';

import { SomethingNewBadge, Text } from '@/components/elements';
import { Surface, SurfaceVariant } from '@/components/layout';

interface HomeBoxProps {
  title: string;
  message?: string;
  messageNode?: React.ReactNode;
  customButton?: React.ReactNode;
  buttonLabel?: string;
  onClick?: () => void;
  buttonIcon?: React.ReactNode;
  surfaceVariant: SurfaceVariant;
  somethingNewBadge?: boolean;
  buttonHint?: string;
  buttonTestId?: string;
}

export const HomeBox = ({
  title,
  message,
  messageNode,
  customButton,
  buttonLabel,
  onClick,
  buttonIcon,
  surfaceVariant,
  somethingNewBadge,
  buttonHint,
  buttonTestId,
}: HomeBoxProps) => {
  const buttonColor = surfaceVariant === 'secondaryContainer' ? 'secondary' : 'primary';

  return (
    <Surface variant={surfaceVariant}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        columnGap={4}
        rowGap={4}
        flexWrap={['wrap', 'nowrap']}
      >
        <Stack spacing={2}>
          {somethingNewBadge && <SomethingNewBadge />}
          <Text variant="titleLarge" color="onSurface">
            {title}
          </Text>
          {!!message && (
            <Text variant="bodyMedium" color="onSurface">
              {message}
            </Text>
          )}
          {!!messageNode && messageNode}
        </Stack>

        <Box display="flex" flex={1} alignItems="center" justifyContent={['center', 'flex-end']}>
          <Stack spacing={2} alignItems="center">
            {customButton}
            {!customButton && (
              <Button
                variant="contained"
                color={buttonColor}
                endIcon={buttonIcon}
                onClick={onClick}
                sx={{ whiteSpace: 'nowrap', minWidth: 145 }}
                data-test={buttonTestId}
              >
                {buttonLabel}
              </Button>
            )}
            {!!buttonHint && (
              <Text variant="labelMedium" color="onSurfaceVariant" data-test={`${buttonTestId}-hint`}>
                {buttonHint}
              </Text>
            )}
          </Stack>
        </Box>
      </Stack>
    </Surface>
  );
};
