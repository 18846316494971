import BackIcon from '@mui/icons-material/ArrowBack';
import { Button, ButtonProps } from '@mui/material';

import { useAppNavigate } from '@/hooks';
import { useAppTranslation } from '@/translation';

type Props = ButtonProps;

export const BackButton = (props: Props) => {
  const { t } = useAppTranslation();
  const { back } = useAppNavigate();

  return (
    <Button variant="tonal" startIcon={<BackIcon />} onClick={back} {...props}>
      {t('common:back')}
    </Button>
  );
};
