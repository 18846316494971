import { Box, Checkbox, FormControlLabel, FormHelperText } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactNode } from 'react';
import { useController, useFormContext } from 'react-hook-form';

interface ControlledCheckboxProps {
  id: string;
  name: string;
  disabled?: boolean;
  label: string | ReactNode;
}

export const ControlledCheckbox = ({ id, name, disabled, label }: ControlledCheckboxProps) => {
  const { formState } = useFormContext();
  const { isSubmitting } = formState;
  const { field, fieldState } = useController({ name });
  const { palette } = useTheme();

  return (
    <Box>
      <FormControlLabel
        {...field}
        id={id}
        control={<Checkbox color="primary" sx={{ color: palette.app.outline }} />}
        label={label}
        sx={{ fontSize: 14 }}
        disabled={isSubmitting || disabled}
      />
      <FormHelperText error={!!fieldState.error} sx={{ marginTop: 0 }} id={`${name}-helper-text`}>
        {fieldState.error?.message}
      </FormHelperText>
    </Box>
  );
};
