import { WithContext, WebApplication } from 'schema-dts';

import { useAppConfig } from './useAppConfig';

export const useGetSeoStructuredData = () => {
  const {
    category,
    bannerAlt,
    bannerHeight,
    bannerUrl,
    bannerWidth,
    appName,
    baseUrl,
    currentLanguage,
    loginPageDescription,
    sameAs,
    loginPageTitle,
  } = useAppConfig();

  const applicationData = {
    '@context': 'https://schema.org',
    '@type': 'WebApplication',
    name: loginPageTitle,
    url: `${baseUrl}login`,
    description: loginPageDescription,
    inLanguage: currentLanguage,
    applicationCategory: category,
    sameAs,
    image: {
      '@type': 'ImageObject',
      contentUrl: bannerUrl,
      width: `${bannerWidth}px`,
      height: `${bannerHeight}px`,
      alternateName: bannerAlt,
    },
    publisher: {
      '@type': 'Organization',
      name: appName,
      url: baseUrl,
    },
  } as WithContext<WebApplication>;

  const seoStructuredData = {
    '@context': 'https://schema.org',
    '@graph': [applicationData],
  };

  return { seoStructuredData };
};
