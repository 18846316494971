import { Capacitor } from '@capacitor/core';

import { UseSSOLogin } from './types';
import { useSSOLogin as nativeModule } from './useSsoLogin.native';
import { useSSOLogin as webModule } from './useSsoLogin.web';

export const useSSOLogin: UseSSOLogin = () => {
  if (Capacitor.isNativePlatform()) return nativeModule();
  else return webModule();
};
