import { LoadingButton } from '@mui/lab';
import { ButtonProps, CircularProgress } from '@mui/material';
import { useFormContext } from 'react-hook-form';

type ControlledButtonProps = ButtonProps;

export const ControlledButton = ({ ...inputProps }: ControlledButtonProps) => {
  const { formState } = useFormContext();
  const { isSubmitting } = formState;

  return (
    <LoadingButton
      {...inputProps}
      loading={isSubmitting}
      loadingIndicator={<CircularProgress color="primary" size={16} />}
    />
  );
};
