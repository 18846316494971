import { createSlice } from '@reduxjs/toolkit';

import { Subscription } from '@/appTypes/Subscription';

import { Currency } from '../types';

interface SubscriptionState {
  subscription: Subscription | null;
  duration: number | null;
  price: string | null;
  currency: Currency | null;
}

const initialState: SubscriptionState = {
  duration: null,
  price: null,
  subscription: null,
  currency: null,
};

interface BeginCheckoutPayload {
  subscription: Subscription;
  duration: number;
  price: string;
  currency: Currency;
}

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    selectSubscription: (state, { payload }: { payload: BeginCheckoutPayload }) => {
      const { duration, price, subscription } = payload;
      state.duration = duration;
      state.price = price;
      state.subscription = subscription;
      state.currency = payload.currency;
    },
    setSubscriptionInitialState: () => initialState,
  },
});

export const { selectSubscription, setSubscriptionInitialState } = subscriptionSlice.actions;
