import { Thread } from '@/features/Chat/types';
import { TranslationKeys } from '@/translation';
import { Language } from '@/translation/locales';

export enum Article {
  theFieldOfPlay = 'theFieldOfPlay',
  theBall = 'theBall',
  theNumberOfPlayers = 'theNumberOfPlayers',
  thePlayersEquipment = 'thePlayersEquipment',
  theReferee = 'theReferee',
  theAssistantReferees = 'theAssistantReferees',
  theDurationOfTheMatch = 'theDurationOfTheMatch',
  theStartAndRestartOfPlay = 'theStartAndRestartOfPlay',
  theBallInAndOutOfPlay = 'theBallInAndOutOfPlay',
  theMethodOfScoring = 'theMethodOfScoring',
  offside = 'offside',
  foulsAndMisconduct = 'foulsAndMisconduct',
  freeKicks = 'freeKicks',
  thePenaltyKick = 'thePenaltyKick',
  theThrowIn = 'theThrowIn',
  theGoalKick = 'theGoalKick',
  theCornerKick = 'theCornerKick',
}

export const ArticlesArray = [
  Article.theFieldOfPlay,
  Article.theBall,
  Article.theNumberOfPlayers,
  Article.thePlayersEquipment,
  Article.theReferee,
  Article.theAssistantReferees,
  Article.theDurationOfTheMatch,
  Article.theStartAndRestartOfPlay,
  Article.theBallInAndOutOfPlay,
  Article.theMethodOfScoring,
  Article.offside,
  Article.foulsAndMisconduct,
  Article.freeKicks,
  Article.thePenaltyKick,
  Article.theThrowIn,
  Article.theGoalKick,
  Article.theCornerKick,
];

export enum GroupsOfArticles {
  fieldAndBall = 'fieldAndBall',
  playersAndClothes = 'playersAndClothes',
  referees = 'referees',
  gamePreparation = 'gamePreparation',
  offsideAndIllegalPlay = 'offsideAndIllegalPlay',
  gamePieces = 'gamePieces',
}

export const GroupedArticles: Record<GroupsOfArticles, Article[]> = {
  [GroupsOfArticles.fieldAndBall]: ArticlesArray.slice(0, 2),
  [GroupsOfArticles.playersAndClothes]: ArticlesArray.slice(2, 4),
  [GroupsOfArticles.referees]: ArticlesArray.slice(4, 6),
  [GroupsOfArticles.gamePreparation]: ArticlesArray.slice(6, 10),
  [GroupsOfArticles.offsideAndIllegalPlay]: ArticlesArray.slice(10, 12),
  [GroupsOfArticles.gamePieces]: ArticlesArray.slice(12, 17),
};

export enum QuizMode {
  learning = 'learning',
  exam = 'exam',
  reviewMistakes = 'mistakes_review',
}

export enum AnswerNotationOptionsKey {
  keepPlaying = 'keep_playing',
  directFreeKick = 'direct_free_kick',
  indirectFreeKick = 'indirect_free_kick',
  penaltyKick = 'penalty_kick',
  onceAgain = 'once_again',
  refereeThrowIn = 'referee_throw_in',
  throwIn = 'throw_in',
  cornerKick = 'corner_kick',
  endOfTheMatch = 'end_of_the_match',
  goalKick = 'goal_kick',
  goal = 'goal',
  reprimand = 'reprimand',
  exclusion = 'exclusion',
}

export const AnswerNotationBaseOptionsKeys: readonly AnswerNotationOptionsKey[] = [
  AnswerNotationOptionsKey.keepPlaying,
  AnswerNotationOptionsKey.directFreeKick,
  AnswerNotationOptionsKey.indirectFreeKick,
  AnswerNotationOptionsKey.penaltyKick,
  AnswerNotationOptionsKey.onceAgain,
  AnswerNotationOptionsKey.refereeThrowIn,
  AnswerNotationOptionsKey.throwIn,
  AnswerNotationOptionsKey.cornerKick,
  AnswerNotationOptionsKey.endOfTheMatch,
  AnswerNotationOptionsKey.goalKick,
  AnswerNotationOptionsKey.goal,
] as const;

export const AnswerNotationSanctionsOptionsKeys: readonly AnswerNotationOptionsKey[] = [
  AnswerNotationOptionsKey.reprimand,
  AnswerNotationOptionsKey.exclusion,
] as const;

export const AnswerNotationOptions = Object.keys(AnswerNotationOptionsKey).reduce(
  (acc, key) => {
    acc[key as keyof typeof AnswerNotationOptionsKey] = {
      token: AnswerNotationOptionsKey[key as keyof typeof AnswerNotationOptionsKey],
      messageId: `common:notationAnswers.${key}` as TranslationKeys,
      symbolId: `common:notationAnswers.${key}Symbol` as TranslationKeys,
    };

    return acc;
  },
  {} as Record<
    keyof typeof AnswerNotationOptionsKey,
    { token: AnswerNotationOptionsKey; messageId: TranslationKeys; symbolId: TranslationKeys }
  >,
);

export type QuestionType = 'NOTATION' | 'MULTIPLE_CHOICE' | 'SINGLE_CHOICE';

export interface Answer {
  id: string | AnswerNotationOptionsKey;
  answer: string | AnswerNotationOptionsKey;
}

export interface Question {
  id: string;
  answerType: QuestionType;
  content: string;
  answer: Answer[];
  relatedArticle: number;
  possibleAnswers: Answer[];
  explanation: string | undefined;
  reportedAt: string | null;
}

export interface QuizQuestion {
  id: string;
  question: Question;
  correctAnswerProvided: boolean | null;
  flagged: boolean;
  answerProvided: Answer[] | null;
  chatThreads: Thread[];
}

export interface Quiz {
  id: string;
  language: Language;
  createdAt: string;
  quizQuestions: QuizQuestion[];
  questionsAmount: number;
  positiveAnswersCount: number;
  unansweredCount: number;
  relatedArticles: number[];
  mode: QuizMode;
  completeDate: string | null;
}

export const PassRatePercentages = 80;
