import { Quiz, QuizMode } from '@/appTypes';

export const getFirstUnansweredQuestionIndex = (quiz?: Quiz | null) => {
  const mode = quiz?.mode;
  const indexOfUnansweredQuestion = quiz?.quizQuestions.findIndex((question) => {
    // In exam mode we always checkAnswer before changing the question to save state
    // so we have to check if the question has an answer provided
    if (mode === QuizMode.exam) return !question.answerProvided?.length;
    else return question.correctAnswerProvided === null;
  });

  return indexOfUnansweredQuestion === -1 ? undefined : indexOfUnansweredQuestion;
};
