import { axiosInstance } from '@/services';

interface Payload {
  name: string;
  registrationId: string;
  deviceId: string;
  type: 'android' | 'ios' | 'web';
}

export type PlatformType = 'android' | 'ios' | 'web';

export const registerFCMDeviceToken = async (payload: Payload): Promise<void> => {
  await axiosInstance.post('/devices/', payload);
};
