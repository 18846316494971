import { axiosInstance } from '@/services';

interface RemoveAccountPayload {
  userId: string;
}

export const removeAccountRequest = async ({ userId }: RemoveAccountPayload): Promise<boolean> => {
  const response = await axiosInstance.delete(`/users/${userId}`);

  return response.status === 200;
};
