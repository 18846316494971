import { Box, Chip, ChipProps, alpha, styled } from '@mui/material';

import { Text } from './Text';

interface SelectableChipStyledProps extends ChipProps {
  isSelected: boolean;
  isError?: boolean;
}
const SelectableChipStyled = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'defaultBackgroundColor' && prop !== 'isError',
})<SelectableChipProps>(({ isSelected, isError, theme: { palette } }) => {
  const borderColor = () => {
    if (isError) return palette.error.main;

    if (isSelected) return 'transparent';

    return palette.app.outline;
  };

  return {
    backgroundColor: isSelected ? palette.app.primaryContainer : 'transparent',
    borderColor: borderColor(),
    '& .MuiChip-label, & .MuiChip-icon': {
      color: isSelected ? palette.app.onPrimaryContainer : palette.app.onSurfaceVariant,
    },
    '@media (hover: hover)': {
      '&.MuiChip-clickable:hover': {
        backgroundColor: isSelected ? alpha(palette.app.primaryContainer, 0.5) : 'rgba(0, 98, 159, 0.04)',
        borderColor: isSelected ? palette.app.primaryFixedDim : palette.app.outline,
      },
    },
    '@media (hover: none)': {
      '&.MuiChip-clickable:hover': {
        backgroundColor: isSelected ? palette.app.primaryContainer : 'transparent',
        borderColor: borderColor(),
      },
    },
  };
});

interface SelectableChipProps extends SelectableChipStyledProps {
  symbol?: string;
}

export const SelectableChip = ({ symbol, isSelected, isError, ...rest }: SelectableChipProps) => {
  return (
    <SelectableChipStyled
      {...rest}
      isSelected={isSelected}
      isError={isError}
      icon={
        symbol ? (
          <Box sx={{ px: 1 }}>
            <Text variant="labelLarge" fontWeight={900}>
              {symbol}
            </Text>
          </Box>
        ) : undefined
      }
    />
  );
};
