import { Box, Stack } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';

import { useAppSelector, useIsPage } from '@/hooks';
import { Pages } from '@/navigation';
import { useAppTranslation } from '@/translation';

import { StartNewThreadPayload, useContinueThread, useRateMessage, useStartNewThread } from '../api';
import { useCurrentThread, useAskNewQuestionFromStore as useAskNewQuestionFromStore } from '../hooks';
import { MessageSender, Message, Thread } from '../types';

import { ChatMessage } from './ChatMessage';
import { HowShouldUserAskInstructions } from './HowShouldUserAskInstructions';
import { HowShouldUserAskStartButton } from './HowShouldUserAskInstructions/HowShouldUserAskStartButton';
import { MaxTwoQuestionPerThreadInfo } from './MaxTwoQuestionPerThreadInfo';
import { MessageSkeleton } from './MessageSkeleton';
import { QuestionInput } from './QuestionInput';
import { StartNewThreadButton } from './StartNewThreadButton';
import { LackOfThreadsMessage } from './messages/LackOfThreadsMessage';
import { LoadingResponseMessage } from './messages/LoadingResponseMessage';

interface Props {
  encouragingMessageKey?: 'chat:encouragingMessages.quizzesPage' | 'chat:encouragingMessages.homeAndChatPage';
  quizQuestionId?: string;
  quizId?: string;
  thread?: Thread | null;
  isLoadingThread?: boolean;
  allowToStartNewThread?: boolean;
  showInstructions?: boolean;
}

export const AssistantChat = ({
  encouragingMessageKey,
  thread,
  quizId,
  isLoadingThread,
  allowToStartNewThread,
  quizQuestionId,
  showInstructions: canShowInstructions,
}: Props) => {
  const { t } = useAppTranslation();

  const [question, setQuestion] = useState('');
  const [askedQuestion, setAskedQuestion] = useState('');
  const [showInstructions, setShowInstructions] = useState(false);

  const { openThread } = useCurrentThread();
  const { startNewThread, isLoading: isLoadingStartNewThread } = useStartNewThread({ quizId });
  const { continueThread, isLoading: isLoadingContinueThread } = useContinueThread({
    threadId: thread?.id,
    quizId,
    quizQuestionId,
  });
  const { rateMessage, isLoading: isRatingLoading } = useRateMessage({ threadId: thread?.id, quizId, quizQuestionId });

  const isOnHomePage = useIsPage(Pages.home);
  const isOnQuizzesPages = useIsPage([Pages.quiz, Pages.quizSummary]);
  const availableThreads = useAppSelector((store) => store.user.currentUser?.subInfo?.chat.availableThreads);

  const isLoadingMutation = isLoadingStartNewThread || isLoadingContinueThread;
  const showAskedQuestionWhileLoading = isLoadingMutation && askedQuestion;
  const messages: Message[] = useMemo(() => {
    if (showAskedQuestionWhileLoading && !thread)
      return [{ sender: MessageSender.user, content: askedQuestion, rating: 0 }];

    if (showAskedQuestionWhileLoading && thread)
      return [...thread.messages, { sender: MessageSender.user, content: askedQuestion, rating: 0 }];

    return thread?.messages || [];
  }, [showAskedQuestionWhileLoading, thread, askedQuestion]);

  const lackOfThreads = availableThreads === 0;
  const showLackOfThreads = availableThreads === 0 && !thread;
  const showStartNewThreadButton = !!thread && thread.messages.length >= 2 && allowToStartNewThread;
  const richedMaxTwoQuestions = !!thread && thread.messages.length >= 3;
  const showMaxTwoQuestionsInfo = isOnQuizzesPages && richedMaxTwoQuestions;
  const showHowShouldUserAsksInstructions = !thread && !showLackOfThreads && !isLoadingThread;
  const disableQuestionInput =
    (richedMaxTwoQuestions && lackOfThreads) ||
    isLoadingMutation ||
    richedMaxTwoQuestions ||
    isLoadingThread ||
    showInstructions;

  const handleShowInstructions = () => setShowInstructions(true);

  const { startNewThreadOnChatPage } = useAskNewQuestionFromStore({
    onNewQuestion: (question) => startAndOpenThread({ question, relatedQuestionId: quizQuestionId }),
  });

  const onSubmit = (question: string) => {
    setQuestion('');
    setAskedQuestion(question);

    if (isOnHomePage) {
      startNewThreadOnChatPage(question);
      return;
    }

    if (thread) {
      continueThread({ question });
      return;
    }

    startAndOpenThread({ question, relatedQuestionId: quizQuestionId });
  };

  const startAndOpenThread = useCallback(
    async (payload: StartNewThreadPayload) => {
      setQuestion('');
      setAskedQuestion(payload.question);

      const result = await startNewThread(payload);
      if (result?.id && !isOnQuizzesPages) openThread(result.id);
    },
    [startNewThread, isOnQuizzesPages, openThread],
  );

  return (
    <Stack spacing={4} width="100%">
      <Stack spacing={2} width="100%">
        {!!encouragingMessageKey && <ChatMessage author={MessageSender.assistant} message={t(encouragingMessageKey)} />}
        {showLackOfThreads && <LackOfThreadsMessage showAvatar={!encouragingMessageKey} />}
      </Stack>

      {messages.map((message, index) => (
        <ChatMessage
          key={index}
          message={message.content}
          author={message.sender}
          showRating
          isRatingLoading={isRatingLoading}
          rate={message.rating}
          onRateClick={(rate) => rateMessage({ messageIndex: index, rate })}
        />
      ))}

      {isLoadingMutation && <LoadingResponseMessage />}
      {isLoadingThread && (
        <>
          <MessageSkeleton author={MessageSender.user} />
          <MessageSkeleton author={MessageSender.assistant} />
        </>
      )}

      {canShowInstructions && showHowShouldUserAsksInstructions && !showInstructions && (
        <HowShouldUserAskStartButton onShowInstructions={handleShowInstructions} />
      )}
      {canShowInstructions && showInstructions && (
        <HowShouldUserAskInstructions onInstructionsClose={() => setShowInstructions(false)} />
      )}

      {showStartNewThreadButton && <StartNewThreadButton variant={richedMaxTwoQuestions ? 'contained' : 'outlined'} />}
      {showMaxTwoQuestionsInfo && <MaxTwoQuestionPerThreadInfo />}

      {!showMaxTwoQuestionsInfo && (
        <Box pt={2}>
          <QuestionInput
            onQuestionChange={setQuestion}
            onQuestionSubmit={() => onSubmit(question)}
            question={question}
            disabled={disableQuestionInput}
            isThreadOpen={!!thread || !!isLoadingThread}
          />
        </Box>
      )}
    </Stack>
  );
};
