import { CustomVariant, Typography, TypographyProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactNode, forwardRef, useMemo } from 'react';

import { ColorPaletteKey } from '@/styles/theme';
import { TranslationKeys, useAppTranslation } from '@/translation';
import { Language } from '@/translation/locales';

export interface TextProps extends Omit<TypographyProps, 'color'> {
  selectable?: boolean;
  variant?: CustomVariant;
  opacity?: number;
  translationKey?: TranslationKeys;
  translationParams?: Record<string, string | number | ReactNode>;
  lng?: Language;
  color?: ColorPaletteKey;
  // Will be replaced by color prop
  customColor?: string;
}

export const Text = forwardRef<HTMLInputElement, TextProps>(
  (
    {
      translationKey,
      selectable,
      variant,
      opacity,
      children,
      translationParams,
      customColor,
      color,
      sx,
      lng,
      ...props
    },
    ref,
  ) => {
    const { palette } = useTheme();
    const { t } = useAppTranslation();

    const textColor = useMemo(() => {
      if (color && palette.app[color]) return palette.app[color];

      if (customColor) return customColor;
      return undefined;
    }, [color, customColor, palette.app]);

    return (
      <Typography
        {...props}
        variant={variant}
        component="span"
        sx={{ userSelect: selectable ? 'text' : 'none', opacity, color: textColor, ...sx }}
        ref={ref}
      >
        {translationKey ? t(translationKey, { ...translationParams, lng }) : children}
      </Typography>
    );
  },
);
