import { Box, Button } from '@mui/material';

import { useAppTranslation } from '@/translation';

import { useCurrentThread } from '../hooks';

interface Props {
  variant: 'outlined' | 'contained';
  customOnClick?: () => void;
}

export const StartNewThreadButton = ({ variant, customOnClick }: Props) => {
  const { t } = useAppTranslation();

  const { resetThread: startNewThread } = useCurrentThread();

  const handleClick = () => {
    if (customOnClick) customOnClick();
    else startNewThread();
  };

  return (
    <Box display="flex" justifyContent="center">
      <Button variant={variant} onClick={handleClick} size="small">
        {t('chat:startNewThread')}
      </Button>
    </Box>
  );
};
