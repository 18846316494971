import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm, useFormContext } from 'react-hook-form';
import { z } from 'zod';

import { useNotify } from '@/hooks';
import { captureError } from '@/services';
import { useAppTranslation } from '@/translation';

import { useSendCompletedSurveyAnswer } from '../api';
import {
  BasicQuestionKey,
  BooleanAnswer,
  ExtendedQuestionKey,
  ScaleAnswer,
  SurveyQuestionAnswer,
  SurveyType,
} from '../types';

const basicSurveyFormSchema = z.object({
  [BasicQuestionKey.preparedForExam]: z.nativeEnum(ScaleAnswer),
  [BasicQuestionKey.userSuggestions]: z.string().optional(),
});

const extendedSurveyFormSchema = z.object({
  [ExtendedQuestionKey.difficultyLevel]: z.nativeEnum(ScaleAnswer),
  [ExtendedQuestionKey.willBackToUsAgain]: z.nativeEnum(ScaleAnswer),
  [ExtendedQuestionKey.willRecommendUs]: z.nativeEnum(BooleanAnswer),
  [ExtendedQuestionKey.userSuggestions]: z.string().optional(),
});

type BasicSurveyFormValues = z.infer<typeof basicSurveyFormSchema>;
type ExtendedSurveyFormValues = z.infer<typeof extendedSurveyFormSchema>;

export type SurveyFormValues<T extends SurveyType> = T extends 'basic'
  ? BasicSurveyFormValues
  : ExtendedSurveyFormValues;

interface UseCompletingSurveyFormProps<T extends SurveyType> {
  type: T;
  onComplete?: () => void;
}

export const useCompletingSurveyForm = <T extends SurveyType>({
  type,
  onComplete,
}: UseCompletingSurveyFormProps<T>) => {
  const { sendCompletedSurveyAnswer } = useSendCompletedSurveyAnswer();
  const { notify } = useNotify();
  const { t } = useAppTranslation();

  const form = useForm<T extends 'basic' ? BasicSurveyFormValues : ExtendedSurveyFormValues>({
    resolver: zodResolver(type === 'basic' ? basicSurveyFormSchema : extendedSurveyFormSchema),
  });
  const { handleSubmit, formState } = form;

  const onSubmit: SubmitHandler<SurveyFormValues<T>> = async (data) => {
    try {
      const answers: SurveyQuestionAnswer[] = Object.entries(data).map(([key, value]) => ({
        question: key,
        answer: value?.toString() || null,
      }));

      const res = await sendCompletedSurveyAnswer({
        answers,
        surveyType: type,
      });

      if (res.status !== 201) throw new Error('unknown sendCompletedSurveyAnswer error');

      notify('success', t('survey:submittedSurveyThanks'));
      onComplete?.();
    } catch (err) {
      captureError(err);
      notify('error', t('common:errorOccurredTryAgain'));
      throw new Error('unknown sendCompletedSurveyAnswer error');
    }
  };

  const onSubmitPress = handleSubmit(onSubmit);

  const isSubmitting = formState.isSubmitting;

  return {
    form,
    onSubmitPress,
    isSubmitting,
    isSubmitSuccessful: formState.isSubmitSuccessful,
  };
};

export const useCompletingSurveyFormContext = <T extends SurveyType>() => useFormContext<SurveyFormValues<T>>();
