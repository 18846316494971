import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { createMigrate, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { isDev } from '@/config';
import { chatSlice } from '@/features/Chat/store';
import { quizSlice } from '@/features/Quiz/store';
import { subscriptionSlice } from '@/features/Subscription/store';
import { userSlice } from '@/features/User/store';
import { themeSlice } from '@/styles/theme';

import { storeMigrations } from './migrations';

const reducers = combineReducers({
  user: userSlice.reducer,
  chat: chatSlice.reducer,
  quiz: quizSlice.reducer,
  theme: themeSlice.reducer,
  subscription: subscriptionSlice.reducer,
});

const persistedReducer = persistReducer(
  {
    key: 'root',
    version: 1,
    storage,
    debug: isDev,
    migrate: createMigrate(storeMigrations, { debug: isDev }),
  },
  reducers,
);

export const store = configureStore({
  devTools: isDev,
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
