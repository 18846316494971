import { Box, Skeleton, Stack } from '@mui/material';

import { Surface } from '@/components/layout';
import { TranslationKeys } from '@/translation';

import { Loader } from '../Loader';
import { Text } from '../Text';

interface BoxWithTitleProps {
  title: string;
  description?: string;
  descriptionLoading?: boolean;
  loading?: boolean;
  loadingLabel?: TranslationKeys;
  children?: React.ReactNode;
}

export const BoxWithTitle = ({
  title,
  description,
  descriptionLoading,
  loading,
  loadingLabel,
  children,
}: BoxWithTitleProps) => {
  return (
    <Surface variant="surface1" sx={{ position: 'relative' }}>
      {loading && <Loader titleKey={loadingLabel} />}

      {!loading && (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack spacing={1}>
            <Text variant="titleLarge">{title}</Text>
            {!descriptionLoading && description && <Text variant="bodyMedium">{description}</Text>}
            {descriptionLoading && <Skeleton variant="text" width={150} height={20} />}
          </Stack>
        </Stack>
      )}
      {!loading && children && <Box sx={{ mt: 6 }}>{children}</Box>}
    </Surface>
  );
};
