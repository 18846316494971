import * as en_gb from './en-gb';
import * as pl from './pl';

export const languagesResources = {
  'en-gb': en_gb,
  pl,
};

export enum Language {
  EN_GB = 'en-gb',
  PL = 'pl',
}
