import dayjs from 'dayjs';

import { Subscriptions } from '@/appTypes/Subscription';
import { useAppSelector } from '@/hooks';

export const useShowPlanBoxes = () => {
  const currentUser = useAppSelector((state) => state.user.currentUser);

  const { previousPlan, subscription: currentPlan } = currentUser || {};

  const expirationDate = dayjs(currentPlan?.expirationDate);
  const daysToExpire = expirationDate.diff(dayjs(), 'day');
  const hoursToExpire = expirationDate.diff(dayjs(), 'hour');
  const isAboutToExpire = dayjs().isBefore(expirationDate);

  const showRenewPlanBox = !!previousPlan && (!currentPlan || currentPlan.type === Subscriptions.FREE);
  const showExpiringPlanBox =
    !!currentPlan && currentPlan.type !== Subscriptions.FREE && isAboutToExpire && daysToExpire <= 5;

  return { showRenewPlanBox, showExpiringPlanBox, hoursToExpire, daysToExpire, currentPlan, previousPlan };
};
