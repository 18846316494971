import { List, Text } from '@/components/elements';
import { useAppTranslation } from '@/translation';

import { useGetQuizzes } from '../../api';

import { HistoryListElementNative } from './HistoryListElement.native';
import { HistoryPlanLimitAlert } from './HistoryPlanLimitAlert';

export const HistoryTableNative = () => {
  const { t } = useAppTranslation();

  const { quizzes, totalCount, isLoading, isFetching, hasNextPage, fetchNextPage, isUserAllowedToFetchQuizzes } =
    useGetQuizzes();

  if (!isUserAllowedToFetchQuizzes) return <HistoryPlanLimitAlert />;

  if (!isLoading && quizzes.length === 0)
    return <Text variant="bodyMedium">{t('quiz:youDidNotFinishAnyQuizzesYet')}</Text>;

  return (
    <List
      data={quizzes}
      fetchNextPage={fetchNextPage}
      isFetchingNextPage={isFetching}
      isLoading={isLoading}
      hasNextPage={hasNextPage}
      totalCount={totalCount || 0}
      itemEsitmatedHeight={110}
      renderItem={(quiz, index) => (
        <HistoryListElementNative quiz={quiz} isLastElement={index === (totalCount || quizzes.length) - 1} />
      )}
    />
  );
};
