import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useGetThread } from '../api';

enum SearchParams {
  threadId = 'threadId',
}

export const useCurrentThread = () => {
  const [serachParams, setSearchParams] = useSearchParams();

  const currentThreadId = serachParams.get(SearchParams.threadId);

  const { isLoading, thread } = useGetThread(currentThreadId);

  const openThread = useCallback(
    (threadId: string) => {
      setSearchParams({ [SearchParams.threadId]: threadId });
    },
    [setSearchParams],
  );

  const resetThread = useCallback(() => {
    setSearchParams((params) => {
      params.delete(SearchParams.threadId);
      return params;
    });
  }, [setSearchParams]);

  return { currentThreadId, openThread, isLoading, thread, resetThread };
};
