import dayjs from 'dayjs';

export const expirationDateFormat = 'DD.MM.YYYY HH:mm';
export const shortExpirationDateFormat = 'DD.MM.YYYY';

export const formatExpirationDate = (expirationDate?: string) => {
  if (!expirationDate) return '';

  return dayjs(expirationDate).format(expirationDateFormat);
};
