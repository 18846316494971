import SendIcon from '@mui/icons-material/Send';
import { FormControl, FormHelperText, IconButton, InputAdornment, TextField } from '@mui/material';
import { motion } from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';

import { useAppSelector } from '@/hooks';
import { useAppTranslation } from '@/translation';

interface Props {
  question: string;
  onQuestionChange: (question: string) => void;
  onQuestionSubmit: () => void;
  disabled?: boolean;
  isThreadOpen: boolean;
}

const questionMinLength = 15;
const questionMaxLength = 1500;

export const QuestionInput = ({
  question,
  onQuestionChange,
  onQuestionSubmit,
  disabled = false,
  isThreadOpen,
}: Props) => {
  const { t } = useAppTranslation();

  const [isError, setIsError] = useState(false);

  const availableThreads = useAppSelector((store) => store.user.currentUser?.subInfo?.chat.availableThreads);
  const availableThreadsText =
    availableThreads !== undefined && !isThreadOpen
      ? t('chat:youHaveCountThreadsToOpen', { count: availableThreads })
      : undefined;
  const questionQualityText = useMemo(() => {
    if (question.length <= questionMinLength) return t('chat:questionQuality.isTooShort');
    if (question.length < 150) return t('chat:questionQuality.isShort');
    if (question.length < questionMaxLength) return t('chat:questionQuality.isGood');
    if (question.length >= questionMaxLength) return t('chat:questionQuality.isTooLong');
  }, [question, t]);
  const helperText = question.length || isError ? questionQualityText : availableThreadsText;
  const notEnoughThreads = availableThreads === undefined || availableThreads <= 0;
  const disabledSubmit = disabled || question.length > questionMaxLength || notEnoughThreads;
  const disabledInput = disabled || notEnoughThreads;
  const isMinLengthReached = question.length >= questionMinLength;

  useEffect(() => {
    if ((!question.length || question.length > questionMinLength) && isError) setIsError(false);
  }, [isError, question]);

  const submitFormOnEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      validateAndSubmit();
    }
  };

  const validateAndSubmit = () => {
    if (question.length < questionMinLength || question.length > questionMaxLength) {
      setIsError(true);
      return;
    }

    setIsError(false);
    onQuestionSubmit();
  };

  return (
    <FormControl variant="standard" fullWidth error={isError} sx={{ opacity: disabledInput ? 0.6 : 1 }}>
      <TextField
        variant="outlined"
        data-test="assistant-questionInput"
        fullWidth
        name="question"
        multiline
        minRows={1}
        maxRows={4}
        label={t('chat:yourQuestion')}
        InputLabelProps={{ shrink: true }}
        placeholder={t(isThreadOpen && !disabledInput ? 'chat:youCanInquireFurther' : 'chat:askYourQuestionHere')}
        value={question}
        onChange={(e) => onQuestionChange(e.target.value)}
        disabled={disabledInput}
        error={isError}
        onKeyDown={submitFormOnEnter}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                data-test="assistant-questionInput-submit"
                aria-label={t('chat:askAssistant')}
                onClick={validateAndSubmit}
                edge="end"
                disabled={disabledSubmit}
                color={disabledSubmit || !isMinLengthReached ? 'inherit' : 'primary'}
              >
                <SendIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {!disabled && (
        <motion.div
          key={helperText}
          initial={{ opacity: 0, y: -6 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 10 }}
          transition={{ duration: 0.3, ease: 'easeOut' }}
        >
          <FormHelperText data-test="assistant-questionInput-helperText">{helperText}</FormHelperText>
        </motion.div>
      )}
    </FormControl>
  );
};
