import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import { ReactNode } from 'react';

import { useAppTranslation } from '@/translation';

import { ModalTemplate } from './ModalTemplate';
import { Text } from './Text';

interface ConfirmationModalProps {
  open: boolean;
  title: string;
  description: string;
  onConfirm: () => void;
  onCancel: () => void;
  customConfirmText?: string;
  dangerous?: boolean;
  loading?: boolean;
  children?: ReactNode;
}

export const ConfirmationModal = ({
  open,
  title,
  description,
  onConfirm,
  onCancel,
  customConfirmText,
  dangerous,
  loading,
  children,
}: ConfirmationModalProps) => {
  const { t } = useAppTranslation();

  return (
    <ModalTemplate isOpen={open} onClose={onCancel}>
      <Stack spacing={6}>
        <Text variant="headlineSmall">{title}</Text>
        {description && <Text variant="bodyMedium">{description}</Text>}
        {children}
        <Stack direction="row" spacing={6} justifyContent="flex-end">
          <Button variant="outlined" color="primary" onClick={onCancel} disabled={loading}>
            {t('common:cancel')}
          </Button>
          <LoadingButton
            variant="contained"
            color={dangerous ? 'error' : 'primary'}
            onClick={onConfirm}
            loading={loading}
          >
            {customConfirmText || t('common:confirm')}
          </LoadingButton>
        </Stack>
      </Stack>
    </ModalTemplate>
  );
};
