import { Virtualizer } from '@tanstack/react-virtual';
import { useEffect } from 'react';

interface UseFetchNextPageOnEndProps<T> {
  fetchNextPage: () => void;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  rowVirtualizer: Virtualizer<HTMLDivElement, Element>;
  data: T[];
}

export const useFetchNextPageOnEnd = <T extends object>({
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  rowVirtualizer,
  data,
}: UseFetchNextPageOnEndProps<T>) => {
  const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse();

  useEffect(() => {
    if (!lastItem) return;

    if (lastItem.index >= data.length - 1 && hasNextPage && !isFetchingNextPage) fetchNextPage();
  }, [data, fetchNextPage, hasNextPage, isFetchingNextPage, lastItem]);
};
