import { Box, BoxProps, Button, ButtonProps, alpha, styled } from '@mui/material';

import { AnsweringState } from '../../store';

export type AnswerLetterState = 'notSelected' | 'selected' | 'correct' | 'inCorrect';

interface AnswerLetterBoxProps extends ButtonProps {
  state: AnswerLetterState;
  showBorder: boolean;
  answeringState: AnsweringState;
}
export const AnswerLetterBox = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'state' && prop !== 'showBorder' && prop !== 'answeringState',
})<AnswerLetterBoxProps>(({ state, showBorder, answeringState, theme }) => {
  const backgroundColor = {
    selected: theme.palette.app.outline,
    correct: theme.palette.app.positive,
    inCorrect: theme.palette.app.error,
    notSelected: 'transparent',
  }[state];

  const color = {
    selected: theme.palette.app.onSecondary,
    correct: theme.palette.app.onPositive,
    inCorrect: theme.palette.app.onError,
    notSelected: theme.palette.app.onSurfaceVariant,
  }[state];

  const borderColor = {
    selected: 'transparent',
    correct: showBorder ? theme.palette.app.outlineVariant : 'transparent',
    inCorrect: showBorder ? theme.palette.app.outlineVariant : 'transparent',
    notSelected: theme.palette.app.outlineVariant,
  }[state];

  const isActive = answeringState === 'answering';

  const cursor = isActive ? 'pointer' : 'default';

  const opacityOnHover = isActive ? 0.6 : 1;

  return {
    padding: 0,
    height: 28,
    minHeight: 28,
    width: 28,
    minWidth: 28,
    backgroundColor,
    color,
    borderRadius: 6,
    borderColor,
    borderWidth: showBorder ? 3 : 0,
    // borderRightWidth,
    borderStyle: 'solid',
    cursor,
    ':hover': {
      opacity: opacityOnHover,
      backgroundColor,
    },
  };
});

interface AnswerTextBoxProps extends BoxProps {
  state: AnswerLetterState;
  showBorder: boolean;
  answeringState: AnsweringState;
}
export const AnswerTextBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'state' && prop !== 'showBorder' && prop !== 'answeringState',
})<AnswerTextBoxProps>(({ state, showBorder, answeringState, theme }) => {
  const borderColor = {
    selected: theme.palette.app.outlineVariant,
    correct: showBorder ? theme.palette.app.outlineVariant : theme.palette.app.outlineVariant,
    inCorrect: showBorder ? theme.palette.app.outlineVariant : theme.palette.app.outlineVariant,
    notSelected: theme.palette.app.outlineVariant,
  }[state];

  const borderLeftWidth = {
    selected: 1,
    correct: 1,
    inCorrect: 1,
    notSelected: 1,
  }[state];

  const isActive = answeringState === 'answering';

  return {
    paddingLeft: 8,
    borderColor,
    borderWidth: 0,
    borderLeftWidth,
    borderStyle: 'solid',
    ':hover': {
      borderColor: isActive ? alpha(borderColor, 0.6) : borderColor,
    },
  };
});
