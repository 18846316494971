import { Button, Stack } from '@mui/material';

import { Text } from '@/components/elements';
import { Row } from '@/components/layout';

interface CreatorOptionContainerProps {
  title: string;
  actionBtnText?: string;
  onClickActionBtn?: () => void;
  children: JSX.Element;
  actionBtnDisabled?: boolean;
}

export const CreatorOptionContainer = ({
  title,
  actionBtnText,
  onClickActionBtn,
  children,
  actionBtnDisabled,
}: CreatorOptionContainerProps) => {
  return (
    <Stack spacing={2}>
      <Row justifyContent="space-between">
        <Text variant="titleMedium">{title}</Text>
        {actionBtnText && (
          <Button variant="text" onClick={onClickActionBtn} disabled={actionBtnDisabled}>
            {actionBtnText}
          </Button>
        )}
      </Row>
      {children}
    </Stack>
  );
};
