import { useMutation } from '@tanstack/react-query';

import { useAppNavigate, useAppSelector } from '@/hooks';
import { Pages } from '@/navigation';

import { removeAccountRequest } from '../api';

export const useRemoveAccount = () => {
  const { navigate } = useAppNavigate();
  const { currentUser } = useAppSelector((state) => state.user);

  const { mutateAsync, isPending: isLoading } = useMutation({
    mutationFn: () => removeAccountRequest({ userId: currentUser?.id || '' }),
    onSuccess: () => {
      navigate(Pages.removedAccount);
    },
  });

  return { removeAccount: mutateAsync, isLoading };
};
