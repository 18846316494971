import { useEffect } from 'react';

import { useAppSelector } from '@/hooks';

import { trackGTMEvent } from './googleTagManager/gtmEvents';
import { StandardGtmEvent } from './googleTagManager/types';
import { trackEvent } from './matomo/matomoAnalyticsEvents';

export const useUpdateAnalyticsUserContext = () => {
  const { currentUser } = useAppSelector((state) => state.user);
  const themeMode = useAppSelector((state) => state.theme.mode);

  const userId = currentUser?.id;

  useEffect(() => {
    trackGTMEvent(StandardGtmEvent.user, { userId, themeMode });
    if (userId) trackEvent.user.setUserId(userId);
    if (themeMode !== 'system') trackEvent.user.setThemeDimension(themeMode);
  }, [userId, themeMode]);
};
