import { sendToGTM } from '@elgorditosalsero/react-gtm-hook';

import { gtmEvent } from './googleTagManager';
import {
  AppThemeGtmEvent,
  AssistantGtmEvent,
  OrderGtmEvent,
  QuizGtmEvent,
  StandardGtmEvent,
  SurveyGtmEvent,
} from './types';

export const clearEcommerceObject = () => {
  sendToGTM({
    dataLayerName: 'dataLayer',
    data: {
      ecommerce: null,
    },
  });
};

export const trackGTMEvent = (event: StandardGtmEvent | OrderGtmEvent | AppThemeGtmEvent, data?: object) => {
  gtmEvent({ event, data: data || {} });
};

export const trackEventQuiz = (event: QuizGtmEvent, data?: object) => {
  // get last part of event action
  const eventAction = event.split('_').pop();

  gtmEvent({
    event,
    data: {
      eventCategory: 'quiz',
      eventAction,
      ...data,
    },
  });
};

export const trackEventSurvey = (event: SurveyGtmEvent) => {
  // get last part of event action
  const eventAction = event.split('_').pop();

  gtmEvent({
    event,
    data: {
      eventCategory: 'survey',
      eventAction,
    },
  });
};

export const trackAssistentEvent = (event: AssistantGtmEvent, data?: object) => {
  // get last part of event action
  const eventAction = event.split('_').pop();

  gtmEvent({
    event,
    data: {
      eventCategory: 'assistant',
      eventAction,
      ...data,
    },
  });
};
