import { QuizMode } from '@/appTypes';
import { TranslationKeys } from '@/translation';

export const modeTranslationKey: Record<QuizMode, TranslationKeys> = {
  [QuizMode.learning]: 'common:learning',
  [QuizMode.exam]: 'common:exam',
  [QuizMode.reviewMistakes]: 'common:reviewMistakes',
};

export const modeLongerTranslationKey: Record<QuizMode, TranslationKeys> = {
  [QuizMode.learning]: 'common:learningMode',
  [QuizMode.exam]: 'common:examMode',
  [QuizMode.reviewMistakes]: 'common:reviewMistakesMode',
};
