import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import { QuizMode } from '@/appTypes';
import { Text } from '@/components/elements';
import { useAppSelector } from '@/hooks';
import { useAppTranslation } from '@/translation';

import { useCreateQuiz } from '../hooks';

import { HomeBox } from './HomeBox';

export const ReviewMistakesBox = () => {
  const { t } = useAppTranslation();
  const currentUser = useAppSelector((state) => state.user.currentUser);

  const { createQuiz } = useCreateQuiz();

  const unreviewedMistakesCount = currentUser?.meta?.unreviewedMistakesCount;

  if (!unreviewedMistakesCount) return null;

  const createReviewMistakesQuiz = () =>
    createQuiz({
      mode: QuizMode.reviewMistakes,
      questionNumber: mistakesToReviewInQuiz,
      selectedArticlesNumbers: [],
    });

  const mistakesToReviewInQuiz = unreviewedMistakesCount ? Math.min(unreviewedMistakesCount, 10) : 0;

  return (
    <HomeBox
      title={t('home:learnFromMistakes')}
      messageNode={
        <Text
          variant="bodyMedium"
          translationKey="home:numberQuestionsForReview"
          translationParams={{ count: unreviewedMistakesCount }}
        />
      }
      buttonLabel={t('home:review')}
      surfaceVariant="surface4"
      buttonIcon={<PlayArrowIcon />}
      onClick={createReviewMistakesQuiz}
      buttonHint={t('common:nQuestions', { count: mistakesToReviewInQuiz })}
      buttonTestId="reviewMistakes-createQuizButton"
    />
  );
};
