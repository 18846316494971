import { Box, Button, Stack } from '@mui/material';
import { useState } from 'react';

import { Switch, Text } from '@/components/elements';
import { TranslationKeys, useAppTranslation } from '@/translation';

import { Cookie, SingleCookie } from './SingleCookie';

interface CookiesSettingsGroupProps {
  label: TranslationKeys;
  description: TranslationKeys;
  checked: boolean;
  onChange?: () => void;
  cookies: Cookie[];
  disabled?: boolean;
}

export const CookiesSettingsGroup = ({
  label,
  description,
  checked,
  onChange,
  cookies,
  disabled,
}: CookiesSettingsGroupProps) => {
  const [showCookies, setShowCookies] = useState(false);
  const { t } = useAppTranslation();

  const toggleShowCookies = () => {
    setShowCookies((prevState) => !prevState);
  };

  const buttonText = showCookies ? t('common:hideCookies') : t('common:showCookies');

  return (
    <Box>
      <Switch labelKey={label} checked={checked} disabled={disabled} onChange={onChange} />
      {/* <FormControlLabel control={<Switch checked={checked} onChange={onChange} />} label={label} /> */}
      <Box pl={12} mt={-1} lineHeight={1}>
        <Text variant="bodySmall" translationKey={description} />
        <Button
          variant="text"
          sx={{ fontSize: 14, paddingY: 0, textTransform: 'none', height: 14 }}
          onClick={toggleShowCookies}
        >
          {buttonText}
        </Button>

        {showCookies && (
          <Stack spacing={2} mt={2}>
            {cookies.map((cookie) => (
              <SingleCookie key={cookie.name} cookie={cookie} />
            ))}
          </Stack>
        )}
      </Box>
    </Box>
  );
};
