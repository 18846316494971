import { Box, Button, Stack } from '@mui/material';

import { Text } from '@/components/elements';
import { Pages } from '@/navigation';
import { useAppTranslation } from '@/translation';

interface RecoverPasswordSummaryProps {
  email: string;
}

export const RecoverPasswordSummary = ({ email }: RecoverPasswordSummaryProps) => {
  const { t } = useAppTranslation();

  return (
    <>
      <Stack spacing={2} marginBottom={8}>
        <Text variant="headlineMedium" translationKey="user:passwordResetRequestHasBeenSent" />
        <Box>
          <Text
            variant="bodyMedium"
            translationKey="user:ifProvidedEmailIsCorrectWeWillSendYouAnEmailWithFurtherInstructionsPart1"
          />
          <Text variant="bodyMedium" fontWeight={600}>
            {' '}
            {email}{' '}
          </Text>
          <Text
            variant="bodyMedium"
            translationKey="user:ifProvidedEmailIsCorrectWeWillSendYouAnEmailWithFurtherInstructionsPart2"
          />
        </Box>
      </Stack>
      <Stack alignItems="center">
        <Button variant="contained" color="primary" href={Pages.login}>
          {t('user:signIn')}
        </Button>
      </Stack>
    </>
  );
};
