import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';

import { ControlledPasswordField, ControlledTextField, ControlledCheckboxForAcceptTerms } from '@/components/form';
import { Meta } from '@/components/layout';
import { useAppSelector } from '@/hooks';
import { Pages } from '@/navigation';
import { useAppTranslation } from '@/translation';

import { LoginPageTemplate, SSOLogin } from '../../components';
import { useNavigateFromLogin, useRegistrationForm } from '../../hooks';

export const RegistrationPage = () => {
  const { t } = useAppTranslation();
  const { session } = useAppSelector((state) => state.user);
  const { navigateToHomeOrFrom } = useNavigateFromLogin();
  const [isSSOLoading, setIsSSOLoading] = useState(false);

  useEffect(() => {
    if (session) navigateToHomeOrFrom();
  }, [navigateToHomeOrFrom, session]);

  const { form, onSubmitPress, isSubmitting } = useRegistrationForm();

  const isLoading = isSSOLoading || isSubmitting;

  return (
    <LoginPageTemplate headerMessageKey="user:createAccountInRefereeWise">
      <Meta titleKey="user:signUp" />
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(() => onSubmitPress())}>
          <Stack spacing={4} mt={4} mb={8}>
            <ControlledTextField
              id="firstName"
              name="firstName"
              label={t('user:firstName')}
              autoComplete="given-name"
              margin="normal"
              fullWidth
              alwaysShowLabel
              disabled={isLoading}
            />
            <ControlledTextField
              id="lastName"
              name="lastName"
              label={t('user:lastName')}
              autoComplete="family-name"
              margin="normal"
              fullWidth
              alwaysShowLabel
              disabled={isLoading}
            />
            <ControlledTextField
              id="email"
              name="email"
              type="email"
              label={t('user:emailAddress')}
              autoComplete="email"
              autoCapitalize="off"
              margin="normal"
              fullWidth
              alwaysShowLabel
              disabled={isLoading}
            />
            <ControlledPasswordField
              id="password"
              name="password"
              label={t('user:password')}
              autoComplete="new-password"
              disabled={isLoading}
            />
            <ControlledCheckboxForAcceptTerms type="signUp" />
          </Stack>
          <Stack spacing={8} alignItems="center">
            <LoadingButton
              type="submit"
              itemType="submit"
              variant="contained"
              fullWidth
              sx={{ maxWidth: 300 }}
              onClick={onSubmitPress}
              loading={isLoading}
            >
              {t('user:signUp')}
            </LoadingButton>
            <SSOLogin type="registration" onStateChange={setIsSSOLoading} disabled={isSubmitting} />
          </Stack>
          <Box mt={6} textAlign="center">
            <Button href={Pages.login} color="secondary" variant="text" sx={{ textTransform: 'none' }}>
              {t('user:alreadyHaveAccountSignIn')}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </LoginPageTemplate>
  );
};
