import { Skeleton, Stack } from '@mui/material';

import { TranslationKeys } from '@/translation';

import { Text } from '../Text';

interface DataWithLabelProps {
  labelNode?: React.ReactNode;
  labelKey?: TranslationKeys;
  data?: string | number;
  dataNode?: React.ReactNode;
  dataColor?: string;
  isLoading?: boolean;
  noWrapLabel?: boolean;
  skeletonWidth?: number;
  testId?: string;
}

export const DataWithLabel = ({
  labelNode,
  labelKey,
  data,
  dataNode,
  dataColor,
  isLoading,
  noWrapLabel,
  skeletonWidth,
  testId,
}: DataWithLabelProps) => {
  return (
    <Stack spacing={1} data-test={testId}>
      {labelNode ? labelNode : <Text variant="labelMedium" translationKey={labelKey} noWrap={noWrapLabel} />}
      {isLoading && <Skeleton width={skeletonWidth || 100} />}
      {!isLoading && dataNode && dataNode}
      {!isLoading && !dataNode && (
        <Text variant="bodyMedium" customColor={dataColor} maxWidth={300}>
          {data}
        </Text>
      )}
    </Stack>
  );
};
