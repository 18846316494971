export enum ScaleAnswer {
  veryUnlikely = 1,
  unlikely = 2,
  neutral = 3,
  likely = 4,
  veryLikely = 5,
}

export type ScaleAnswerKey = keyof typeof ScaleAnswer;
export const scaleAnswerKeys = Object.keys(ScaleAnswer).filter(
  (key) => isNaN(Number(key)) === true,
) as ScaleAnswerKey[];

export enum BooleanAnswer {
  yes = 'yes',
  no = 'no',
}

export enum SurveyType {
  basic = 'basic',
  extended = 'extended',
}

export interface SurveyQuestionAnswer {
  question: string;
  answer: string | null;
}

export enum BasicQuestionKey {
  preparedForExam = 'preparedForExam',
  userSuggestions = 'userSuggestions',
}

export enum ExtendedQuestionKey {
  difficultyLevel = 'difficultyLevel',
  willBackToUsAgain = 'willBackToUsAgain',
  willRecommendUs = 'willRecommendUs',
  userSuggestions = 'userSuggestions',
}
