import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';

import GoogleLogo from '@/assets/icons/GoogleLogo.svg';
import { Text } from '@/components/elements';
import { TranslationKeys } from '@/translation';

import { SSOButtonContainer } from './styled';

interface SSOButtonProps {
  type: 'login' | 'registration';
  onClick: VoidFunction | undefined;
  disabled?: boolean;
}

export const SSOButton = ({ type, onClick, disabled }: SSOButtonProps) => {
  const { palette } = useTheme();

  const label = useMemo<TranslationKeys>(() => {
    return type === 'login' ? 'user:signInUsingGoogle' : 'user:signUpUsingGoogle';
    // return type === 'login' ? 'user:signInUsingFacebook' : 'user:signUpUsingFacebook';
  }, [type]);

  return (
    <SSOButtonContainer onClick={onClick} disabled={disabled}>
      <Stack direction="row" width="100%" alignItems="center">
        <GoogleLogo height={38} />
        {/*  <Box display="flex" height={38} width={38} justifyContent="center" alignItems="center">
             <img src={FacebookLogo} height={20} />
           </Box> */}
        <Box flex={1} mr={2}>
          <Text variant="bodyMedium" translationKey={label} width="100%" sx={{ color: palette.common.black }} />
        </Box>
      </Stack>
    </SSOButtonContainer>
  );
};
