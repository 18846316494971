import { Button, ButtonProps, styled } from '@mui/material';

interface QuizModeContainerProps extends ButtonProps {
  isSelected: boolean;
}

export const QuizModeContainer = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<QuizModeContainerProps>(({ isSelected, theme: { palette } }) => ({
  minHeight: 100,
  height: '100%',
  maxWidth: 200,
  textTransform: 'none',
  alignItems: 'flex-start',
  backgroundColor: isSelected ? palette.app.primaryContainer : 'transparent',
  borderRadius: 8,
  borderColor: isSelected ? 'transparent' : palette.app.outline,
  whiteSpace: 'normal',
  '&:hover': {
    backgroundColor: isSelected ? palette.app.primaryFixed : 'rgba(0, 98, 159, 0.04)',
  },
}));

export const ArticleGroupContainer = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<QuizModeContainerProps>(({ isSelected, theme: { palette } }) => ({
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: 8,
  textTransform: 'none',
  alignItems: 'flex-start',
  borderColor: isSelected ? 'transparent' : palette.app.outline,
  backgroundColor: isSelected ? palette.app.primaryContainer : 'transparent',
  justifyContent: 'flex-start',
  whiteSpace: 'normal',
  padding: 0,
  textAlign: 'left',
  color: palette.text.primary,
  minWidth: 240,
  maxWidth: 240,
  height: '100%',
  '&:hover': {
    backgroundColor: isSelected ? palette.app.primaryFixed : 'rgba(0, 98, 159, 0.04)',
  },
}));
