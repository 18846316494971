import { QuizMode } from '@/appTypes';
import { Subscription } from '@/appTypes/Subscription';
import { isDev } from '@/config';

enum Category {
  user = 'user',
  ecommerce = 'ecommerce',
  quiz = 'rw_quiz',
  asistant = 'rw_assistant',
}

enum CustomDimension {
  appTheme = 1,
}

const _pushToDataLayer = (data: Array<unknown>) => {
  if (isDev)
    // eslint-disable-next-line no-console
    console.log('_pushToDataLayer', data, window._paq);
  window._paq.push(data);
};

const _trackEvent = (category: Category, action: string, name?: string, value?: number) =>
  _pushToDataLayer(['trackEvent', category, action, name, value]);

const setCustomDimension = (customDimensionId: CustomDimension, value: string) =>
  _pushToDataLayer(['setCustomDimension', customDimensionId, value]);

const trackEventUser = {
  login: (authMethod: string) => {
    _trackEvent(Category.user, 'login', authMethod);
  },
  registration: (userId: string) => {
    _trackEvent(Category.user, 'registration', userId);
  },
  setUserId: (userId: string) => {
    _pushToDataLayer(['setUserId', userId]);
  },
  changeTheme: (theme: 'light' | 'dark') => {
    setCustomDimension(CustomDimension.appTheme, theme);
    _trackEvent(Category.user, 'changeTheme', theme);
  },
  setThemeDimension: (theme: 'light' | 'dark') => {
    setCustomDimension(CustomDimension.appTheme, theme);
  },
};

const trackEventApp = {
  setDocumentData: (title: string, url: string) => {
    _pushToDataLayer(['setDocumentTitle', title]);
    _pushToDataLayer(['setCustomUrl', url]);
  },
  viewPage: () => {
    _pushToDataLayer(['trackPageView']);
  },
  setCookieConsentGiven: () => {
    _pushToDataLayer(['setCookieConsentGiven']);
  },
};

const trackEventEcommerce = {
  viewPlanSelect: (planName: Subscription, currencyAsCategory: string, price: string) => {
    // setEcommerceView( productSKU, productName, categoryName, price )
    _pushToDataLayer(['setEcommerceView', planName, planName, currencyAsCategory, price]);
    _pushToDataLayer(['trackPageView']);
  },
  addPlanToCart: (planName: Subscription, currencyAsCategory: string, price: string) => {
    // addEcommerceItem( productSKU, [productName], [productCategory], [price], [quantity] )
    _pushToDataLayer(['addEcommerceItem', planName, planName, currencyAsCategory, price]);
    _pushToDataLayer(['trackEcommerceCartUpdate', price]);
  },
  clearCart: () => {
    _pushToDataLayer(['clearEcommerceCart']);
    _pushToDataLayer(['trackEcommerceCartUpdate', 0]);
  },
  purchase: (orderId: string, planName: Subscription, duration: number, currencyAsCategory: string, price: number) => {
    // addEcommerceItem( productSKU, [productName], [productCategory], [price], [quantity] )
    _pushToDataLayer(['addEcommerceItem', planName, planName, currencyAsCategory, price, duration]);
    // trackEcommerceOrder( orderId, grandTotal, [subTotal], [tax], [shipping], [discount] )
    _pushToDataLayer(['trackEcommerceOrder', orderId, price * duration]);
  },
  extendPlan: () => {
    _trackEvent(Category.ecommerce, 'extendPlan');
  },
  renewPlan: () => {
    _trackEvent(Category.ecommerce, 'renewPlan');
  },
};

const trackEventQuiz = {
  create: (quizMode: QuizMode, quizId: string, questionsAmount: number) => {
    let action = 'createExam';
    if (quizMode === QuizMode.reviewMistakes) action = 'createReviewMistakes';
    if (quizMode === QuizMode.learning) action = 'createLearning';

    _trackEvent(Category.quiz, action, quizId, questionsAmount);
  },
  checkQuestion: (questionNumber: number) => {
    _trackEvent(Category.quiz, 'checkQuestion', questionNumber.toString());
  },
  finish: (quizId: string) => {
    _trackEvent(Category.quiz, 'finish', quizId);
  },
  resumeQuiz: (quizId: string) => {
    _trackEvent(Category.quiz, 'resumeQuiz', quizId);
  },
  reportQuestion: () => {
    _trackEvent(Category.quiz, 'reportQuestion');
  },
};

const trackEventAssistant = {
  startNewThread: (withQuizQuestion: boolean, pageName: string) => {
    const action = withQuizQuestion ? 'startNewThreadWithQuizQuestion' : 'startNewThreadWithoutQuizQuestion';
    _trackEvent(Category.asistant, action, pageName);
  },
  continueThread: (withQuizQuestion: boolean, pageName: string) => {
    const action = withQuizQuestion ? 'continueThreadWithQuizQuestion' : 'continueThreadWithoutQuizQuestion';
    _trackEvent(Category.asistant, action, pageName);
  },
  rateMessage: (messageIndex: number, rate: number) => {
    _trackEvent(Category.asistant, 'rateMessage', `index-${messageIndex}`, rate);
  },
  checkDemoQuestions: (pageName: string) => {
    _trackEvent(Category.asistant, 'checkDemoQuestions', pageName);
  },
  checkDemoAnswer: (questionNumber: number) => {
    _trackEvent(Category.asistant, 'checkDemoAnswer', `question-${questionNumber}`);
  },
};

export const trackEvent = {
  app: trackEventApp,
  user: trackEventUser,
  ecommerce: trackEventEcommerce,
  quiz: trackEventQuiz,
  assistant: trackEventAssistant,
};
