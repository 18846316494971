import { FormControlLabel, Switch as MaterialSwitch } from '@mui/material';

import { TranslationKeys, useAppTranslation } from '@/translation';

interface SwitchProps {
  labelKey: TranslationKeys;
  checked: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
}

export const Switch = ({ labelKey, checked, disabled, onChange }: SwitchProps) => {
  const { t } = useAppTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.checked);
  };

  return (
    <FormControlLabel
      disabled={disabled}
      control={<MaterialSwitch checked={checked} onChange={handleChange} />}
      label={t(labelKey)}
      sx={{ userSelect: 'none' }}
    />
  );
};
