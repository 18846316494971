import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { trackEvent } from './matomoAnalyticsEvents';

const skipPageVisitTracking = ['/demo'];
const pagesWithHisOwnTracker = ['/me/subscription/select', '/me/subscription/summary'];

export const useTrackPageViewsForAnalytics = () => {
  const location = useLocation();

  const trackPageView = useCallback(() => {
    if (skipPageVisitTracking.includes(location.pathname)) return;

    const pageTitle = document.title;
    trackEvent.app.setDocumentData(pageTitle, location.pathname + location.search);
    if (!pagesWithHisOwnTracker.includes(location.pathname)) trackEvent.app.viewPage();
  }, [location.pathname, location.search]);

  useEffect(() => {
    // Track page view after 150ms to ensure that the page title is updated
    setTimeout(() => {
      trackPageView();
    }, 150);
  }, [trackPageView]);
};
