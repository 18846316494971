import { Alert, Button, Stack } from '@mui/material';
import { addBreadcrumb } from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';
import { useRouteError, useSearchParams } from 'react-router-dom';

import { Loader, Text } from '@/components/elements';
import { Logo } from '@/components/elements/Logo';
import { isDev } from '@/config';
import { captureError } from '@/services';
import { useAppTranslation } from '@/translation';

import { Pages } from './pages';

const messagesOnWhichToReload = [
  'load component',
  'imported module',
  'Importing a module script failed',
  'is not a valid JavaScript MIME type',
];

export const RouterErrorHandler = () => {
  const error = useRouteError();
  const { t } = useAppTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const isAfterAutoReload = searchParams.get('autoReload') === 'true';

  const [isLoading, setIsLoading] = useState(true);
  const [isFirstVisit] = useState(!isAfterAutoReload);

  const reloadPage = useCallback(
    (message?: string) => {
      addBreadcrumb({
        message: 'Router error occured - auto reload',
        data: { error, message },
        type: 'debug',
        category: 'router',
      });
      setSearchParams((prev) => {
        prev.set('autoReload', 'true');
        return prev;
      });
      window.location.reload();
    },
    [error, setSearchParams],
  );

  const handleUnknownError = useCallback(() => {
    captureError(new Error('Router error occured - unknown error'), { data: error });
    setIsLoading(false);
  }, [error]);

  const handleReloadLoop = useCallback(() => {
    captureError(new Error('Router error occured - reload loop detected'), { data: error });
    setIsLoading(false);
  }, [error]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const message: string | undefined = (error as any)?.error?.message || (error as any)?.message || error?.toString();

  useEffect(() => {
    if (isAfterAutoReload && !isFirstVisit) {
      handleReloadLoop();
      return;
    }

    if (messagesOnWhichToReload.some((msg) => message?.includes(msg))) reloadPage(message);
    else handleUnknownError();
  }, [error, handleReloadLoop, handleUnknownError, isAfterAutoReload, isFirstVisit, message, reloadPage]);

  return (
    <Stack spacing={4} pt={20} maxWidth={300} margin="0 auto" display="flex" flexDirection="column">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Logo />
          {isDev && (
            <Alert variant="outlined" severity="error">
              {message}
            </Alert>
          )}
          <Text variant="headlineSmall" align="center" translationKey="common:sorrySomethingWentWrong" />
          <Text variant="bodyLarge" align="center" translationKey="common:reportedAndPleaseReloadThePagePart1" />
          <Text
            variant="bodyLarge"
            align="center"
            translationKey="common:reportedAndPleaseReloadThePagePart2"
            selectable
          />
          <Button variant="contained" color="primary" onClick={() => reloadPage()}>
            {t('common:reloadPage')}
          </Button>
          <Button variant="outlined" color="primary" href={Pages.home}>
            {t('common:homePage')}
          </Button>
        </>
      )}
    </Stack>
  );
};
