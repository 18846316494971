import { useAppTranslation } from '@/translation';
import { Language } from '@/translation/locales';

export const appDataStaticElements = {
  appName: 'RefereeWise',
  baseUrl: 'https://www.refereewise.com/',
  sameAs: [
    'https://www.facebook.com/RefereeWisePolska',
    'https://www.facebook.com/RefereeWiseApp',
    'https://twitter.com/refereewise',
    'https://www.instagram.com/refereewise/',
  ],
  category: 'Education',

  // Banner
  bannerPLUrl: 'https://refereewise-assets.s3.eu-central-1.amazonaws.com/standard-banner-pl.png',
  bannerENGBUrl: 'https://refereewise-assets.s3.eu-central-1.amazonaws.com/standard-banner-en.png',
  bannerWidth: 1200,
  bannerHeight: 630,
};

export const useAppConfig = () => {
  const { bannerPLUrl, bannerENGBUrl, ...restOfData } = appDataStaticElements;

  const { currentLanguage, t } = useAppTranslation();

  const bannerUrl = currentLanguage === Language.PL ? bannerPLUrl : bannerENGBUrl;

  return {
    loginPageTitle: t('user:meta.login.title'),
    loginPageDescription: t('user:meta.login.description'),
    bannerAlt: t('user:meta.login.bannerAlt'),
    currentLanguage,
    bannerUrl,
    ...restOfData,
  };
};
