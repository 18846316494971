import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { matchPath } from 'react-router-dom';
import { z } from 'zod';

import { useAppDispatch, useNotify } from '@/hooks';
import { Pages } from '@/navigation';
import { QuizGtmEvent, trackEvent, trackEventQuiz } from '@/services';
import { useAppTranslation } from '@/translation';

import { useReportQuestion } from '../api';
import { updateQuestionReportedAt } from '../store';

const formSchema = z.object({
  reason: z.string().min(10).max(1000),
});

type ReportQuestionFormValues = z.infer<typeof formSchema>;

interface ReportQuestionFormProps {
  questionId: string;
  onSuccess: () => void;
}

export const useReportQuestionForm = ({ questionId, onSuccess }: ReportQuestionFormProps) => {
  const { notify } = useNotify();
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const isOnQuizSummary = !!matchPath(Pages.quizSummary, location.pathname);

  const { reportQuestion } = useReportQuestion();

  const form = useForm<ReportQuestionFormValues>({
    resolver: zodResolver(formSchema),
  });

  const { handleSubmit, reset: resetForm, formState } = form;

  useEffect(() => {
    resetForm();
  }, [resetForm, questionId]);

  const onSubmit: SubmitHandler<ReportQuestionFormValues> = async (data) => {
    trackEventQuiz(QuizGtmEvent.reportQuestion, {
      page: isOnQuizSummary ? 'quizSummary' : 'quiz',
    });
    trackEvent.quiz.reportQuestion();

    await reportQuestion({
      questionId,
      reason: data.reason,
    });

    dispatch(
      updateQuestionReportedAt({
        questionId,
        reportedAt: new Date(),
      }),
    );

    notify('success', t('quiz:questionHasBeenReported'));
    onSuccess();
  };

  const onError: SubmitErrorHandler<ReportQuestionFormValues> = () => {};

  const onSubmitPress = handleSubmit(onSubmit, onError);

  return {
    form,
    onSubmitPress,
    isSubmitting: formState.isSubmitting,
  };
};
