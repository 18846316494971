import { Box, Button, Stack } from '@mui/material';

import { Pages } from '@/navigation';
import { useAppTranslation } from '@/translation';

import { AssistantMessage } from '../ChatMessage/AssistantMessage';

interface Props {
  showAvatar?: boolean;
}

export const LackOfThreadsMessage = ({ showAvatar }: Props) => {
  const { t } = useAppTranslation();

  return (
    <Stack spacing={4} width="100%">
      <AssistantMessage message={t('chat:lackOfThreadsMessage')} hideAvatar={!showAvatar} />
      <Box width="100%" display="flex" justifyContent="center">
        <Button variant="contained" color="primary" href={Pages.userSubscriptionSummary}>
          {t('subscription:buyProPlan')}
        </Button>
      </Box>
    </Stack>
  );
};
