import { CircularProgress, Stack } from '@mui/material';

import { TranslationKeys } from '@/translation';

import { Text } from '../Text';

interface LoaderProps {
  titleKey?: TranslationKeys;
  descriptionKey?: TranslationKeys;
}

export const Loader = ({ titleKey = 'common:weAreLoadingData', descriptionKey }: LoaderProps) => {
  return (
    <Stack alignItems="center" spacing={5} marginY={10}>
      <CircularProgress />
      <Text variant="headlineSmall" translationKey={titleKey} />
      {descriptionKey && <Text variant="bodyMedium" translationKey={descriptionKey} marginTop={4} textAlign="center" />}
    </Stack>
  );
};
