import { ButtonBase, styled } from '@mui/material';

export const SSOButtonContainer = styled(ButtonBase)(({ theme, disabled }) => ({
  backgroundColor: 'white',
  borderColor: theme.palette.app.outlineVariant,
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: 100,
  height: 40,
  width: '100%',
  opacity: disabled ? 0.4 : 1,
}));
