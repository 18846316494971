import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { axiosInstance } from '@/services';

interface UpdateAccountDataPayload {
  userId: string;
  firstName?: string;
  lastName?: string;
  language?: string;
}

export const useUpdateCurrentUserApi = () => {
  const { mutateAsync: updateCurrentUser, isPending: isLoading } = useMutation<
    boolean,
    AxiosError,
    UpdateAccountDataPayload
  >({
    mutationFn: async ({ userId, firstName, lastName, language }) => {
      const response = await axiosInstance.patch(`/users/${userId}/`, {
        name: firstName,
        surname: lastName,
        language,
      });

      return response.status === 200;
    },
  });

  return {
    updateCurrentUser,
    isLoading,
  };
};
