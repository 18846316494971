import { Box, Divider, Stack } from '@mui/material';
import { memo } from 'react';

import { QuizMode } from '@/appTypes';
import { Text, PlanLimitBadge } from '@/components/elements';
import { useFeatureAvailable } from '@/features/User';
import { useAppTranslation } from '@/translation';

import { useCreateQuizFormContext } from '../../hooks';

import { QuizModeContainer } from './styled';

interface SelectableModeOfQuizProps {
  disabled?: boolean;
}

export const SelectableModeOfQuiz = memo(({ disabled }: SelectableModeOfQuizProps) => {
  const { t } = useAppTranslation();
  const { watch, setValue, formState } = useCreateQuizFormContext();
  const { learningMode: isLearningModeAvailable } = useFeatureAvailable();

  const mode = watch('mode');
  const isSubmitting = formState.isSubmitting;

  const isExamSelected = mode === QuizMode.exam;

  return (
    <Stack direction="row" spacing={4}>
      <QuizModeButton
        name={t('common:exam')}
        description1={t('common:examExplanationFirstPart')}
        onClick={() => setValue('mode', QuizMode.exam)}
        isSelected={isExamSelected}
        disabled={isSubmitting || !!disabled}
      />
      <QuizModeButton
        name={t('common:learning')}
        description1={t('common:learningExplanationFirstPart')}
        onClick={() => setValue('mode', QuizMode.learning)}
        isSelected={!isExamSelected}
        disabled={isSubmitting || !!disabled || !isLearningModeAvailable}
        showProPlanBadge={!isLearningModeAvailable}
      />
    </Stack>
  );
});

interface QuizModeProps {
  name: string;
  description1: string;
  onClick: () => void;
  isSelected: boolean;
  disabled: boolean;
  showProPlanBadge?: boolean;
}

const QuizModeButton = ({ name, description1, onClick, isSelected, disabled, showProPlanBadge }: QuizModeProps) => {
  const textColor = isSelected ? 'onPrimaryContainer' : 'onSurfaceVariant';

  return (
    <Box position="relative">
      <QuizModeContainer variant="outlined" onClick={onClick} disabled={disabled} isSelected={isSelected}>
        <Stack direction="column" spacing={1} sx={{ opacity: disabled ? 0.5 : 1 }}>
          <Stack spacing={2} direction="row" justifyContent="center">
            <Text variant="labelLarge" color={textColor}>
              {name}
            </Text>
          </Stack>
          <Divider />
          <Text variant="bodySmall" color={textColor}>
            {description1}
          </Text>
        </Stack>
      </QuizModeContainer>

      {showProPlanBadge && (
        <Box display="flex" position="absolute" bottom={-12} left="0" right="0" flex={1} justifyContent="center">
          <PlanLimitBadge forPlan="PRO" />
        </Box>
      )}
    </Box>
  );
};
