import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';

import { Text } from '@/components/elements';
import { ControlledTextField } from '@/components/form';
import { Pages } from '@/navigation';
import { useAppTranslation } from '@/translation';

import { useRecoverPasswordForm } from '../../hooks';

interface RecoverInputProps {
  showSummary: (email: string) => void;
  providedEmail?: string | null;
}

export const RecoverInput = ({ showSummary, providedEmail }: RecoverInputProps) => {
  const { t } = useAppTranslation();
  const { form, onSubmitPress, isSubmitting } = useRecoverPasswordForm({
    onSuccess: showSummary,
  });

  useEffect(() => {
    if (providedEmail) form.setValue('email', providedEmail);
  }, [form, providedEmail]);

  return (
    <>
      <Stack spacing={2}>
        <Text variant="headlineMedium" translationKey="user:forgottenPassword" />
        <Text variant="bodyMedium" translationKey="user:provideYourEmailToResetPassword" />
      </Stack>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(() => onSubmitPress())}>
          <Stack spacing={8} mt={8} mb={8} alignItems="center">
            <ControlledTextField
              id="email"
              name="email"
              type="email"
              label={t('user:emailAddress')}
              autoComplete="email"
              autoFocus
              margin="normal"
              fullWidth
              alwaysShowLabel
            />
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={onSubmitPress}
              loading={isSubmitting}
            >
              {t('user:recoverPassword')}
            </LoadingButton>
          </Stack>
        </form>
      </FormProvider>
      <Button href={Pages.login} variant="text" startIcon={<ChevronLeftIcon />}>
        {t('common:back')}
      </Button>
    </>
  );
};
