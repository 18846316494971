import { useState } from 'react';

import { useGetCurrentPageName } from '@/hooks';
import { AssistantGtmEvent, trackAssistentEvent, trackEvent } from '@/services';
import { useAppTranslation } from '@/translation';

import { MessageSender } from '../../types';
import { ChatMessage } from '../ChatMessage';
import { StartNewThreadButton } from '../StartNewThreadButton';
import { LoadingResponseMessage } from '../messages/LoadingResponseMessage';

import { HowShouldUserAskSelectExampleQuestion } from './HowShouldUserAskSelectExampleQuestion';

interface Props {
  onInstructionsClose: () => void;
}

export const HowShouldUserAskInstructions = ({ onInstructionsClose }: Props) => {
  const { t } = useAppTranslation();
  const [showAnswerToQuestion, setShowAnswerToQuestion] = useState<number | false>(false);
  const [isFakeLoading, setIsFakeLoading] = useState(true);
  const pageName = useGetCurrentPageName();

  const handleSelectExampleQuestion = (questionNumber: number) => {
    trackAssistentEvent(AssistantGtmEvent.checkDemoAnswer, { questionNumber, pageName });
    trackEvent.assistant.checkDemoAnswer(questionNumber);
    setShowAnswerToQuestion(questionNumber);
    setTimeout(() => {
      setIsFakeLoading(false);
    }, 1000);
  };

  if (showAnswerToQuestion === 1) {
    return (
      <>
        <ChatMessage
          hideAvatar
          message={t('chat:howToAskQuestionInstructionMessage')}
          author={MessageSender.assistant}
        />
        <ChatMessage message={t('chat:howToAskQuestionInstructionExampleQuestion1')} author={MessageSender.user} />
        {isFakeLoading ? (
          <LoadingResponseMessage />
        ) : (
          <>
            <ChatMessage
              message={t('chat:howToAskQuestionInstructionExampleAnswer1')}
              author={MessageSender.assistant}
            />
            <StartNewThreadButton variant="contained" customOnClick={onInstructionsClose} />
          </>
        )}
      </>
    );
  }

  if (showAnswerToQuestion === 2) {
    return (
      <>
        <ChatMessage
          hideAvatar
          message={t('chat:howToAskQuestionInstructionMessage')}
          author={MessageSender.assistant}
        />
        <ChatMessage message={t('chat:howToAskQuestionInstructionExampleQuestion2')} author={MessageSender.user} />
        {isFakeLoading ? (
          <LoadingResponseMessage />
        ) : (
          <>
            <ChatMessage
              message={t('chat:howToAskQuestionInstructionExampleAnswer2')}
              author={MessageSender.assistant}
            />
            <StartNewThreadButton variant="contained" customOnClick={onInstructionsClose} />
          </>
        )}
      </>
    );
  }

  return <HowShouldUserAskSelectExampleQuestion onSelectQuestion={handleSelectExampleQuestion} />;
};
