import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Quiz } from '@/appTypes';
import { getQuizQueryKey } from '@/features/Quiz/api';
import { setThreadMessageRateToQuizQuestion } from '@/features/Quiz/store';
import { useAppDispatch, useGetCurrentPageName, useNotify } from '@/hooks';
import { AssistantGtmEvent, axiosInstance, trackAssistentEvent, trackEvent } from '@/services';
import { useAppTranslation } from '@/translation';

import { Thread } from '../types';
import { addThreadMessageRateToQuizQuestion } from '../utils';

import { getThreadQueryKey } from './useGetThread';

export interface RateThreadPayload {
  messageIndex: number;
  rate: number;
}

const endpoint = '/chat/:id/rating/:answer_index/';

interface Params {
  threadId: string | undefined;
  quizId?: string;
  quizQuestionId?: string;
}

export const useRateMessage = ({ threadId, quizId, quizQuestionId }: Params) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const { notify } = useNotify();
  const { t } = useAppTranslation();
  const pageName = useGetCurrentPageName();

  const rateThreadRequest = async ({ messageIndex, rate }: RateThreadPayload) => {
    if (!threadId) throw new Error('Thread id is required');

    const response = await axiosInstance.post<Thread | undefined>(
      endpoint.replace(':id', threadId).replace(':answer_index', messageIndex.toString()),
      { rating: rate },
    );

    return response;
  };

  const { mutateAsync, isPending: isLoading } = useMutation({
    mutationFn: rateThreadRequest,
    onSuccess: ({ status }, { rate, messageIndex }) => {
      if (status !== 204) return;

      trackAssistentEvent(AssistantGtmEvent.rateMessage, { withQuizQuestion: !!quizQuestionId, pageName });
      trackEvent.assistant.rateMessage(messageIndex, rate);

      queryClient.setQueryData<Thread>([getThreadQueryKey, threadId], (oldData) => {
        if (!oldData) return oldData;

        const updatedMessages = [...oldData.messages];
        if (messageIndex && updatedMessages.length > 0 && updatedMessages[messageIndex])
          updatedMessages[messageIndex] = { ...updatedMessages[messageIndex], rating: rate };

        return { ...oldData, messages: updatedMessages };
      });

      if (quizId && quizQuestionId) {
        dispatch(setThreadMessageRateToQuizQuestion({ quizQuestionId, messageIndex, rate }));

        queryClient.setQueryData<Quiz>(getQuizQueryKey(quizId), (quiz) => {
          if (!quiz) return;

          return addThreadMessageRateToQuizQuestion({
            quiz,
            quizQuestionId,
            messageIndex,
            rate,
          });
        });
      }
    },
    onError: () => {
      notify('error', t('common:sorrySomethingWentWrong'));
    },
  });

  return {
    rateMessage: mutateAsync,
    isLoading,
  };
};
