import { Outlet } from 'react-router-dom';

import { CookiesServicesProvider } from '@/features/CookiesConsent';
import { ForceUpdateModal } from '@/features/ForceUpdate/components/ForceUpdateModal';
import { useNativePushNotifications, useTrackPageViewsForAnalytics } from '@/services';
import { MaterialThemeProvider } from '@/styles/theme';

import { AppUrlListener } from './AppUrlListener.native';

export const AppWrappersInsideRouter = () => {
  useTrackPageViewsForAnalytics();

  useNativePushNotifications();

  return (
    <div>
      <AppUrlListener />
      <MaterialThemeProvider>
        <CookiesServicesProvider>
          <Outlet />
        </CookiesServicesProvider>
        <ForceUpdateModal />
      </MaterialThemeProvider>
    </div>
  );
};
