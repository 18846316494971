import { CustomTypographyOptions, Palette } from '@mui/material';

import theme from './theme.json';

type ThemedTypography = (palette: Palette) => CustomTypographyOptions;
// type TypographyVariant = typeof rawFont.display.large;

// export const rawFont = theme.font

// const getTypographyFromTheme = (variant: TypographyVariant): TypographyStyleOptions => ({
//   fontFamily: variant.fontFamilyName,
//   fontSize: variant.fontSize + 'px',
//   fontWeight: variant.fontWeight,
//   lineHeight: variant.lineHeight + 'px',
//   letterSpacing: variant.letterSpacing + 'px',
// });

// export const typographyVariants = {
//   displayLarge: getTypographyFromTheme(rawFont.display.large),
//   displayMedium: getTypographyFromTheme(rawFont.display.medium),
//   displaySmall: getTypographyFromTheme(rawFont.display.small),
//   headlineLarge: getTypographyFromTheme(rawFont.headline.large),
//   headlineMedium: getTypographyFromTheme(rawFont.headline.medium),
//   headlineSmall: getTypographyFromTheme(rawFont.headline.small),
//   bodyLarge: getTypographyFromTheme(rawFont.body.large),
//   bodyMedium: getTypographyFromTheme(rawFont.body.medium),
//   bodySmall: getTypographyFromTheme(rawFont.body.small),
//   labelLarge: getTypographyFromTheme(rawFont.label.large),
//   labelMedium: getTypographyFromTheme(rawFont.label.medium),
//   labelSmall: getTypographyFromTheme(rawFont.label.small),
//   titleLarge: getTypographyFromTheme(rawFont.title.large),
//   titleMedium: getTypographyFromTheme(rawFont.title.medium),
//   titleSmall: getTypographyFromTheme(rawFont.title.small),
// };

export const typographyVariants = theme.font;

export const typography: ThemedTypography = (palette) => ({
  fontFamily: 'Roboto, sans-serif',
  allVariants: {
    fontWeight: 400,
    color: palette.text.primary,
    letterSpacing: 0,
  },
  ...typographyVariants,
});
