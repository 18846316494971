/* eslint-disable no-console */
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { PushNotifications } from '@capacitor/push-notifications';
import { useEffect } from 'react';

import { PlatformType, registerFCMDeviceToken } from '@/features/User/api/registerFCMDeviceToken.native';
import { useAppSelector } from '@/hooks';

import { captureError } from './sentry';

const registerDevice = async (fcmToken: string) => {
  console.info('Registration token: ', fcmToken);
  const deviceName = (await Device.getInfo()).name || 'Unknown device';
  const deviceId = (await Device.getId()).identifier;
  const platform = Capacitor.getPlatform() as PlatformType;
  registerFCMDeviceToken({ name: deviceName, registrationId: fcmToken, deviceId, type: platform });
};

const addListeners = async () => {
  await PushNotifications.addListener('registration', (token) => registerDevice(token.value));

  await PushNotifications.addListener('registrationError', captureError);

  await PushNotifications.addListener('pushNotificationReceived', (notification) => {
    console.log('Push notification received: ', notification);
  });

  await PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
    console.log('Push notification action performed', notification.actionId, notification.inputValue);
  });
};

const registerNotifications = async () => {
  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === 'prompt') permStatus = await PushNotifications.requestPermissions();

  if (permStatus.receive !== 'granted') throw new Error('User denied permissions!');

  await PushNotifications.register();
};

const getDeliveredNotifications = async () => {
  const notificationList = await PushNotifications.getDeliveredNotifications();
  console.log('delivered notifications', notificationList);
};

export const useNativePushNotifications = () => {
  const currentUserSession = useAppSelector((state) => state.user.session);
  const expiresAt = currentUserSession?.refreshTokenExpiresAt;

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return;

    const isCurrentSessionValid = expiresAt && expiresAt > Math.floor(Date.now() / 1000);
    if (!isCurrentSessionValid) return;

    addListeners();
    registerNotifications();
    getDeliveredNotifications();
  }, [expiresAt]);
};
