import { Box } from '@mui/material';
import { ColumnDef, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { debounce } from 'lodash';
import { ReactNode, useCallback, useState } from 'react';

import { DefaultCell } from './DefaultCell';
import { ItemWithId, TableBody } from './TableBody';
import { TableHeader } from './TableHeader';
import { Table as TableElement } from './styled';

interface TableProps<T extends ItemWithId> {
  data: T[];
  columns: ColumnDef<T>[];
  hasNextPage?: boolean;
  isLoading: boolean;
  isFetching: boolean;
  totalCount: number;
  fetchNextPage: () => void;
  noRowsMessage: string;
  onRowClick?: (item: T) => void;
  footer?: ReactNode;
  hideBody?: boolean;
  hideHeader?: boolean;
  rowTestId?: string;
  rowEsitmatedHeight: number;
  tableHeight?: number;
}

export const Table = <T extends ItemWithId>({
  data,
  columns,
  hasNextPage,
  isLoading,
  isFetching,
  totalCount,
  fetchNextPage,
  noRowsMessage,
  onRowClick,
  footer,
  rowTestId,
  hideBody,
  hideHeader,
  rowEsitmatedHeight,
  tableHeight = 500,
}: TableProps<T>) => {
  const [tableContainerElement, setTableContainerElement] = useState<HTMLDivElement | null>(null);

  const setTableContainerRef = useCallback((element: HTMLDivElement | null) => {
    setTableContainerElement(element);
  }, []);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    defaultColumn: {
      cell: DefaultCell,
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
  });

  const { rows } = table.getRowModel();
  const headersGroups = table.getHeaderGroups();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchNextPage = useCallback(
    debounce(() => {
      fetchNextPage();
    }, 200),
    [fetchNextPage],
  );

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        // once the user has scrolled within tableHeight of the bottom of the table, fetch more data if we can
        if (scrollHeight - scrollTop - clientHeight < tableHeight && !isFetching && data.length < totalCount)
          debouncedFetchNextPage();
      }
    },
    [tableHeight, isFetching, data.length, totalCount, debouncedFetchNextPage],
  );
  return (
    <div
      onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
      ref={setTableContainerRef}
      style={{
        height: tableHeight,
        overflow: 'auto', //our scrollable table container
        position: 'relative', //needed for sticky header
      }}
    >
      <TableElement>
        {!hideHeader && <TableHeader headersGroups={headersGroups} />}
        {!hideBody && (
          <TableBody
            rows={rows}
            hasNextPage={hasNextPage}
            totalCount={totalCount}
            tableContainerRef={tableContainerElement}
            noRowsMessage={noRowsMessage}
            isLoading={isLoading || isFetching}
            onRowClick={onRowClick}
            numberOfColumns={columns.length}
            rowTestId={rowTestId}
            rowEsitmatedHeight={rowEsitmatedHeight}
          />
        )}
      </TableElement>
      {footer && <Box p={5}>{footer}</Box>}
    </div>
  );
};
