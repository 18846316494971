import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { forwardRef } from 'react';
import { useController, UseControllerProps, useFormContext } from 'react-hook-form';

import { TranslationKeys, useAppTranslation } from '@/translation';

export type TextInputRef = HTMLInputElement;

export type ControlledTextFieldProps = TextFieldProps & {
  name: string;
  rules?: UseControllerProps['rules'];
  defaultValue?: UseControllerProps['defaultValue'];
  alwaysShowLabel?: boolean;
  endIcon?: React.ReactNode;
  labelKey?: TranslationKeys;
  placeholderKey?: TranslationKeys;
};

export const ControlledTextField = forwardRef<TextInputRef, ControlledTextFieldProps>(
  (
    {
      name,
      rules,
      defaultValue,
      helperText,
      disabled,
      alwaysShowLabel,
      endIcon,
      label,
      labelKey,
      placeholder,
      placeholderKey,
      ...inputProps
    },
    ref,
  ) => {
    const { t } = useAppTranslation();
    const { formState } = useFormContext();
    const {
      field: { onChange, ...field },
    } = useController({ name, rules, defaultValue });

    const error = formState.errors[name]?.message?.toString();
    const isLoading = formState.isSubmitting;

    const textLabel = labelKey ? t(labelKey) : label;
    const textPlaceholder = placeholderKey ? t(placeholderKey) : placeholder;

    return (
      <TextField
        {...inputProps}
        {...field}
        label={textLabel}
        placeholder={textPlaceholder}
        defaultChecked={field.value}
        ref={ref}
        onChange={(e) => onChange(e.target.value)}
        error={!!error}
        disabled={isLoading || disabled}
        helperText={error || helperText}
        InputProps={{
          endAdornment: !!endIcon && <InputAdornment position="end">{endIcon}</InputAdornment>,
        }}
        InputLabelProps={{
          shrink: alwaysShowLabel,
        }}
      />
    );
  },
);
