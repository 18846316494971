import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Meta } from '@/components/layout';

import { LoginPageTemplate } from '../../components';

import { RecoverInput } from './RecoverInput';
import { RecoverPasswordSummary } from './RecoverPasswordSummary';

export const RecoverPasswordPage = () => {
  const [showSummaryForEmail, setShowSummaryForEmail] = useState('');
  const [searchParams] = useSearchParams();

  const providedEmail = searchParams.get('email');

  return (
    <LoginPageTemplate>
      <Meta titleKey="user:recoverPassword" />
      {showSummaryForEmail ? (
        <RecoverPasswordSummary email={showSummaryForEmail} />
      ) : (
        <RecoverInput providedEmail={providedEmail} showSummary={setShowSummaryForEmail} />
      )}
    </LoginPageTemplate>
  );
};
