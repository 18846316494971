import { createSlice } from '@reduxjs/toolkit';

interface ChatState {
  questionToAsk: string | null;
}

const initialState: ChatState = {
  questionToAsk: null,
};

interface SetQuestionToAskPayload {
  question: string | null;
}

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setQuestionToAsk: (state, { payload }: { payload: SetQuestionToAskPayload }) => {
      state.questionToAsk = payload.question;
    },
  },
});

export const { setQuestionToAsk } = chatSlice.actions;
