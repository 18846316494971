import { HeaderGroup, flexRender } from '@tanstack/react-table';
import { memo } from 'react';

import { Th, Thead } from './styled';

interface TableHeaderProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headersGroups: HeaderGroup<any>[];
}

export const TableHeader = memo(({ headersGroups }: TableHeaderProps) => {
  return (
    <Thead>
      {headersGroups.map((headerGroup) => (
        <tr key={headerGroup.id} style={{ display: 'flex', width: '100%' }}>
          {headerGroup.headers.map((header) => {
            return (
              <Th
                key={header.id}
                colSpan={header.colSpan}
                style={{
                  width: header.getSize() === Number.MAX_SAFE_INTEGER ? '100%' : header.getSize(),
                }}
              >
                {/* TOOD: is isPlaceholder needed? */}
                {header.isPlaceholder ? null : (
                  <div
                    {...{
                      className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                      onClick: header.column.getToggleSortingHandler(),
                    }}
                    style={{
                      width: header.getSize() === Number.MAX_SAFE_INTEGER ? '100%' : header.getSize(),
                      minWidth: header.column.columnDef.minSize,
                    }}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted() as string] ?? null}
                  </div>
                )}
              </Th>
            );
          })}
        </tr>
      ))}
    </Thead>
  );
});
