import { PaletteOptions } from '@mui/material';

import theme from './theme.json';

export const palettes = theme.colors.palettes;
export const appLightModePalette = theme.colors.light;
export const appDarkModePalette = theme.colors.dark;

export type ColorPalette = typeof appLightModePalette;
export type ColorPaletteKey = keyof typeof appLightModePalette;

const getModeColor = (mode: 'dark' | 'light', color: ColorPaletteKey) =>
  mode === 'light' ? appLightModePalette[color] : appDarkModePalette[color];

export const getMuiLightModePalette = (mode: 'dark' | 'light'): PaletteOptions => ({
  mode,
  primary: {
    light: appLightModePalette.primary,
    main: getModeColor(mode, 'primary'),
    dark: appDarkModePalette.primary,
    contrastText: getModeColor(mode, 'onPrimary'),
  },
  secondary: {
    light: appLightModePalette.secondary,
    main: getModeColor(mode, 'secondary'),
    dark: appDarkModePalette.secondary,
    contrastText: getModeColor(mode, 'onSecondary'),
  },
  error: {
    light: appLightModePalette.error,
    main: getModeColor(mode, 'error'),
    dark: appDarkModePalette.error,
    contrastText: getModeColor(mode, 'onError'),
  },
  background: {
    default: getModeColor(mode, 'background'),
    paper: getModeColor(mode, 'surfaceContainer'),
  },
  common: {
    black: '#000000',
    white: '#ffffff',
  },
  // warning: {},
  // info: {},
  success: {
    light: appLightModePalette.positive,
    main: getModeColor(mode, 'positive'),
    dark: appDarkModePalette.positive,
    contrastText: getModeColor(mode, 'onPositive'),
  },
  grey: {
    '50': palettes.neutral['5'],
    '100': palettes.neutral['10'],
    '200': palettes.neutral['20'],
    '300': palettes.neutral['30'],
    '400': palettes.neutral['40'],
    '500': palettes.neutral['50'],
    '600': palettes.neutral['60'],
    '700': palettes.neutral['70'],
    '800': palettes.neutral['80'],
    '900': palettes.neutral['90'],
    A100: palettes.neutral['10'],
    A200: palettes.neutral['20'],
    A400: palettes.neutral['40'],
    A700: palettes.neutral['70'],
  },
  text: {
    primary: getModeColor(mode, 'onSurface'),
    secondary: getModeColor(mode, 'onPrimary'),
    disabled: getModeColor(mode, 'onSurface'),
  },
});

export const lightModePalette = {
  ...getMuiLightModePalette('light'),
  app: appLightModePalette,
  primarySpectrum: palettes.primary,
  secondarySpectrum: palettes.secondary,
};

export const darkModePalette = {
  ...getMuiLightModePalette('dark'),
  app: appDarkModePalette,
  primarySpectrum: palettes.primary,
  secondarySpectrum: palettes.secondary,
};
