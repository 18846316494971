import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { ListResponse } from '@/appTypes';
import { axiosInstance } from '@/services';

import { ThreadListElement } from '../types';

export const chatHistoryQueryKey = 'chatHistory';
const endpoint = '/chat/';
const pageSize = 15;

export type GetChatHistoryResponse = ListResponse<ThreadListElement>;

export const useGetChatHistory = () => {
  const getChatHistoryRequest = async (page = 1) => {
    const response = await axiosInstance.get<GetChatHistoryResponse>(endpoint, {
      params: {
        page,
        pageSize,
      },
    });

    return response.data;
  };

  const { data, hasNextPage, isLoading, fetchNextPage, isFetching, isFetchingNextPage, dataUpdatedAt } =
    useInfiniteQuery({
      queryKey: [chatHistoryQueryKey],
      initialPageParam: 1,
      queryFn: ({ pageParam }) => getChatHistoryRequest(pageParam),
      placeholderData: keepPreviousData,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.next) return allPages.length + 1;

        return undefined;
      },
    });

  const chatHistory: GetChatHistoryResponse['results'] = useMemo(() => {
    if (!data?.pages) return [];

    return data.pages.flatMap((page) => page.results);
    // Note: checking dataUpdatedAt to force rerender when data is updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dataUpdatedAt]);

  const totalCount = data?.pages[data.pages.length - 1].count;

  const handleFetchNextPage = () => {
    if (isLoading || isFetching || isFetchingNextPage || !hasNextPage) return;

    fetchNextPage();
  };

  return {
    chatHistory,
    isLoading,
    totalCount,
    isFetching,
    hasNextPage,
    fetchNextPage: handleFetchNextPage,
  };
};
