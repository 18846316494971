import LanguageIcon from '@mui/icons-material/Language';
import LightModeIcon from '@mui/icons-material/LightMode';
import { Box, Button, Container, IconButton, Paper, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactNode, useState } from 'react';

import { Text } from '@/components/elements';
import { Logo } from '@/components/elements/Logo';
import { useAppDispatch } from '@/hooks';
import { Pages, useGetPagePathWithLang } from '@/navigation';
import { toggleThemeMode as toggleThemeModeAction } from '@/styles/theme';
import { TranslationKeys, useAppTranslation } from '@/translation';

import { LanguageSelector } from './LanguageSelector';

interface LoginPageTemplateProps {
  children: ReactNode;
  headerMessageKey?: TranslationKeys;
}

export const LoginPageTemplate = ({ children, headerMessageKey }: LoginPageTemplateProps) => {
  const dispatch = useAppDispatch();
  const { palette } = useTheme();
  const { t } = useAppTranslation();

  const termsOfServicePath = useGetPagePathWithLang(Pages.termsOfService);
  const privacyPolicyPath = useGetPagePathWithLang(Pages.privacyPolicy);

  const [shouldOpenLangSel, setShouldOpenLangSel] = useState(false);

  const toggleThemeMode = () => dispatch(toggleThemeModeAction());

  return (
    <Container
      maxWidth="xs"
      sx={{
        height: '100vh',
        px: 0,
        justifyContent: 'space-between',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        pt: 10,
      }}
    >
      <Box alignItems="center" justifyContent="center" display="flex" flexDirection="column">
        <Box sx={{ justifyContent: 'center', display: 'flex', mb: 8 }}>
          <Logo linkToLandingPageOnWeb size="large" />
        </Box>
        <Paper elevation={3} sx={{ p: [4, 6, 8], mb: 2, mt: 1, width: '100%', borderRadius: [0, 4] }}>
          {headerMessageKey && (
            <Box textAlign="center" mb={8}>
              <Text variant="headlineMedium" translationKey={headerMessageKey} />
            </Box>
          )}
          {children}
        </Paper>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            px: 2,
          }}
        >
          <Stack direction="row" ml={2} alignItems="center">
            <IconButton onClick={() => setShouldOpenLangSel(true)}>
              <LanguageIcon fontSize="small" />
            </IconButton>
            <LanguageSelector
              variant="standard"
              shouldOpen={shouldOpenLangSel}
              onOpen={() => setShouldOpenLangSel(false)}
            />
          </Stack>
          <IconButton
            onClick={toggleThemeMode}
            size="large"
            aria-label="toggle theme mode"
            sx={{ mr: 2, color: palette.app.outline }}
          >
            <LightModeIcon />
          </IconButton>
        </Box>
      </Box>
      <Stack direction="row" justifyContent="center" spacing={6} display="flex" position="sticky" bottom={20}>
        <Button variant="text" color="info" href={termsOfServicePath}>
          {t('common:applicationTermsAndConditions')}
        </Button>
        <Button variant="text" color="info" href={privacyPolicyPath}>
          {t('common:privacyPolicy')}
        </Button>
      </Stack>
    </Container>
  );
};
