export type OrderStatus = 'NEW' | 'ACCEPTED' | 'PAID' | 'CANCELED' | 'NOT_FOUND';
export enum OrderStatuses {
  NEW = 'NEW',
  ACCEPTED = 'ACCEPTED',
  PAID = 'PAID',
  CANCELED = 'CANCELED',
  NOT_FOUND = 'NOT_FOUND',
}

export type Subscription = 'FREE' | 'PRO';
export enum Subscriptions {
  FREE = 'FREE',
  PRO = 'PRO',
}
