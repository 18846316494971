import { Box, Skeleton, Stack } from '@mui/material';
import { random } from 'lodash';

import { MessageSender } from '../types';

import { AssistantIcon } from './AssistantIcon';

interface Props {
  author: MessageSender;
}

export const MessageSkeleton = ({ author }: Props) => {
  const isUser = author === MessageSender.user;

  const randomHeight = random(75, 150);

  return (
    <Stack
      direction={isUser ? 'row-reverse' : 'row'}
      spacing={2}
      alignItems="flex-start"
      pr={isUser ? undefined : [4, 10, 20]}
      pl={isUser ? [4, 10, 20] : undefined}
      alignSelf={isUser ? 'flex-end' : undefined}
      width="100%"
    >
      <Box height={32} width={32} display="flex" alignItems="center" justifyContent="center" pt={1}>
        {!isUser && <AssistantIcon />}
      </Box>
      <Skeleton variant="rounded" width={isUser ? '80%' : '100%'} height={randomHeight} sx={{ borderRadius: 4 }} />
    </Stack>
  );
};
