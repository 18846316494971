import { Button, Box } from '@mui/material';

import { useGetCurrentPageName } from '@/hooks';
import { AssistantGtmEvent, trackAssistentEvent, trackEvent } from '@/services';
import { useAppTranslation } from '@/translation';

interface Props {
  onShowInstructions: () => void;
}

export const HowShouldUserAskStartButton = ({ onShowInstructions }: Props) => {
  const { t } = useAppTranslation();
  const pageName = useGetCurrentPageName();

  const handleShowInstructions = () => {
    trackAssistentEvent(AssistantGtmEvent.checkDemoQuestions, { pageName });
    trackEvent.assistant.checkDemoQuestions(pageName || 'null');
    onShowInstructions();
  };

  return (
    <Box display="flex" justifyContent="center">
      <Button variant="text" onClick={handleShowInstructions} size="small">
        {t('chat:howShouldIAsk')}
      </Button>
    </Box>
  );
};
