import BackIcon from '@mui/icons-material/ArrowBack';
import { Button, ButtonProps } from '@mui/material';

import { Pages } from '@/navigation';
import { useAppTranslation } from '@/translation';

type BackToHomePageButtonProps = ButtonProps;

export const BackToHomePageButton = (props: BackToHomePageButtonProps) => {
  const { t } = useAppTranslation();

  return (
    <Button variant="tonal" startIcon={<BackIcon />} href={Pages.home} {...props}>
      {t('common:backToHomePage')}
    </Button>
  );
};
