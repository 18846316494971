import { useMutation } from '@tanstack/react-query';

import { axiosInstance } from '@/services';

import { SurveyQuestionAnswer, SurveyType } from '../types';

interface UseSendCompletedSurveyAnswerPayload {
  surveyType: SurveyType;
  answers: SurveyQuestionAnswer[];
}

export const useSendCompletedSurveyAnswer = () => {
  const sendCompletedSurveyAnswerRequest = async (payload: UseSendCompletedSurveyAnswerPayload) => {
    const response = await axiosInstance.post<undefined>(`/users/me/survey/`, payload);

    return response;
  };

  const { mutateAsync, isPending: isLoading } = useMutation({
    mutationFn: sendCompletedSurveyAnswerRequest,
  });

  return { sendCompletedSurveyAnswer: mutateAsync, isLoading };
};
