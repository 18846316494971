import { QuizQuestion } from '@/appTypes';

export enum MessageSender {
  user = 'user',
  assistant = 'assistant',
}

export interface Message {
  sender: MessageSender;
  content: string;
  rating: number;
}

export interface Thread {
  id: string;
  topic: string;
  createdAt: string;
  messages: Message[];
  relatedQuestion: QuizQuestion | null;
}

export interface ThreadListElement {
  id: string;
  topic: string;
  createdAt: string;
  hasRelatedQuizQuestion: boolean;
}
