import { axiosInstance } from '@/services';

interface ResetPasswordPayload {
  newPassword: string;
  token: string;
}

export const resetPassword = async ({ newPassword, token }: ResetPasswordPayload): Promise<boolean> => {
  const response = await axiosInstance.patch(`/users/me/reset_password/`, {
    token,
    password: newPassword,
  });

  return response.status === 200;
};
