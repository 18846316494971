import { Box, MenuItem, Select } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { useAppSelector } from '@/hooks';
import { useAppTranslation } from '@/translation';
import { Language } from '@/translation/locales';

import { useUpdateCurrentUserApi } from '../api';

interface LanguageSelectorProps {
  variant: 'standard' | 'outlined';
  shouldOpen?: boolean;
  onOpen?: () => void;
}

export const LanguageSelector = ({ variant, shouldOpen, onOpen }: LanguageSelectorProps) => {
  const { i18n, currentLanguage } = useAppTranslation();
  const [open, setOpen] = useState(false);
  const currentUserId = useAppSelector((state) => state.user?.currentUser?.id);
  const { updateCurrentUser } = useUpdateCurrentUserApi();

  const updateLanguageOnServer = useCallback(
    (newLanguage: string) => {
      if (!currentUserId) return null;

      updateCurrentUser({
        userId: currentUserId,
        language: newLanguage,
      });
    },
    [currentUserId, updateCurrentUser],
  );

  const handleOpen = useCallback(() => {
    setOpen(true);
    if (onOpen) onOpen();
  }, [onOpen]);

  useEffect(() => {
    if (shouldOpen) handleOpen();
  }, [handleOpen, shouldOpen]);

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    updateLanguageOnServer(language);
  };

  const isStandard = variant === 'standard';

  return (
    <Box width={150} alignContent="center" alignItems="center">
      <Select
        id="language-selector"
        value={currentLanguage}
        onChange={(event) => changeLanguage(event.target.value)}
        fullWidth
        open={open}
        onOpen={handleOpen}
        onClose={() => setOpen(false)}
        size="small"
        variant={variant}
        disableUnderline
        IconComponent={isStandard ? () => null : undefined}
        inputProps={isStandard ? { sx: { pb: 0 } } : {}}
      >
        <MenuItem value={Language.PL}>{'Polski'}</MenuItem>
        <MenuItem value={Language.EN_GB}>{'English'}</MenuItem>
      </Select>
    </Box>
  );
};
