import { Button, styled } from '@mui/material';

export const ProfileDropdownButton = styled(Button)(({ theme }) => ({
  color: theme.palette.app.primary,
  borderColor: theme.palette.app.outline,
  borderRadius: 100,
  padding: '10px 16px 10px 24px',
  textTransform: 'none',
}));

export const HeaderBar = styled('header')({
  padding: 0,
  marginTop: 4,
  paddingBottom: 4,
  height: 72,
  justifyContent: 'center',
  alignItems: 'center',
});
