import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { useEffect } from 'react';

// import { googleAndroidOAuthClientId } from '@/config';

import { GoogleOAuthProviderProps } from './types';

export const GoogleOAuthProvider = ({ children }: GoogleOAuthProviderProps) => {
  useEffect(() => {
    GoogleAuth.initialize();
  }, []);

  return children;
};
