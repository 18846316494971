import { axiosInstance } from '@/services';
import { Language } from '@/translation/locales';

interface RegisterPayload {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  language: Language;
  hasTermsAndConditionsAccepted: boolean;
}

interface RegisterResponse {
  id: string;
  username: string;
  name: string;
  surname: string;
  password: string;
  language: Language;
  hasTermsAndConditionsAccepted: boolean;
}

interface RegisterProcessedResponse {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  hasTermsAndConditionsAccepted: boolean;
}

export const register = async (payload: RegisterPayload): Promise<RegisterProcessedResponse> => {
  const response = await axiosInstance.post<RegisterResponse>('/users/', {
    name: payload.firstName,
    surname: payload.lastName,
    username: payload.email,
    password: payload.password,
    language: payload.language,
    hasTermsAndConditionsAccepted: payload.hasTermsAndConditionsAccepted,
  });

  return {
    id: response.data.id,
    firstName: response.data.name,
    lastName: response.data.surname,
    email: response.data.username,
    hasTermsAndConditionsAccepted: response.data.hasTermsAndConditionsAccepted,
  };
};
