import { Box } from '@mui/material';

import { Text } from '@/components/elements';

export const MaxTwoQuestionPerThreadInfo = () => {
  return (
    <Box display="flex" justifyContent="center">
      <Text translationKey="chat:maxTwoQuestionPerThread" />
    </Box>
  );
};
