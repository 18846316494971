import { useCallback, useEffect } from 'react';

import { useAppDispatch, useAppNavigate, useAppSelector } from '@/hooks';
import { Pages } from '@/navigation';

import { setQuestionToAsk } from '../store';

interface Params {
  onNewQuestion?: undefined | ((question: string) => void);
}

export const useAskNewQuestionFromStore = ({ onNewQuestion }: Params = {}) => {
  const { navigate } = useAppNavigate();
  const newQuestionToAsk = useAppSelector((store) => store.chat.questionToAsk);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (newQuestionToAsk && onNewQuestion) {
      onNewQuestion(newQuestionToAsk);
      dispatch(setQuestionToAsk({ question: null }));
    }
  }, [dispatch, newQuestionToAsk, onNewQuestion]);

  const startNewThreadOnChatPage = useCallback(
    (question: string) => {
      dispatch(setQuestionToAsk({ question }));
      navigate(Pages.assistantChat);
    },
    [dispatch, navigate],
  );

  return { startNewThreadOnChatPage };
};
