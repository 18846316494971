import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query';

import { Quiz } from '@/appTypes';
import { getQuizQueryKey } from '@/features/Quiz/api';
import { setThreadToQuizQuestion } from '@/features/Quiz/store';
import { adjustQuizQuestionAnswerForFrontend } from '@/features/Quiz/utils';
import { useRefreshCurrentUserData } from '@/features/User';
import { decrementAvailableThreads } from '@/features/User/store';
import { useAppDispatch, useNotify, useGetCurrentPageName } from '@/hooks';
import { AssistantGtmEvent, axiosInstance, trackAssistentEvent, trackEvent } from '@/services';
import { useAppTranslation } from '@/translation';

import { Thread } from '../types';
import { addThreadToQuizQuestion } from '../utils';

import { GetChatHistoryResponse, chatHistoryQueryKey } from './useGetChatHistory';
import { getThreadQueryKey } from './useGetThread';

export interface StartNewThreadPayload {
  question: string;
  relatedQuestionId?: string;
}

const endpoint = '/chat/';

interface Params {
  quizId?: string;
}

export const useStartNewThread = ({ quizId }: Params = {}) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const { refreshCurrentUserData } = useRefreshCurrentUserData();
  const { notify } = useNotify();
  const { t } = useAppTranslation();
  const pageName = useGetCurrentPageName();

  const startThreadRequest = async (payload: StartNewThreadPayload) => {
    const response = await axiosInstance.post<Thread | undefined>(endpoint, payload);

    return response?.data;
  };

  const {
    mutateAsync,
    isPending: isLoading,
    isIdle,
  } = useMutation({
    mutationFn: startThreadRequest,
    onSuccess: (thread, { relatedQuestionId }) => {
      if (!thread) return;

      trackAssistentEvent(AssistantGtmEvent.startNewThread, { withQuizQuestion: !!relatedQuestionId, pageName });
      trackEvent.assistant.startNewThread(!!relatedQuestionId, pageName || 'null');
      dispatch(decrementAvailableThreads());

      queryClient.setQueryData<Thread>([getThreadQueryKey, thread.id], {
        ...thread,
        relatedQuestion: thread.relatedQuestion ? adjustQuizQuestionAnswerForFrontend(thread.relatedQuestion) : null,
      });
      queryClient.setQueryData<InfiniteData<GetChatHistoryResponse>>([chatHistoryQueryKey], (threadsData) => {
        if (!threadsData) return undefined;

        const updatedFirstPage = threadsData.pages[0];
        if (!updatedFirstPage) return;
        updatedFirstPage.count += 1;
        updatedFirstPage.results.unshift({
          createdAt: thread.createdAt,
          id: thread.id,
          topic: thread.topic,
          hasRelatedQuizQuestion: !!thread.relatedQuestion,
        });

        return {
          ...threadsData,
          pages: [updatedFirstPage, ...threadsData.pages.slice(1)],
        };
      });

      if (quizId) {
        if (relatedQuestionId) dispatch(setThreadToQuizQuestion({ quizQuestionId: relatedQuestionId, thread }));

        queryClient.setQueryData<Quiz>(getQuizQueryKey(quizId), (quiz) => {
          if (!quiz) return;

          return addThreadToQuizQuestion({ quiz, relatedQuestionId: relatedQuestionId || null, thread });
        });
      }
    },
    onError: () => {
      refreshCurrentUserData();
      notify('error', t('common:sorrySomethingWentWrong'));
    },
  });

  return {
    startNewThread: mutateAsync,
    isLoading,
    isIdle,
  };
};
