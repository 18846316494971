import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { Text } from '@/components/elements';
import { ControlledPasswordField } from '@/components/form';
import { Meta } from '@/components/layout';
import { Pages } from '@/navigation';
import { TranslationKeys, useAppTranslation } from '@/translation';

import { LoginPageTemplate } from '../../components';
import { useResetPasswordForm } from '../../hooks';

export const ResetPasswordPage = () => {
  const { t } = useAppTranslation();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [showLinkExpiredError, setShowLinkExpiredError] = useState(false);

  const { form, onSubmitPress, isSubmitting } = useResetPasswordForm({
    token: token || '',
    onLinkExpired: () => setShowLinkExpiredError(true),
  });

  const headerMessageKey: TranslationKeys = showLinkExpiredError ? 'user:yourLinkExpired' : 'user:setNewPassword';

  return (
    <LoginPageTemplate headerMessageKey={headerMessageKey}>
      <Meta titleKey="user:resetPassword" />
      {showLinkExpiredError && (
        <>
          <Text variant="bodyMedium" translationKey="user:probablyYourLinkToResetPasswordHasExpired" />
          <Stack mt={8} alignItems="center">
            <Button type="submit" variant="contained" href={Pages.recoverPassword}>
              {t('user:recoverPassword')}
            </Button>
          </Stack>
        </>
      )}

      {!showLinkExpiredError && (
        <>
          <Text variant="bodyMedium" translationKey="user:afterResettingPasswordYouWillBeAbleToLoginUsingIt" />
          <FormProvider {...form}>
            <Stack spacing={8} sx={{ mt: 4 }} alignItems="center">
              <ControlledPasswordField
                id="newPassword"
                name="newPassword"
                label={t('user:password')}
                autoComplete="new-password"
              />
              <LoadingButton
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={onSubmitPress}
                loading={isSubmitting}
              >
                {t('user:resetPassword')}
              </LoadingButton>
            </Stack>
          </FormProvider>
        </>
      )}
    </LoginPageTemplate>
  );
};
