import { Box } from '@mui/material';

import { Text } from '../Text';

import { Td } from './styled';

interface NoRowsInfoProps {
  message?: string;
}

export const NoRowsInfo = ({ message }: NoRowsInfoProps) => {
  return (
    <tr>
      <Td colSpan={100}>
        <Box sx={{ p: 10 }}>
          <Text variant="headlineSmall" textAlign="center">
            {message}
          </Text>
        </Box>
      </Td>
    </tr>
  );
};
