import { useCallback } from 'react';

import { useAppDispatch } from '@/hooks';
import { useAppTranslation } from '@/translation';

import { useGetCurrentUserApi } from '../api';
import { setCurrentUserData } from '../store';

export const useRefreshCurrentUserData = () => {
  const dispatch = useAppDispatch();
  const { refetch, isLoading } = useGetCurrentUserApi();
  const { i18n } = useAppTranslation();

  const refreshCurrentUserData = useCallback(async () => {
    if (isLoading) return null;
    const currentUserRes = await refetch();

    dispatch(setCurrentUserData(currentUserRes.data || null));
    if (currentUserRes.data?.language) await i18n.changeLanguage(currentUserRes.data.language);

    return currentUserRes.data || null;
  }, [dispatch, i18n, isLoading, refetch]);

  return { refreshCurrentUserData };
};
