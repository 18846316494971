import 'dayjs/locale/pl';
import 'dayjs/locale/en-gb';
import dayjs from 'dayjs';
import { useEffect } from 'react';

import { useAppTranslation } from './useAppTranslation';

export const useConfigDayjsLang = () => {
  const { currentLanguage } = useAppTranslation();

  useEffect(() => {
    dayjs.locale(currentLanguage);
  }, [currentLanguage]);
};
