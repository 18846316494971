import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import { setInitialStateOnNewQuiz } from '@/features/Quiz/store';
import { getFirstUnansweredQuestionIndex } from '@/features/Quiz/utils';
import { useAppDispatch, useAppNavigate } from '@/hooks';
import { Pages } from '@/navigation';
import { QuizGtmEvent, trackEvent, trackEventQuiz } from '@/services';
import { useAppTranslation } from '@/translation';

import { useGetLastUnfinishedQuiz } from '../api';

import { HomeBox } from './HomeBox';

export const ResumeQuiz = () => {
  const dispatch = useAppDispatch();
  const { t } = useAppTranslation();
  const { navigate } = useAppNavigate();

  const { lastUnfinishedQuiz, isLoading } = useGetLastUnfinishedQuiz();

  if (isLoading || !lastUnfinishedQuiz) return null;

  const resumeQuiz = () => {
    if (!lastUnfinishedQuiz) return;

    trackEventQuiz(QuizGtmEvent.resumeQuiz);
    trackEvent.quiz.resumeQuiz(lastUnfinishedQuiz.id);
    const firstUnansweredQuestion = (getFirstUnansweredQuestionIndex(lastUnfinishedQuiz) || 0) + 1;

    dispatch(setInitialStateOnNewQuiz({ quiz: lastUnfinishedQuiz }));
    navigate(Pages.quiz, { id: lastUnfinishedQuiz.id }, { question: firstUnansweredQuestion.toString() });
  };

  return (
    <HomeBox
      title={t('home:unfinishedQuizResumeItNow')}
      message={t('home:unfinishedQuizBody')}
      buttonLabel={t('home:resumeQuiz')}
      onClick={resumeQuiz}
      surfaceVariant="surface4"
      buttonIcon={<PlayArrowIcon />}
    />
  );
};
