import { styled } from '@mui/material';

export const Th = styled('th')({
  display: 'flex',
  padding: '12px 4px',
});

export const Thead = styled('thead')(({ theme }) => ({
  display: 'grid',
  position: 'sticky',
  top: 0,
  zIndex: 1,
  backgroundColor: theme.palette.app.background,
}));

export const Table = styled('table')({
  tableLayout: 'fixed',
  display: 'grid',
});

export const Td = styled('td')({
  padding: '6px',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
});

export const Tr = styled('tr')(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  display: 'flex',
  '&:not(.edge) + tr:not(.edge) td': {
    borderTop: `1px solid ${theme.palette.app.outlineVariant}`,
  },
  ':hover': {
    backgroundColor: theme.palette.app.surface1,
  },
}));
