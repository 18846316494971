import { useQuery } from '@tanstack/react-query';

import { adjustQuizQuestionAnswerForFrontend } from '@/features/Quiz/utils';
import { axiosInstance } from '@/services';

import { Thread } from '../types';

export const getThreadQueryKey = 'thread';
const endpoints = '/chat/:id/';

const OneDay = 1000 * 60 * 60 * 24;

export const useGetThread = (id: string | null | undefined) => {
  const getThreadRequest = async () => {
    const response = await axiosInstance.get<Thread | undefined>(endpoints.replace(':id', id!));

    if (response.status === 404) return null;

    if (response.data?.relatedQuestion)
      response.data.relatedQuestion = adjustQuizQuestionAnswerForFrontend(response.data.relatedQuestion);

    return response.data;
  };

  const { data, isLoading } = useQuery({
    queryKey: [getThreadQueryKey, id],
    queryFn: getThreadRequest,
    enabled: !!id,
    staleTime: OneDay,
  });

  return {
    thread: data,
    isLoading,
  };
};
