import { axiosInstance } from '@/services';

interface RecoverPasswordPayload {
  email: string;
}

export const recoverPassword = async ({ email }: RecoverPasswordPayload): Promise<boolean> => {
  const response = await axiosInstance.post(`/users/forgot_password/`, {
    username: email,
  });

  return response.status === 200;
};
