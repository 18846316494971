import { useTheme } from '@mui/material/styles';
import { Transition, Variants, motion } from 'framer-motion';

import SygnetWhiteIcon from '@/assets/icons/sygnetWhite.svg';

interface Props {
  pulse?: boolean;
}

const variants: Variants = {
  start: {
    scale: 0.9,
    rotate: 0,
  },
  end: {
    scale: 1.1,
    rotate: 0,
  },
};

const transition: Transition = {
  duration: 0.8,
  repeat: Infinity,
  repeatType: 'reverse',
};

export const AssistantIcon = ({ pulse }: Props) => {
  const { palette } = useTheme();

  return (
    <motion.div
      animate={pulse ? 'end' : undefined}
      variants={variants}
      transition={transition}
      style={{ display: 'flex' }}
    >
      <SygnetWhiteIcon height={20} width={24} color={palette.app.secondary} />
    </motion.div>
  );
};
