import { Stack } from '@mui/material';

import { Text } from '@/components/elements';

import { LanguageSelector } from './LanguageSelector';

export const UserProfileLanguageSelector = () => {
  return (
    <Stack spacing={4} maxWidth={500}>
      <Stack spacing={2}>
        <Text variant="titleLarge" translationKey="user:applicationsLanguage" />
        <Text variant="bodyMedium" translationKey="user:applicationsLanguageDescription" />
      </Stack>
      <LanguageSelector variant="outlined" />
    </Stack>
  );
};
