import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { useState } from 'react';

import { useAppDispatch, useNotify } from '@/hooks';
import { captureError, trackEvent } from '@/services';
import { useAppTranslation } from '@/translation';

import { ssoLogin } from '../../api';
import { setSession } from '../../store';

import { UseSSOLogin } from './types';

export const useSSOLogin: UseSSOLogin = () => {
  const dispatch = useAppDispatch();
  const { t } = useAppTranslation();
  const { notify } = useNotify();

  const [isLoading, setIsLoading] = useState(false);

  const genericFailure = (error: unknown) => {
    notify('error', t('user:ssoLoginFailed'));
    captureError(new Error('Google SSO login failed:' + JSON.stringify(error)));
    setIsLoading(false);
  };

  const loginUsingGoogle = async () => {
    setIsLoading(true);
    try {
      const user = await GoogleAuth.signIn();
      const accessToken = user.authentication.accessToken;

      const tokens = await ssoLogin({
        type: 'google',
        payload: { accessToken },
      });

      trackEvent.user.login('google');
      dispatch(setSession(tokens));
      setIsLoading(false);
    } catch (error) {
      genericFailure(error);
    }
  };

  return {
    loginUsingGoogle,
    isLoading,
  };
};
