import { useNavigate, useSearchParams } from 'react-router-dom';

import { Pages } from '@/navigation';

export const useNavigateFromLogin = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const navigateToHomeOrFrom = () => {
    const from = searchParams.get('from') || Pages.home;
    navigate(from, { replace: true });
  };

  return { navigateToHomeOrFrom };
};
