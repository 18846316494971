import { axiosInstance } from '@/services';

export interface ConfirmRegistrationPayload {
  token: string;
}

export interface ConfirmRegistrationResponse {
  access: string;
  refresh: string;
}

export interface ConfirmRegistrationProcessedResponse {
  accessToken: string;
  refreshToken: string;
}

export const confirmRegistration = async (
  payload: ConfirmRegistrationPayload,
): Promise<ConfirmRegistrationProcessedResponse> => {
  const response = await axiosInstance.patch<ConfirmRegistrationResponse>('/users/verify_account/', payload);
  return {
    accessToken: response.data.access,
    refreshToken: response.data.refresh,
  };
};
