import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import SygnetWhiteIcon from '@/assets/icons/sygnetWhite.svg';

import { Text } from './Text';

export const SomethingNewBadge = () => {
  const { palette } = useTheme();

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      sx={{
        backgroundColor: palette.app.tertiary,
        borderRadius: 2,
        px: 2,
        py: 1,
        width: 'fit-content',
      }}
    >
      <SygnetWhiteIcon height={18} color={palette.app.onSecondary} />
      <Text variant="labelMedium" translationKey="common:somethingNew" color="onSecondary" />
    </Stack>
  );
};
