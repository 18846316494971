import { Box, Stack } from '@mui/material';
import { FormProvider } from 'react-hook-form';

import { Text } from '@/components/elements';
import { ControlledButton, ControlledTextField } from '@/components/form';
import { useAppTranslation } from '@/translation';

import { useChangePasswordForm } from '../../hooks';

export const ChangePasswordForm = () => {
  const { t } = useAppTranslation();
  const { form, onSubmitPress, isFormEdited } = useChangePasswordForm();

  return (
    <Stack spacing={8} flex={1}>
      <Text variant="titleLarge">{t('user:changePassword')}</Text>
      <FormProvider {...form}>
        <Stack spacing={6} maxWidth={450} minWidth={200} flex={1}>
          <ControlledTextField
            name="newPassword"
            label={t('user:newPassword')}
            type="password"
            size="small"
            fullWidth
            autoComplete="new-password"
            alwaysShowLabel
          />
          <ControlledTextField
            name="repeatNewPassword"
            label={t('user:repeatNewPassword')}
            type="password"
            size="small"
            fullWidth
            autoComplete="new-password"
            alwaysShowLabel
          />
          <Box justifyContent="center" display="flex" flex={1}>
            <ControlledButton type="submit" variant={isFormEdited ? 'contained' : 'outlined'} onClick={onSubmitPress}>
              {t('user:changePassword')}
            </ControlledButton>
          </Box>
        </Stack>
      </FormProvider>
    </Stack>
  );
};
