import { Tooltip, alpha } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Subscription, Subscriptions } from '@/appTypes/Subscription';
import { planFullName } from '@/features/Subscription/utils/planName';
import { Pages } from '@/navigation';
import { useAppTranslation } from '@/translation';

import { Text } from '../Text';

import { BadgeBox } from './styled';

interface PlanLimitBadgeProps {
  forPlan: Subscription;
  extendedMessage?: boolean;
  customMessage?: string;
}

export const PlanLimitBadge = ({ forPlan, extendedMessage, customMessage }: PlanLimitBadgeProps) => {
  const { t } = useAppTranslation();
  const { palette } = useTheme();
  const planName = t(planFullName[forPlan]);

  const bgColor = alpha(forPlan === Subscriptions.PRO ? palette.app.primaryFixed : palette.app.secondaryFixed, 0.6);
  const color = forPlan === Subscriptions.PRO ? palette.app.onPrimaryFixed : palette.app.onSecondaryFixed;
  const standardMessage = extendedMessage ? t('common:upgradePlan') : t('common:availableInPlanName', { planName });
  const finalMessage = customMessage || standardMessage;

  return (
    <Tooltip title={finalMessage}>
      <BadgeBox bgColor={bgColor} sx={{ color }} href={Pages.userSubscriptionSummary} fullWidth>
        {extendedMessage ? (
          <Text variant="labelLarge" translationKey="common:availableInPlanName" translationParams={{ planName }} />
        ) : (
          <Text variant="labelLarge">{planName}</Text>
        )}
      </BadgeBox>
    </Tooltip>
  );
};
