import { Box, Stack } from '@mui/material';
import { memo, useCallback, useMemo } from 'react';

import { GroupedArticles, GroupsOfArticles } from '@/appTypes';
import { Text } from '@/components/elements';
import { useFeatureAvailable } from '@/features/User';

import { useCreateQuizFormContext } from '../../hooks';

import { ArticleGroup } from './ArticleGroup';

interface SelectableArticlesProps {
  disabled?: boolean;
}

export const SelectableArticles = memo(({ disabled }: SelectableArticlesProps) => {
  const { watch, setValue, formState } = useCreateQuizFormContext();
  const { selectingArticles: isSelectingArticlesAvailable } = useFeatureAvailable();

  const selectedGroups = watch('selectedGroups');
  const isSubmitting = formState.isSubmitting;

  const toggleArticleGroup = useCallback(
    (groupKey: GroupsOfArticles) => {
      if (selectedGroups.includes(groupKey)) {
        setValue(
          'selectedGroups',
          selectedGroups.filter((group) => group !== groupKey),
        );
      } else {
        setValue('selectedGroups', [...selectedGroups, groupKey]);
      }
    },
    [selectedGroups, setValue],
  );

  const errorMessage = formState.errors.selectedGroups?.message;

  const groupOfArticles = useMemo(
    () =>
      Object.keys(GroupsOfArticles).map((groupKey) => {
        const articles = GroupedArticles[groupKey as GroupsOfArticles];
        const isSelected = selectedGroups.includes(groupKey as GroupsOfArticles);

        return (
          <ArticleGroup
            key={groupKey}
            groupKey={groupKey}
            articles={articles}
            disabled={disabled || !isSelectingArticlesAvailable || isSubmitting}
            isSelected={isSelected}
            onClick={() => toggleArticleGroup(groupKey as GroupsOfArticles)}
            isSelectingArticlesAvailable={isSelectingArticlesAvailable}
          />
        );
      }),
    [disabled, isSelectingArticlesAvailable, isSubmitting, selectedGroups, toggleArticleGroup],
  );

  return (
    <Box>
      <Stack direction="row" flexWrap="wrap" rowGap={4} columnGap={4} maxWidth={800}>
        {groupOfArticles}
      </Stack>

      {errorMessage && (
        <Text variant="bodySmall" customColor="app.error">
          {errorMessage}
        </Text>
      )}
    </Box>
  );
});
