import { useQuery } from '@tanstack/react-query';

import { User, UserFromBE } from '@/appTypes/User';
import { axiosInstance, updateTokenInterceptor } from '@/services';

import { processUserBeToFe } from '../utils';

export const getCurrentUserQueryKey = 'getCurrentUser';
const endpoint = '/users/me/';

export const useGetCurrentUserApi = () => {
  const {
    data: user,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery<User>({
    queryKey: [getCurrentUserQueryKey],
    queryFn: async () => {
      await updateTokenInterceptor();
      const res = await axiosInstance.get<UserFromBE>(endpoint);
      return processUserBeToFe(res.data);
    },
    enabled: false,
  });

  return {
    user,
    refetch,
    isLoading: isLoading || isRefetching,
  };
};
