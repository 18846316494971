import { axiosInstance } from '@/services';

interface SsoLoginParams {
  type: 'google' | 'facebook';
  payload: {
    accessToken: string;
  };
}

interface SsoLoginResponse {
  access: string;
  refresh: string;
}

interface SsoLoginProcessedResponse {
  accessToken: string;
  refreshToken: string;
}

export const ssoLogin = async (data: SsoLoginParams): Promise<SsoLoginProcessedResponse> => {
  const response = await axiosInstance.post<SsoLoginResponse>(`/dj-rest-auth/${data.type}/`, data.payload);

  return {
    accessToken: response.data.access,
    refreshToken: response.data.refresh,
  };
};
