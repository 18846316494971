import { axiosInstance } from '@/services';

interface LoginPayload {
  email: string;
  password: string;
}

interface LoginResponse {
  access: string;
  refresh: string;
}

interface LoginProcessedResponse {
  accessToken: string;
  refreshToken: string;
}

export const login = async ({ password, email }: LoginPayload): Promise<LoginProcessedResponse> => {
  const response = await axiosInstance.post<LoginResponse>('/token', {
    username: email,
    password,
  });

  return {
    accessToken: response.data.access,
    refreshToken: response.data.refresh,
  };
};
