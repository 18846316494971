import { Quiz } from '@/appTypes';

interface Params {
  quiz: Quiz;
  quizQuestionId: string;
  messageIndex: number;
  rate: number;
}

export const addThreadMessageRateToQuizQuestion = ({ quiz, messageIndex, quizQuestionId, rate }: Params) => {
  if (!quizQuestionId) return quiz;

  const quizQuestions = [...quiz.quizQuestions];
  const quizQuestionIndex = quizQuestions.findIndex((quizQuestion) => quizQuestion.id === quizQuestionId);

  if (quizQuestionIndex === -1) return quiz;

  const chatThreads = quizQuestions[quizQuestionIndex].chatThreads;
  if (!chatThreads) return quiz;

  const thread = chatThreads[0];
  if (!thread) return quiz;

  const messages = [...thread.messages];
  if (messages[messageIndex]) {
    messages[messageIndex] = {
      ...messages[messageIndex],
      rating: rate,
    };
  }

  quizQuestions[quizQuestionIndex] = {
    ...quizQuestions[quizQuestionIndex],
    chatThreads: [
      {
        ...thread,
        messages,
      },
    ],
  };

  return {
    ...quiz,
    quizQuestions,
  };
};
