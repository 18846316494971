import { Alert, Box, Button, Stack } from '@mui/material';
import { ReactNode } from 'react';

import { Pages } from '@/navigation';

interface PlanLimitAlertProps {
  children: ReactNode;
  buttonLabel?: string;
  // use contained button if the alert is about reaching the limit of the current plan
  containedButton?: boolean;
}

export const PlanLimitAlert = ({ children, buttonLabel, containedButton }: PlanLimitAlertProps) => {
  return (
    <Alert severity="info" sx={{ p: [4] }}>
      <Stack direction={['column', 'row']} justifyContent="space-between" columnGap={4} rowGap={4}>
        <Box>{children}</Box>
        <Box display="flex" minWidth={150} justifyContent="center" alignItems="center">
          <Button
            variant={containedButton ? 'contained' : 'outlined'}
            color="secondary"
            href={Pages.userSubscriptionSummary}
          >
            {buttonLabel}
          </Button>
        </Box>
      </Stack>
    </Alert>
  );
};
