import { Answer, Quiz } from '@/appTypes';

interface GetQuizQuestion {
  quiz: Quiz | null;
  quizQuestionId: string;
}
export const getQuizQuestion = ({ quiz, quizQuestionId }: GetQuizQuestion) => {
  return quiz?.quizQuestions.find((quizQuestion) => quizQuestion.id === quizQuestionId);
};

interface AddAnswerForQuestionParams {
  quiz: Quiz | null;
  quizQuestionId: string;
  answer: Answer;
  onIndex?: number;
}
export const addAnswerForQuestion = ({
  quiz,
  quizQuestionId,
  answer,
  onIndex,
}: AddAnswerForQuestionParams): Quiz | null => {
  if (!quiz) return null;

  return {
    ...quiz,
    quizQuestions: quiz?.quizQuestions.map((quizQuestion) => {
      if (quizQuestion.id === quizQuestionId) {
        return {
          ...quizQuestion,
          answerProvided: [
            ...(quizQuestion.answerProvided?.slice(0, onIndex) || []),
            answer,
            ...(onIndex !== undefined ? quizQuestion.answerProvided?.slice(onIndex) || [] : []),
          ],
        };
      }
      return quizQuestion;
    }),
  };
};

interface RemoveAnswerFromQuestionParams {
  quiz: Quiz | null;
  quizQuestionId: string;
  answer?: Answer;
  answerIndex?: number;
}
export const removeAnswerFromQuestion = ({
  quiz,
  quizQuestionId,
  answer,
  answerIndex,
}: RemoveAnswerFromQuestionParams): Quiz | null => {
  if (!quiz) return null;

  return {
    ...quiz,
    quizQuestions: quiz?.quizQuestions.map((quizQuestion) => {
      if (quizQuestion.id === quizQuestionId) {
        return {
          ...quizQuestion,
          answerProvided:
            quizQuestion.answerProvided?.length === 1
              ? null
              : quizQuestion.answerProvided?.filter((answerProvided, index) => {
                  if (answerIndex !== undefined) return index !== answerIndex;
                  return answerProvided.id !== answer?.id;
                }) || null,
        };
      }
      return quizQuestion;
    }),
  };
};

interface OverrideAnswerForQuestionParams {
  quiz: Quiz | null;
  quizQuestionId: string;
  answer: Answer;
}
export const overrideAnswerForQuestion = ({
  quiz,
  quizQuestionId,
  answer,
}: OverrideAnswerForQuestionParams): Quiz | null => {
  if (!quiz) return null;

  return {
    ...quiz,
    quizQuestions: quiz?.quizQuestions.map((quizQuestion) => {
      if (quizQuestion.id === quizQuestionId) {
        return {
          ...quizQuestion,
          answerProvided: [answer],
        };
      }
      return quizQuestion;
    }),
  };
};
