import { Box, Button, styled } from '@mui/material';

export const QuestionNumberButton = styled(Button, {
  shouldForwardProp: (prop) => {
    return prop !== 'isCurrent';
  },
})<{ isCurrent: boolean }>(({ theme, isCurrent }) => ({
  padding: '6px 16px',
  borderRadius: 8,
  border: `1px solid ${theme.palette.app.outline}`,
  minWidth: 0,
  marginRight: 8,
  transition: 'all .3s ease-in-out',
  borderColor: isCurrent ? theme.palette.app.primaryContainer : theme.palette.app.outline,
  color: isCurrent ? theme.palette.app.onPrimaryContainer : theme.palette.app.onSurfaceVariant,
  backgroundColor: isCurrent ? theme.palette.app.primaryContainer : theme.palette.app.surfaceContainerLowest,
  '@media (hover: none)': {
    '&.MuiButton-root:hover': {
      backgroundColor: isCurrent ? theme.palette.app.primaryContainer : theme.palette.app.surfaceContainerLowest,
    },
  },
}));

interface EdgeGradientProps {
  edge: 'left' | 'right';
  hide: boolean;
}

export const EdgeGradient = styled(Box, {
  shouldForwardProp: (prop) => {
    return prop !== 'edge' && prop !== 'hide';
  },
})<EdgeGradientProps>(({ edge, hide, theme }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  width: hide ? 0 : 16,
  background: `linear-gradient(to ${edge}, rgba(255,255,255,0) 0%, ${theme.palette.app.background} 100%)`,
  pointerEvents: 'none',
  zIndex: 1,
  [edge]: 0,
  opacity: hide ? 0 : 1,
  transition: 'all 0.3s ease-in-out',
  marginLeft: edge === 'left' ? '-8px' : 0,
  marginRight: edge === 'right' ? '-4px' : 0,
}));
