import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Button, ButtonBase, Divider, IconButton, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import { useCallback } from 'react';

import { PassRatePercentages, Quiz, QuizMode } from '@/appTypes';
import { Text } from '@/components/elements';
import { useAppNavigate } from '@/hooks';
import { Pages } from '@/navigation';
import { useAppTranslation } from '@/translation';

import { modeTranslationKey } from '../../utils';
import { NotFinishedQuizChip } from '../NotFinishedQuizChip';

interface Props {
  quiz: Quiz;
  isLastElement: boolean;
}

export const HistoryListElementNative = ({ quiz, isLastElement }: Props) => {
  const { t } = useAppTranslation();
  const { palette } = useTheme();
  const { navigate } = useAppNavigate();

  const openQuiz = useCallback((quizId: string) => navigate(Pages.quizSummary, { id: quizId }), [navigate]);
  const resumeQuiz = useCallback(
    (event: React.MouseEvent, quizId: string) => {
      event.stopPropagation();
      navigate(Pages.quiz, { id: quizId });
    },
    [navigate],
  );

  const isReviewMistakesMode = quiz.mode === QuizMode.reviewMistakes;
  const percentage = Math.round(((quiz.positiveAnswersCount || 0) / (quiz.questionsAmount || 0)) * 100);
  const color = percentage >= PassRatePercentages ? palette.app.positive : palette.app.error;

  return (
    <Box flex={1} display="flex" height={110} flexDirection="column">
      <ButtonBase onClick={() => openQuiz(quiz.id)} sx={{ textAlign: 'left', flex: 1 }}>
        <Stack key={quiz.id} direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2, flex: 1 }}>
          <Stack spacing={1} sx={{ flexGrow: 1 }}>
            <Text variant="bodyMedium">{dayjs(quiz.createdAt).format('DD.MM.YYYY HH:mm')}</Text>
            <Text variant="bodyMedium">{t(modeTranslationKey[quiz.mode])}</Text>
            {!!quiz.completeDate && (
              <Stack flex={1} spacing={4} direction="row">
                <Text variant="bodyMedium" noWrap>
                  {'Wynik: '}
                  {`${quiz.positiveAnswersCount} / ${quiz.questionsAmount}`}
                </Text>
                {!isReviewMistakesMode && <Text variant="bodyMedium" customColor={color}>{`${percentage}%`}</Text>}
              </Stack>
            )}
            <Text variant="bodyMedium">
              {'Liczba artykułów: '}
              {quiz.relatedArticles.length}
            </Text>
          </Stack>
          {!quiz.completeDate && (
            <Stack spacing={0} justifyContent="flex-end" alignItems="center" sx={{ mt: 2 }}>
              <NotFinishedQuizChip />
              <Button variant="text" onClick={(event) => resumeQuiz(event, quiz.id)}>
                {t('quiz:resumeQuiz')}
              </Button>
            </Stack>
          )}
          <IconButton>
            <ChevronRightIcon />
          </IconButton>
        </Stack>
      </ButtonBase>
      {!isLastElement && <Divider />}
    </Box>
  );
};
