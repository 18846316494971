import { createSlice } from '@reduxjs/toolkit';

import { Answer, AnswerNotationBaseOptionsKeys, AnswerNotationOptionsKey, Quiz } from '@/appTypes';
import { Thread } from '@/features/Chat/types';
import { addThreadMessageRateToQuizQuestion, addThreadToQuizQuestion } from '@/features/Chat/utils';

import {
  getQuizQuestion,
  removeAnswerFromQuestion,
  addAnswerForQuestion,
  overrideAnswerForQuestion,
} from './quizSliceHelpers';

export type AnsweringState = 'answering' | 'answered' | 'correct' | 'inCorrect' | 'checkingAnswer';

interface QuizState {
  visitedQuestions: number[];
  answeringState: AnsweringState;
  userAnswer: Answer[];
  quiz: Quiz | null;
}

const initialState: QuizState = {
  visitedQuestions: [],
  answeringState: 'answering',
  userAnswer: [],
  quiz: null,
};

interface ChangeToQuestionPayload {
  nextAnsweringState: AnsweringState;
  nextQuestionNumber: number;
}

interface UpdateQuestionProvidedAnswerPayload {
  questionId: string;
  isCorrect: boolean;
  answerProvided: string[];
}

interface UpdateQuestionReportedAtPayload {
  questionId: string;
  reportedAt: Date;
}

interface ToggleUserAnswerPayload {
  quizQuestionId: string;
  answer: Answer;
}

interface DeleteAnswerNotationElementPayload {
  token: AnswerNotationOptionsKey;
  quizQuestionId: string;
}

interface ToggleUserAnswerKeyPayload {
  answerKey: AnswerNotationOptionsKey;
  quizQuestionId: string;
}

interface SetUserAnswerPayload {
  answer: Answer;
  quizQuestionId: string;
}

interface SetInitialStateOnNewQuizPayload {
  quiz: Quiz;
  demo?: boolean;
}

interface SetThreadToQuizQuestionPayload {
  quizQuestionId: string;
  thread: Thread;
}

interface SetThreadMessageRateToQuizQuestionPayload {
  quizQuestionId: string;
  messageIndex: number;
  rate: number;
}

export const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    addVisitedQuestion: (state, { payload }: { payload: number }) => {
      state.visitedQuestions = [...state.visitedQuestions, payload];
    },
    setAnsweringState: (state, { payload }: { payload: AnsweringState }) => {
      state.answeringState = payload;
    },
    toggleUserAnswer: (state, { payload }: { payload: ToggleUserAnswerPayload }) => {
      if (!state.quiz) return;

      const question = getQuizQuestion({ quiz: state.quiz, quizQuestionId: payload.quizQuestionId });
      const isProvidedAnswerAlreadySelected = question?.answerProvided?.find(
        (answer) => answer.id === payload.answer.id,
      );
      if (isProvidedAnswerAlreadySelected) {
        const isLastQuestionInUserAnswer = question?.answerProvided?.length === 1;
        state.quiz = removeAnswerFromQuestion({
          quiz: state.quiz,
          quizQuestionId: payload.quizQuestionId,
          answer: payload.answer,
        });
        if (isLastQuestionInUserAnswer) state.answeringState = 'answering';
      } else {
        state.quiz = addAnswerForQuestion({
          quiz: state.quiz,
          quizQuestionId: payload.quizQuestionId,
          answer: payload.answer,
        });
      }
    },
    deleteAnswerNotationElement: (state, { payload }: { payload: DeleteAnswerNotationElementPayload }) => {
      const question = getQuizQuestion({ quiz: state.quiz, quizQuestionId: payload.quizQuestionId });
      const elementIndexToDelete = question?.answerProvided?.findIndex((answer) => answer.id === payload.token);

      if (elementIndexToDelete !== -1) {
        state.quiz = removeAnswerFromQuestion({
          quiz: state.quiz,
          quizQuestionId: payload.quizQuestionId,
          answerIndex: elementIndexToDelete,
        });
      }
    },
    setThreadToQuizQuestion: (
      state,
      { payload: { thread, quizQuestionId } }: { payload: SetThreadToQuizQuestionPayload },
    ) => {
      state.quiz = addThreadToQuizQuestion({
        quiz: state.quiz as Quiz,
        relatedQuestionId: quizQuestionId,
        thread,
      });
    },
    setThreadMessageRateToQuizQuestion: (
      state,
      { payload: { quizQuestionId, messageIndex, rate } }: { payload: SetThreadMessageRateToQuizQuestionPayload },
    ) => {
      state.quiz = addThreadMessageRateToQuizQuestion({
        quiz: state.quiz as Quiz,
        messageIndex,
        quizQuestionId,
        rate,
      });
    },
    toggleUserAnswerKey: (state, { payload }: { payload: ToggleUserAnswerKeyPayload }) => {
      const { answerKey, quizQuestionId } = payload;
      // order [<BaseOptions>, <reprimand(s)>, <exclusion(s)>]

      const question = getQuizQuestion({ quiz: state.quiz, quizQuestionId });
      const currentAnswer = { id: answerKey, answer: answerKey };

      // if payload is in <BaseOptions>
      if (AnswerNotationBaseOptionsKeys.includes(answerKey)) {
        // remove option from userAnswer if it is already there
        if (question?.answerProvided?.find((answer) => answer.id === answerKey)) {
          // state.userAnswer = state.userAnswer.filter((answer) => answer.id !== answerKey);
          state.quiz = removeAnswerFromQuestion({
            quiz: state.quiz,
            quizQuestionId,
            answer: currentAnswer,
          });
        } else {
          // replace <BaseOptions> with payload if is there with payload
          const isBaseOptionInUserAnswer = question?.answerProvided?.find((answer) =>
            AnswerNotationBaseOptionsKeys.includes(answer.id as AnswerNotationOptionsKey),
          );
          let tempQuiz;

          if (isBaseOptionInUserAnswer) {
            tempQuiz = removeAnswerFromQuestion({
              quiz: state.quiz,
              quizQuestionId,
              answer: isBaseOptionInUserAnswer,
            });
          }

          state.quiz = addAnswerForQuestion({
            quiz: tempQuiz || state.quiz,
            quizQuestionId,
            answer: currentAnswer,
            onIndex: 0,
          });
        }
      } else {
        // if it's reprimand add it before exclusion(s)
        if (answerKey === AnswerNotationOptionsKey.reprimand) {
          const exclusionIndex = question?.answerProvided?.findIndex(
            (answer) => answer.id === AnswerNotationOptionsKey.exclusion,
          );

          if (exclusionIndex !== -1) {
            state.quiz = addAnswerForQuestion({
              quiz: state.quiz,
              quizQuestionId,
              answer: currentAnswer,
              onIndex: exclusionIndex,
            });
          } else {
            state.quiz = addAnswerForQuestion({
              quiz: state.quiz,
              quizQuestionId,
              answer: currentAnswer,
            });
          }
        } else if (answerKey === AnswerNotationOptionsKey.exclusion) {
          state.quiz = addAnswerForQuestion({
            quiz: state.quiz,
            quizQuestionId,
            answer: currentAnswer,
          });
        }
      }
    },
    setUserAnswer: (state, { payload }: { payload: SetUserAnswerPayload }) => {
      state.quiz = overrideAnswerForQuestion({
        quiz: state.quiz,
        quizQuestionId: payload.quizQuestionId,
        answer: payload.answer,
      });
    },
    setInitialStateOnNewQuiz: (state, { payload }: { payload: SetInitialStateOnNewQuizPayload }) => {
      const { demo, quiz } = payload;
      if (state.quiz?.id !== quiz.id || demo) {
        state.visitedQuestions = initialState.visitedQuestions;
        state.answeringState = initialState.answeringState;
      }
      state.quiz = quiz;
    },
    updateQuestionProvidedAnswer: (state, { payload }: { payload: UpdateQuestionProvidedAnswerPayload }) => {
      const { questionId, isCorrect } = payload;
      const question = state.quiz?.quizQuestions?.find((question) => question.id === questionId);
      if (question) question.correctAnswerProvided = isCorrect;
    },
    changeToQuestion: (state, { payload }: { payload: ChangeToQuestionPayload }) => {
      state.answeringState = payload.nextAnsweringState;
    },
    updateQuestionReportedAt: (state, { payload }: { payload: UpdateQuestionReportedAtPayload }) => {
      const { questionId, reportedAt } = payload;
      const question = state.quiz?.quizQuestions?.find((question) => question.question.id === questionId);
      if (question) question.question.reportedAt = reportedAt.toString();
    },
  },
});

export const {
  addVisitedQuestion,
  setAnsweringState,
  setUserAnswer,
  changeToQuestion,
  setInitialStateOnNewQuiz,
  updateQuestionProvidedAnswer,
  deleteAnswerNotationElement,
  toggleUserAnswerKey,
  toggleUserAnswer,
  updateQuestionReportedAt,
  setThreadToQuizQuestion,
  setThreadMessageRateToQuizQuestion,
} = quizSlice.actions;
