import { useQuery } from '@tanstack/react-query';

import { Quiz } from '@/appTypes';
import { axiosInstance } from '@/services';

export const useGetLastUnfinishedQuiz = () => {
  const getLastUnfinishedQuizRequest = async () => {
    const response = await axiosInstance.get<Quiz | undefined>('/quizzes/last_quiz_unfinished/');

    if (response?.status === 404) return null;

    return response?.data;
  };

  const { data, isLoading } = useQuery({
    queryKey: ['lastUnfinishedQuiz'],
    queryFn: getLastUnfinishedQuizRequest,
    refetchOnMount: true,
  });

  return {
    lastUnfinishedQuiz: data,
    isLoading,
  };
};
