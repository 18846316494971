import { Stack } from '@mui/material';
import { memo } from 'react';

import { SelectableChip } from '@/components/elements';
import { useAppTranslation } from '@/translation';

import { possibleNumberOfQuestions, useCreateQuizFormContext } from '../../hooks';

interface SelectableNumberOfQuestionsProps {
  disabled?: boolean;
}

export const SelectableNumberOfQuestions = memo(({ disabled }: SelectableNumberOfQuestionsProps) => {
  const { t } = useAppTranslation();
  const { watch, setValue, formState } = useCreateQuizFormContext();

  const questionNumber = watch('questionNumber');
  const isSubmitting = formState.isSubmitting;

  const isNumberOfQuestionsSelected = (numberOfQuestions: number) => questionNumber === numberOfQuestions;

  return (
    <Stack direction="row" spacing={2} flexWrap="wrap" rowGap={3} useFlexGap>
      {possibleNumberOfQuestions.map((numberOfQuestions) => {
        const isSelected = isNumberOfQuestionsSelected(numberOfQuestions);

        return (
          <SelectableChip
            key={numberOfQuestions.toString()}
            variant="outlined"
            label={t('common:nQuestions', { count: numberOfQuestions })}
            onClick={() => setValue('questionNumber', numberOfQuestions)}
            isSelected={isSelected}
            disabled={isSubmitting || disabled}
          />
        );
      })}
    </Stack>
  );
});
