import { Quiz } from '@/appTypes';

import { Thread } from '../types';

interface Params {
  quiz: Quiz;
  relatedQuestionId: string | null;
  thread: Thread;
}

export const addThreadToQuizQuestion = ({ quiz, relatedQuestionId, thread }: Params) => {
  if (!relatedQuestionId) return quiz;

  const quizQuestions = [...quiz.quizQuestions];
  const quizQuestionIndex = quizQuestions.findIndex((quizQuestion) => quizQuestion.id === relatedQuestionId);

  if (quizQuestionIndex === -1) return quiz;

  quizQuestions[quizQuestionIndex] = {
    ...quizQuestions[quizQuestionIndex],
    chatThreads: [thread],
  };

  return {
    ...quiz,
    quizQuestions,
  };
};
