import { MessageSender } from '../../types';

import { AssistantMessage } from './AssistantMessage';
import { UserMessage } from './UserMessage';

interface Props {
  author: MessageSender;
  message: string | React.ReactNode;
  showRating?: boolean;
  rate?: number;
  onRateClick?: (rate: number) => void;
  isRatingLoading?: boolean;
  hideAvatar?: boolean;
  assistantIconPulse?: boolean;
}

export const ChatMessage = ({
  author,
  message,
  showRating,
  onRateClick,
  rate,
  isRatingLoading,
  hideAvatar,
  assistantIconPulse,
}: Props) => {
  if (author === MessageSender.user) {
    return <UserMessage message={message} />;
  } else {
    return (
      <AssistantMessage
        message={message}
        showRating={showRating}
        onRateClick={onRateClick}
        rate={rate}
        isRatingLoading={isRatingLoading}
        hideAvatar={hideAvatar}
        assistantIconPulse={assistantIconPulse}
      />
    );
  }
};
