import { Helmet, HelmetProps } from 'react-helmet';

import { TranslationKeys, useAppTranslation } from '@/translation';

interface MetaProps extends Omit<HelmetProps, 'title'> {
  titleKey?: TranslationKeys;
}

export const Meta = ({ titleKey }: MetaProps) => {
  const { t } = useAppTranslation();

  return <Helmet title={titleKey ? t(titleKey) : undefined} />;
};
