import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Quiz } from '@/appTypes';
import { getQuizQueryKey } from '@/features/Quiz/api';
import { setThreadToQuizQuestion } from '@/features/Quiz/store';
import { useRefreshCurrentUserData } from '@/features/User';
import { useAppDispatch, useGetCurrentPageName, useNotify } from '@/hooks';
import { AssistantGtmEvent, axiosInstance, trackAssistentEvent, trackEvent } from '@/services';
import { useAppTranslation } from '@/translation';

import { Thread } from '../types';
import { addThreadToQuizQuestion } from '../utils';

import { getThreadQueryKey } from './useGetThread';

export interface ContinueThreadPayload {
  question: string;
}

const endpoint = '/chat/:id/';

interface Params {
  quizId?: string;
  quizQuestionId?: string;
  threadId: string | undefined;
}

export const useContinueThread = ({ threadId, quizQuestionId, quizId }: Params) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const { refreshCurrentUserData } = useRefreshCurrentUserData();
  const { notify } = useNotify();
  const { t } = useAppTranslation();
  const pageName = useGetCurrentPageName();

  const continueThreadRequest = async (payload: ContinueThreadPayload) => {
    if (!threadId) throw new Error('Thread id is required');

    const response = await axiosInstance.put<Thread | undefined>(endpoint.replace(':id', threadId), payload);

    return response?.data;
  };

  const {
    mutateAsync,
    isPending: isLoading,
    isIdle,
  } = useMutation({
    mutationFn: continueThreadRequest,
    onSuccess: (thread) => {
      if (!thread) return;

      trackAssistentEvent(AssistantGtmEvent.continueThread, { withQuizQuestion: !!quizQuestionId, pageName });
      trackEvent.assistant.continueThread(!!quizQuestionId, pageName || 'null');
      queryClient.setQueryData<Thread>([getThreadQueryKey, thread.id], thread);

      if (quizId) {
        if (quizQuestionId) dispatch(setThreadToQuizQuestion({ quizQuestionId, thread }));

        queryClient.setQueryData<Quiz>(getQuizQueryKey(quizId), (quiz) => {
          if (!quiz) return;

          return addThreadToQuizQuestion({ quiz, relatedQuestionId: quizQuestionId || null, thread });
        });
      }
    },
    onError: () => {
      refreshCurrentUserData();
      notify('error', t('common:sorrySomethingWentWrong'));
    },
  });

  return {
    continueThread: mutateAsync,
    isLoading,
    isIdle,
  };
};
