import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError } from 'axios';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { useNotify } from '@/hooks';
import { captureError } from '@/services';
import { useAppTranslation } from '@/translation';

import { recoverPassword } from '../api';

const formSchema = z.object({
  email: z.string().email(),
});

type RecoverPasswordFormValues = z.infer<typeof formSchema>;

interface UseRecoverPasswordFormParams {
  onSuccess: (email: string) => void;
}

export const useRecoverPasswordForm = ({ onSuccess }: UseRecoverPasswordFormParams) => {
  const { notify } = useNotify();
  const { t } = useAppTranslation();

  const form = useForm<RecoverPasswordFormValues>({
    resolver: zodResolver(formSchema),
  });
  const { handleSubmit, formState } = form;

  const onSubmit: SubmitHandler<RecoverPasswordFormValues> = async (data) => {
    try {
      const { ...payload } = data;
      const result = await recoverPassword(payload);

      if (result) onSuccess(payload.email);
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        notify('error', t('common:errorOccurredTryAgain'));
        captureError(err);
      }
    }
  };

  const onError: SubmitErrorHandler<RecoverPasswordFormValues> = () => {};

  const onSubmitPress = handleSubmit(onSubmit, onError);

  const isSubmitting = formState.isSubmitting;

  return {
    form,
    onSubmitPress,
    isSubmitting,
  };
};
