import { CustomThemeOptions } from '@mui/material';
import { LinkProps } from '@mui/material/Link';

import { LinkBehavior } from './LinkBehavior';
import { typographyVariants } from './typographyThemeHandler';

const getButtonPadding = (size: 'small' | 'medium' | 'large' | undefined) => {
  switch (size) {
    case 'small':
      return '8px 14px';
    default:
    case 'medium':
      return '10px 16px';
    case 'large':
      return '12px 18px';
  }
};

export const muiComponentOverwrite = (palette: CustomThemeOptions['palette']): CustomThemeOptions['components'] => ({
  MuiLink: {
    defaultProps: {
      component: LinkBehavior,
    } as LinkProps,
  },
  MuiButtonBase: {
    defaultProps: {
      LinkComponent: LinkBehavior,
    },
  },
  MuiPaper: {
    defaultProps: {
      elevation: 3,
    },
    styleOverrides: {
      root: ({ theme, ownerState }) => {
        return [
          {
            borderRadius: 16,
            backgroundColor: palette.app.surfaceContainerLowest,
          },
          theme.unstable_sx({
            p: [4, 6],
            // pb: [4, 6, 8],
            py: [3, 4, 5],
            ...ownerState.sx,
          }),
        ];
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: ({ ownerState }) => ({
        borderRadius: 100,
        padding: getButtonPadding(ownerState.size),
        textTransform: 'none',
        ...(ownerState.variant !== 'text' && {
          whiteSpace: 'nowrap',
        }),
        ...typographyVariants.labelLarge,
        ...(ownerState.variant === 'contained' &&
          !ownerState.disabled && {
            color: palette.app.onPrimary,
            '@media (hover: hover)': {
              '&:hover': {
                opacity: 0.92,
                backgroundColor: palette.app.primary,
              },
            },
            '@media (hover: none)': {
              '&:hover': {},
            },
            '&:active': {
              opacity: 0.88,
              backgroundColor: palette.app.primary,
            },
          }),
      }),
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: palette.app.outline,
          },
          ...(!ownerState.disabled && {
            '@media (hover: hover)': {
              '&:hover fieldset': {
                borderColor: palette.app.onSurface,
              },
            },
            '&.Mui-focused fieldset': {
              borderColor: palette.app.primary,
              borderWidth: '2px',
            },
          }),
        },
      }),
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: 8,
        userSelect: 'none',
        '& .MuiChip-label': {
          ...typographyVariants.labelLarge,
        },
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        padding: '0 !important',
      },
    },
  },

  MuiDivider: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        backgroundColor: ownerState.light ? palette.app.outlineVariant : palette.app.outline,
      }),
    },
  },
  MuiSkeleton: {
    styleOverrides: {
      root: {
        transform: 'unset',
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      message: {
        width: '100%',
      },
    },
  },
});
