import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import { IconButton, Stack } from '@mui/material';
import type { DefaultComponentProps } from '@mui/material/OverridableComponent';
import { alpha, useTheme } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';

import { Text } from '@/components/elements';

interface Props {
  message: string | React.ReactNode;
  showRating?: boolean;
  rate?: number;
  onRateClick?: (rate: number) => void;
  isRatingLoading?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const defaultIconsProps: DefaultComponentProps<any> = { fontSize: 'small', sx: { transform: 'scaleX(-1)' } };

export const MessageContentBox = ({ message, showRating, rate, onRateClick, isRatingLoading }: Props) => {
  const { palette } = useTheme();

  const bgColor =
    palette.mode === 'dark' ? alpha(palette.secondarySpectrum[20], 0.7) : alpha(palette.secondarySpectrum[90], 0.7);

  const [contentHeight, setContentHeight] = useState<number | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (ref.current) setContentHeight(ref.current.clientHeight);
  });

  const fullWidth = contentHeight && contentHeight > 40;

  return (
    <Stack
      spacing={1}
      px={3}
      py={2}
      pb={showRating ? 1 : 2}
      borderRadius={4}
      bgcolor={bgColor}
      width={fullWidth ? '100%' : 'auto'}
      ref={ref}
      justifyContent="flex-end"
      minHeight={36}
    >
      <Text variant="bodyMedium" sx={{ whiteSpace: 'pre-line', wordBreak: 'break-word' }} color="onSurface" selectable>
        {message}
      </Text>

      {showRating && (
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
          <Text variant="labelSmall" color="onSurfaceVariant" translationKey="chat:rateAnswer" />
          <Stack direction="row" spacing={0} alignItems="center">
            {rate === 1 ? (
              <IconButton
                onClick={() => onRateClick?.(0)}
                size="small"
                disabled={isRatingLoading}
                data-test="assistant-message-rate-good-checked"
              >
                <ThumbUpIcon color="primary" {...defaultIconsProps} />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => onRateClick?.(1)}
                size="small"
                disabled={isRatingLoading}
                data-test="assistant-message-rate-good-unchecked"
              >
                <ThumbUpOutlinedIcon {...defaultIconsProps} />
              </IconButton>
            )}
            {rate === -1 ? (
              <IconButton
                onClick={() => onRateClick?.(0)}
                size="small"
                disabled={isRatingLoading}
                data-test="assistant-message-rate-bad-checked"
              >
                <ThumbDownIcon color="error" {...defaultIconsProps} />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => onRateClick?.(-1)}
                size="small"
                disabled={isRatingLoading}
                data-test="assistant-message-rate-bad-unchecked"
              >
                <ThumbDownOutlinedIcon {...defaultIconsProps} />
              </IconButton>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
