import { CircularProgress, LinearProgress } from '@mui/material';
import { Row, flexRender } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';

import { NoRowsInfo } from './NoRowsInfo';
import { Td, Tr } from './styled';

interface TableBodyProps<T> {
  rows: Row<T>[];
  hasNextPage?: boolean;
  totalCount: number;
  tableContainerRef: HTMLDivElement | null;
  noRowsMessage: string;
  isLoading: boolean;
  onRowClick?: (item: T) => void;
  numberOfColumns: number;
  rowTestId?: string;
  rowEsitmatedHeight: number;
}

export interface ItemWithId {
  id: string;
}

export const TableBody = <T extends ItemWithId>({
  rows,
  hasNextPage,
  totalCount,
  tableContainerRef,
  noRowsMessage,
  isLoading,
  onRowClick,
  numberOfColumns,
  rowTestId,
  rowEsitmatedHeight,
}: TableBodyProps<T>) => {
  const rowVirtualizer = useVirtualizer({
    count: totalCount,
    getScrollElement: () => tableContainerRef,
    estimateSize: () => rowEsitmatedHeight,
    // measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  const virtualRows = rowVirtualizer.getVirtualItems();

  const showLoaderRow = hasNextPage && isLoading;

  return (
    <tbody
      style={{
        display: 'grid',
        height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
        position: 'relative', //needed for absolute positioning of rows
      }}
    >
      {isLoading && (
        <tr>
          <th colSpan={numberOfColumns}>
            <LinearProgress />
          </th>
        </tr>
      )}

      {virtualRows.map((virtualRow) => {
        const row = rows[virtualRow.index];

        return (
          <Tr
            data-test={rowTestId}
            data-index={virtualRow.index} // needed for dynamic row height measurement
            ref={(node) => rowVirtualizer.measureElement(node)} // measure dynamic row height
            key={`${row?.index}-${virtualRow?.index}`}
            onClick={() => onRowClick?.(row.original)}
            sx={{
              transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
            }}
          >
            {row?.getVisibleCells().map((cell) => {
              return (
                <Td
                  key={cell.id}
                  style={{
                    minWidth: cell.column.columnDef.minSize || 'unset',
                    width: cell.column.getSize() === Number.MAX_SAFE_INTEGER ? '100%' : cell.column.getSize(),
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    textAlign: (cell.column.columnDef.meta as any)?.textAlign || 'left',
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              );
            })}
          </Tr>
        );
      })}

      {!isLoading && rows.length === 0 && <NoRowsInfo message={noRowsMessage} />}
      {(isLoading || showLoaderRow) && (
        <Tr
          key="loader"
          sx={{
            transform: `translateY(${rowVirtualizer.getTotalSize()}px)`,
            justifyContent: 'center',
          }}
        >
          <Td colSpan={numberOfColumns} style={{ textAlign: 'center', width: '100%' }}>
            <div style={{ textAlign: 'center' }}>
              <CircularProgress />
            </div>
          </Td>
        </Tr>
      )}
    </tbody>
  );
};
