import { Button, Grid } from '@mui/material';

import { Text } from '@/components/elements';
import { useAppTranslation } from '@/translation';

import { MessageSender } from '../../types';
import { ChatMessage } from '../ChatMessage';

interface Props {
  onSelectQuestion: (questionNumber: number) => void;
}

export const HowShouldUserAskSelectExampleQuestion = ({ onSelectQuestion }: Props) => {
  const { t } = useAppTranslation();

  return (
    <>
      <ChatMessage message={t('chat:howToAskQuestionInstructionMessage')} author={MessageSender.assistant} hideAvatar />
      <Grid container columnSpacing={[2, 4]} pt={4}>
        <Grid item xs={12} sm={6} display="flex" flexDirection="column" justifyContent="space-between">
          <Text
            variant="bodySmall"
            translationKey="chat:howToAskQuestionInstructionExampleQuestion1"
            color="onSurfaceVariant"
            textAlign="center"
          />
          <Button variant="text" size="small" onClick={() => onSelectQuestion(1)}>
            {t('chat:checkAnswer')}
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} display="flex" flexDirection="column" justifyContent="space-between">
          <Text
            variant="bodySmall"
            translationKey="chat:howToAskQuestionInstructionExampleQuestion2"
            color="onSurfaceVariant"
            textAlign="center"
          />
          <Button variant="text" size="small" onClick={() => onSelectQuestion(2)}>
            {t('chat:checkAnswer')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
