import { Box, Button, Stack } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import { Text } from '@/components/elements';
import { Meta } from '@/components/layout';
import { Pages } from '@/navigation';
import { useAppTranslation } from '@/translation';

import { LoginPageTemplate } from '../../components';

export const RegistrationSuccessPage = () => {
  const { t } = useAppTranslation();
  const [searchParams] = useSearchParams();

  const userEmail = searchParams.get('email');

  return (
    <LoginPageTemplate headerMessageKey="user:confirmYourRegistration">
      <Meta titleKey="user:signUp" />
      <Stack spacing={8}>
        <Box>
          <Text variant="bodyMedium">{t('user:registrationSuccessMessagePart1')}</Text>
          <Text variant="bodyMedium" fontWeight={700}>
            {userEmail}
          </Text>
          <Text variant="bodyMedium">{t('user:registrationSuccessMessagePart2')}</Text>
        </Box>
        <Text variant="bodyMedium">{t('user:registrationSuccessMessagePart3')}</Text>

        <Stack spacing={8} alignItems="center">
          <Button href={Pages.login} variant="contained">
            {t('user:signIn')}
          </Button>
          <Button href={Pages.landing} variant="outlined">
            {t('user:goToHomePage')}
          </Button>
        </Stack>
      </Stack>
    </LoginPageTemplate>
  );
};
