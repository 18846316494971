import { axiosInstance } from '@/services';

interface ChangePasswordPayload {
  userId: string;
  newPassword: string;
}

export const changePassword = async ({ userId, newPassword }: ChangePasswordPayload): Promise<boolean> => {
  const response = await axiosInstance.patch(`/users/${userId}/`, {
    password: newPassword,
  });

  return response.status === 200;
};
