import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Stack, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Text } from '@/components/elements';
import { useAppTranslation } from '@/translation';

export const BetaBadge = () => {
  const { palette } = useTheme();
  const { t } = useAppTranslation();

  return (
    <Tooltip title={t('chat:betaTooltip')}>
      <Stack
        alignItems="center"
        spacing={1}
        direction="row"
        sx={{
          bgcolor: palette.app.tertiaryContainer,
          borderRadius: 4,
          px: 2,
          py: 0.5,
          color: palette.app.onTertiaryContainer,
        }}
      >
        <Text variant="bodyMedium" translationKey="common:beta" />
        <InfoOutlinedIcon sx={{ color: palette.app.onTertiaryContainer, fontSize: 14 }} />
      </Stack>
    </Tooltip>
  );
};
