import { createSlice } from '@reduxjs/toolkit';

import { AppThemeGtmEvent, trackEvent, trackGTMEvent } from '@/services';

interface ThemeState {
  mode: 'light' | 'dark' | 'system';
}

const initialState: ThemeState = {
  mode: 'system',
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setThemeMode: (state, action) => {
      state.mode = action.payload;
    },
    toggleThemeMode: (state) => {
      const newValue = state.mode === 'light' ? 'dark' : 'light';
      trackGTMEvent(AppThemeGtmEvent.change, { themeMode: newValue });
      trackEvent.user.changeTheme(newValue);
      state.mode = newValue;
    },
  },
});

export const { toggleThemeMode, setThemeMode } = themeSlice.actions;
