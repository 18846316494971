import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, IconButton, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';

import { PassRatePercentages, Quiz, QuizMode } from '@/appTypes';
import { Cell, Table, Text } from '@/components/elements';
import { useFeatureAvailable } from '@/features/User';
import { useAppNavigate } from '@/hooks';
import { Pages } from '@/navigation';
import { useAppTranslation } from '@/translation';

import { useGetQuizzes } from '../../api';
import { useFormatRelatedArticlesToGroups } from '../../hooks';
import { modeTranslationKey } from '../../utils';
import { NotFinishedQuizChip } from '../NotFinishedQuizChip';

import { HistoryPlanLimitAlert } from './HistoryPlanLimitAlert';

export const HistoryTableWeb = () => {
  const { t } = useAppTranslation();
  const { navigate } = useAppNavigate();
  const { palette } = useTheme();
  const { formatRelatedArticlesToGroups } = useFormatRelatedArticlesToGroups();

  const { quizHistory, quizHistoryUpTo10 } = useFeatureAvailable();

  const openQuiz = useCallback((quizId: string) => navigate(Pages.quizSummary, { id: quizId }), [navigate]);

  const { quizzes, totalCount, isLoading, isFetching, hasNextPage, fetchNextPage, isUserAllowedToFetchQuizzes } =
    useGetQuizzes();

  const columnHelper = createColumnHelper<Quiz>();

  const columns = useMemo<ColumnDef<Quiz>[]>(
    () => [
      columnHelper.display({
        id: 'completeDate',
        header: t('common:date'),
        size: 100,
        cell: ({ row }) => (
          <Cell textAlign="center" width={100} sx={{ whiteSpace: 'pre-line' }}>
            {dayjs(row.original.createdAt).format('HH:mm\nDD.MM.YYYY')}
          </Cell>
        ),
      }),
      columnHelper.display({
        id: 'relatedArticles',
        header: t('common:articles'),
        minSize: 250,
        cell: ({ row }) => {
          if (row.original.mode === QuizMode.reviewMistakes) return <Cell minWidth={250}>{'- - -'}</Cell>;

          return <Cell minWidth={250}>{formatRelatedArticlesToGroups(row.original.relatedArticles)}</Cell>;
        },
      }),
      columnHelper.display({
        id: 'mode',
        header: t('common:mode'),
        size: 125,
        cell: ({ row }) => (
          <Cell textAlign="center" width={125}>
            {t(modeTranslationKey[row.original.mode])}
          </Cell>
        ),
      }),
      columnHelper.display({
        id: 'score',
        header: t('common:score'),
        size: 125,
        cell: ({ row }) => {
          const isReviewMistakesMode = row.original.mode === QuizMode.reviewMistakes;
          const isQuizFinished = !!row.original.completeDate;

          if (!isQuizFinished) {
            return (
              <Cell width={125}>
                <NotFinishedQuizChip />
              </Cell>
            );
          }

          const percentage = Math.round(
            ((row.original.positiveAnswersCount || 0) / (row.original.questionsAmount || 0)) * 100,
          );

          const color = percentage >= PassRatePercentages ? palette.app.positive : palette.app.error;

          return (
            <Cell width={125}>
              <Stack flex={1} spacing={6} direction="row" justifyContent="center">
                <Text
                  variant="bodyMedium"
                  noWrap
                >{`${row.original.positiveAnswersCount} / ${row.original.questionsAmount}`}</Text>
                {!isReviewMistakesMode && <Text variant="bodyMedium" customColor={color}>{`${percentage}%`}</Text>}
              </Stack>
            </Cell>
          );
        },
      }),
      columnHelper.display({
        id: 'actions',
        size: 50,
        cell: ({ row }) => (
          <Cell>
            <Box display="flex" flex={1} justifyContent="flex-end">
              <IconButton onClick={() => openQuiz(row.original.id)}>
                <ChevronRightIcon color="primary" />
              </IconButton>
            </Box>
          </Cell>
        ),
      }),
    ],
    [columnHelper, formatRelatedArticlesToGroups, openQuiz, palette.app.error, palette.app.positive, t],
  );

  const overrideBody = useMemo(() => {
    if (quizHistory) return undefined;

    return <HistoryPlanLimitAlert />;
  }, [quizHistory]);

  return (
    <Box>
      <Table
        columns={columns}
        totalCount={totalCount || 0}
        data={quizzes}
        isLoading={isUserAllowedToFetchQuizzes && isLoading}
        isFetching={isFetching}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        noRowsMessage={t('quiz:youDidNotFinishAnyQuizzesYet')}
        onRowClick={(row) => openQuiz(row.id)}
        footer={overrideBody}
        hideBody={!quizHistory && !quizHistoryUpTo10}
        rowTestId="historyTable-row"
        rowEsitmatedHeight={65}
      />
    </Box>
  );
};
