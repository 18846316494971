import { useMutation } from '@tanstack/react-query';

import { Quiz, QuizMode } from '@/appTypes';
import { axiosInstance } from '@/services';

interface CreateQuizPayload {
  questionsAmount: number;
  relatedArticles: number[];
  mode: QuizMode;
}

export const useCreateQuizApi = () => {
  const createQuizRequest = async (payload: CreateQuizPayload) => {
    const response = await axiosInstance.post<Quiz | undefined>('/quizzes/create_quiz/', payload);

    return response?.data;
  };

  const { mutateAsync, isPending: isLoading } = useMutation({
    mutationFn: createQuizRequest,
  });

  return {
    createQuiz: mutateAsync,
    isLoading,
  };
};
