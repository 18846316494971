import { Button, ButtonProps, alpha, styled } from '@mui/material';

interface BadgeBoxProps extends ButtonProps {
  bgColor: string;
}

export const BadgeBox = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'bgColor',
})<BadgeBoxProps>(({ theme, bgColor }) => ({
  color: theme.palette.app.primary,
  borderColor: theme.palette.app.outline,
  backgroundColor: bgColor,
  borderRadius: 8,
  padding: '2px 8px 2px 8px',
  textTransform: 'none',
  maxWidth: 160,
  width: 'fit-content',
  textAlign: 'center',
  '&:hover': {
    backgroundColor: alpha(bgColor, 0.9),
  },
}));
