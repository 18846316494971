import { createSlice } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';

import { User } from '@/appTypes/User';

interface Session {
  accessToken: string;
  refreshToken: string;
  accessTokenExpiresAt: number;
  refreshTokenExpiresAt: number;
}

interface UserState {
  currentUser: User | null;
  session: Session | null;
}

const initialState: UserState = {
  currentUser: null,
  session: null,
};

interface SetSessionAction {
  accessToken: string;
  refreshToken: string;
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSession: (state, { payload }: { payload: SetSessionAction | null }) => {
      if (!payload) {
        state.session = null;
        return;
      }

      const { accessToken, refreshToken } = payload;
      const decodedAccessToken = jwtDecode(accessToken);
      const decodedRefreshToken = jwtDecode(refreshToken);
      const accessTokenExpiresAt = decodedAccessToken.exp || 0;
      const refreshTokenExpiresAt = decodedRefreshToken.exp || 0;

      state.session = { accessToken, refreshToken, accessTokenExpiresAt, refreshTokenExpiresAt };
    },
    setCurrentUserData: (state, { payload }: { payload: UserState['currentUser'] }) => {
      state.currentUser = payload;
    },
    logoutUser: (state) => {
      state.currentUser = null;
      state.session = null;
    },
    decrementAvailableThreads: (state) => {
      if (state.currentUser?.subInfo?.chat?.availableThreads) state.currentUser.subInfo.chat.availableThreads -= 1;
    },
  },
});

export const { logoutUser, setSession, setCurrentUserData, decrementAvailableThreads } = userSlice.actions;
