import { Capacitor } from '@capacitor/core';
import { useMatch } from 'react-router-dom';

import { Pages } from '@/navigation';

import { CookiesConsent } from '../components/CookiesConsent';

interface CookiesServicesProviderProps {
  children: React.ReactNode;
}

export const CookiesServicesProvider = ({ children }: CookiesServicesProviderProps) => {
  const hideConsent = useMatch(Pages.demo) || Capacitor.isNativePlatform();

  return (
    <div>
      {!hideConsent && <CookiesConsent />}
      {children}
    </div>
  );
};
