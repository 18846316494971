import { Box, Button, Stack } from '@mui/material';

import { InTextButton, Text } from '@/components/elements';
import { Pages, useGetPagePathWithLang } from '@/navigation';
import { useAppTranslation } from '@/translation';

import { acceptCookiesConsent } from '../utils/cookiesConsent';

interface CookiesBasicsProps {
  showSettings: () => void;
  onClose: () => void;
}

export const CookiesBasics = ({ showSettings, onClose }: CookiesBasicsProps) => {
  const { t } = useAppTranslation();
  const cookiesPolicyPath = useGetPagePathWithLang(Pages.cookiesPolicy);
  const privacyPolicyPath = useGetPagePathWithLang(Pages.privacyPolicy);

  const acceptAllCookies = () => {
    acceptCookiesConsent({
      analytics: true,
      advert: true,
    });
    onClose();
  };

  return (
    <Stack spacing={4}>
      <Text variant="bodyMedium" translationKey="common:cookiesConsentDescription" />
      <Box lineHeight={1}>
        <Text variant="bodyMedium" translationKey="common:cookiesBasicsPart1" />{' '}
        <Text variant="bodyMedium" translationKey="common:cookiesFilesSettings" fontStyle="italic" />{' '}
        <Text variant="bodyMedium" translationKey="common:cookiesBasicsPart3" />
      </Box>
      <Box lineHeight={1}>
        <Text variant="bodyMedium" translationKey="common:cookiesBasicsPart4" />
        <InTextButton href={cookiesPolicyPath}>{t('common:cookiesBasicsPart5')}</InTextButton>
        <Text variant="bodyMedium" translationKey="common:cookiesBasicsPart6" />
        <InTextButton href={privacyPolicyPath}>{t('common:cookiesBasicsPart7')}</InTextButton>
      </Box>

      <Stack spacing={[1, 4]} direction="row">
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={acceptAllCookies}
          data-test="accept-all-cookies"
        >
          {t('common:iAgree')}
        </Button>
        <Button size="small" variant="text" onClick={showSettings}>
          {t('common:adjustSettings')}
        </Button>
      </Stack>
    </Stack>
  );
};
