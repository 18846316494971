interface MetaPixelParams {
  name: string;
  value: string;
}

export const metaPixel = ({ name, value }: MetaPixelParams) => {
  if (!window.fbq) return;

  window.fbq(name, value);
};
