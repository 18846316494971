import { styled, Box, alpha } from '@mui/material';

import { AnsweringState } from '../../store';

interface NotationQuestionInputFieldContainerProps {
  answeringState: AnsweringState;
}

export const NotationQuestionInputFieldContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'answeringState',
})<NotationQuestionInputFieldContainerProps>(({ theme, answeringState }) => {
  const backgroundColor = {
    answering: theme.palette.app.surfaceContainerLowest,
    checkingAnswer: theme.palette.app.surfaceContainer,
    answered: theme.palette.app.surfaceContainerLowest,
    correct: theme.palette.app.positiveContainer50,
    inCorrect: alpha(theme.palette.app.errorContainer, 0.5),
  }[answeringState];

  const borderColor = {
    answering: theme.palette.app.outline,
    checkingAnswer: theme.palette.app.outline,
    answered: theme.palette.app.outline,
    correct: theme.palette.app.positive,
    inCorrect: theme.palette.app.error,
  }[answeringState];

  return {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    padding: '16px 14px',
    borderRadius: 4,
    border: `1px solid ${borderColor}`,
    height: 'fit-content',
    backgroundColor,
  };
});

export const NotationQuestionInputFieldLabelContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  top: -10,
  left: 10,
  backgroundColor: theme.palette.app.background,
  padding: '0 4px',
}));
