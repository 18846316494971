import { Capacitor } from '@capacitor/core';
import Cookies from 'js-cookie';

import { isDev } from '@/config/env';
import { CookiesKeys } from '@/constants/cookiesKeys';
import { gtagEvent, trackEvent } from '@/services';
import { metaPixel } from '@/services/metaPixel';

export interface CookiesTypes {
  analytics: boolean;
  advert: boolean;
}

export interface CookiesConsent extends CookiesTypes {
  version: number;
  acceptedAt: Date;
}

const CurrentVersion = 3;

const setCookiesConsent = (cookieConsent: CookiesConsent) => {
  Cookies.set(CookiesKeys.cookiesConsent, JSON.stringify(cookieConsent), {
    domain: isDev ? undefined : 'refereewise.com',
    expires: 365,
  });
};

export const getCookiesConsent = (): CookiesConsent | null => {
  const cookiesConsent = Cookies.get(CookiesKeys.cookiesConsent);

  if (!cookiesConsent) return null;

  return JSON.parse(cookiesConsent);
};

export const updateServicesBasedOnConsent = () => {
  if (Capacitor.isNativePlatform()) {
    updateAnalyticsConsent(true);
    updateAdvertConsent(true);
    return;
  }

  const cookiesConsent = getCookiesConsent();

  updateAnalyticsConsent(!!cookiesConsent?.analytics);
  updateAdvertConsent(!!cookiesConsent?.advert);
};

const updateAnalyticsConsent = (granted: boolean, reloadOnDeny = false) => {
  const isDemoPage = window.location.pathname.includes('/demo');
  if (isDemoPage) return;

  const grantedValue = granted ? 'granted' : 'denied';
  gtagEvent({
    event: 'consent',
    name: 'update',
    data: {
      analytics_storage: grantedValue,
      ad_storage: grantedValue,
    },
  });

  if (granted) trackEvent.app.setCookieConsentGiven();
  else if (reloadOnDeny) window.location.reload();
};

const updateAdvertConsent = (granted: boolean) => {
  const grantedValue: string = granted ? 'grant' : 'revoke';
  metaPixel({
    name: 'consent',
    value: grantedValue,
  });
};

export const acceptCookiesConsent = (cookies: CookiesTypes) => {
  const previousCookiesConsent = getCookiesConsent();
  const cookiesConsent: CookiesConsent = {
    ...cookies,
    version: CurrentVersion,
    acceptedAt: new Date(),
  };

  setCookiesConsent(cookiesConsent);

  if (previousCookiesConsent?.analytics !== cookiesConsent.analytics)
    updateAnalyticsConsent(cookiesConsent.analytics, true);
  if (previousCookiesConsent?.advert !== cookiesConsent.advert) updateAdvertConsent(cookiesConsent.advert);
};

const isCurrentVersion = (cookiesConsent: CookiesConsent): boolean => {
  return cookiesConsent.version >= CurrentVersion;
};

export const shouldShowCookiesConsent = (): boolean => {
  const cookiesConsent = getCookiesConsent();

  if (!cookiesConsent) return true;

  return !isCurrentVersion(cookiesConsent);
};
