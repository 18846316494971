import { useUpdateAnalyticsUserContext } from '@/services';

import { useUpdateSentryContextAboutUser } from '../../hooks';

interface SessionManagerProps {
  children: React.ReactNode;
}

export const SessionManager = ({ children }: SessionManagerProps) => {
  useUpdateSentryContextAboutUser();
  useUpdateAnalyticsUserContext();

  return <>{children}</>;
};
