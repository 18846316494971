import { Transition, motion } from 'framer-motion';

const loadingContainer = (size: number) => ({
  width: size * 4.5,
  height: size * 2,
  display: 'flex',
  justifyContent: 'space-around',
});

const loadingCircle = (size: number) => ({
  display: 'block',
  width: size,
  height: size,
  borderRadius: '50%',
});

const loadingContainerVariants = {
  start: {
    transition: {
      staggerChildren: 0.3,
    },
  },
  end: {
    transition: {
      staggerChildren: 0.3,
    },
  },
};

const loadingCircleVariants = {
  start: {
    y: '50%',
    opacity: 0.3,
  },
  end: {
    y: '0%',
    opacity: 1,
  },
};

const loadingCircleTransition: Transition = {
  duration: 0.8,
  repeat: Infinity,
  repeatType: 'reverse',
  ease: 'easeInOut',
};

interface Props {
  color: string;
  size?: number;
}

export const TypingLoader = ({ color, size = 8 }: Props) => {
  return (
    <motion.div style={loadingContainer(size)} variants={loadingContainerVariants} initial="start" animate="end">
      <motion.span
        style={{ ...loadingCircle(size), backgroundColor: color }}
        variants={loadingCircleVariants}
        transition={loadingCircleTransition}
      />
      <motion.span
        style={{ ...loadingCircle(size), backgroundColor: color }}
        variants={loadingCircleVariants}
        transition={loadingCircleTransition}
      />
      <motion.span
        style={{ ...loadingCircle(size), backgroundColor: color }}
        variants={loadingCircleVariants}
        transition={loadingCircleTransition}
      />
    </motion.div>
  );
};
