import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { Stack } from '@mui/material';
import { useEffect } from 'react';

import { Page } from '@/components/layout';
import { useRefreshCurrentUserData } from '@/features/User';

import {
  ResumeQuiz,
  QuizCreator,
  RenewPlanBox,
  ReviewMistakesBox,
  ExpiringPlanBox,
  AssistantChatBox,
} from '../../components';
import { useShowPlanBoxes } from '../../hooks/useShowPlanBoxes';

export const HomePage = () => {
  const { refreshCurrentUserData } = useRefreshCurrentUserData();
  const { showExpiringPlanBox, showRenewPlanBox } = useShowPlanBoxes();

  useEffect(() => {
    SplashScreen.hide();
    refreshCurrentUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  CapacitorApp.addListener('backButton', () => {
    CapacitorApp.exitApp();
  });

  return (
    <Page disableHeaderLink={Capacitor.isNativePlatform()}>
      <Stack spacing={10}>
        {/* Plan sections */}
        {showRenewPlanBox && showExpiringPlanBox && (
          <Stack spacing={4}>
            <RenewPlanBox />
            <ExpiringPlanBox />
          </Stack>
        )}

        <AssistantChatBox />

        {/* Quiz sections */}
        <Stack spacing={4}>
          <ResumeQuiz />
          <ReviewMistakesBox />
          <QuizCreator />
        </Stack>
      </Stack>
    </Page>
  );
};
