import { PlanLimitAlert, Text } from '@/components/elements';
import { useAppTranslation } from '@/translation';

export const HistoryPlanLimitAlert = () => {
  const { t } = useAppTranslation();

  return (
    <PlanLimitAlert buttonLabel={t('common:upgradePlan')}>
      <Text variant="bodyMedium" translationKey="quiz:youHaveAccessOnlyToLast10Quizzes" />
    </PlanLimitAlert>
  );
};
