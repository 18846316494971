import { Quiz, QuizQuestion } from '@/appTypes';

import { GetQuizResponse } from '../api';

// TEMP: [BE] Match userAnswers: string[] to Answer[]
export const adjustQuizUserAnswerForFrontend = (quizFromBe: GetQuizResponse): Quiz | undefined => {
  if (!quizFromBe) return undefined;

  return {
    ...quizFromBe,
    quizQuestions: quizFromBe.quizQuestions.map(adjustQuizQuestionAnswerForFrontend) || [],
  };
};

export const adjustQuizQuestionAnswerForFrontend = (quizQuestion: QuizQuestion) => ({
  ...quizQuestion,
  answerProvided:
    (quizQuestion.answerProvided &&
      quizQuestion.answerProvided?.map((answerProvided) => {
        const matchedAnswer = quizQuestion.question.possibleAnswers.find(
          (possibleAnswer) => possibleAnswer.answer === (answerProvided as unknown as string),
        );

        return {
          id: matchedAnswer?.id || (answerProvided as unknown as string),
          answer: answerProvided as unknown as string,
        };
      })) ||
    null,
});
