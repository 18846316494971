import { Box, CircularProgress } from '@mui/material';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useRef } from 'react';

import { useFetchNextPageOnEnd } from '@/hooks/useFetchNextPageOnEnd';

import { Loader } from '../Loader';

export interface ListProps<T> {
  data: T[];
  hasNextPage?: boolean;
  isLoading: boolean;
  isFetchingNextPage: boolean;
  totalCount: number;
  itemEsitmatedHeight: number;
  fetchNextPage: () => void;
  renderItem: (item: T, index: number) => JSX.Element;
}

export const List = <T extends object>({
  data,
  hasNextPage,
  isLoading,
  isFetchingNextPage,
  totalCount,
  itemEsitmatedHeight,
  fetchNextPage,
  renderItem,
}: ListProps<T>) => {
  const parentRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: totalCount,
    getScrollElement: () => parentRef.current,
    estimateSize: () => itemEsitmatedHeight || 100,
    overscan: 5,
  });

  useFetchNextPageOnEnd({
    fetchNextPage,
    hasNextPage: hasNextPage || false,
    isFetchingNextPage,
    rowVirtualizer,
    data,
  });

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Loader />
      </Box>
    );
  }

  return (
    <div
      ref={parentRef}
      className="List"
      style={{
        height: `100%`,
        width: `100%`,
        overflow: 'auto',
      }}
    >
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: '100%',
          position: 'relative',
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualRow) => {
          const isLoaderRow = virtualRow.index > data.length - 1;
          const item = data[virtualRow.index];

          return (
            <div
              key={virtualRow.index}
              className={virtualRow.index % 2 ? 'ListItemOdd' : 'ListItemEven'}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: `${virtualRow.size}px`,
                transform: `translateY(${virtualRow.start}px)`,
              }}
            >
              {isLoaderRow && hasNextPage && (
                <Box sx={{ display: 'flex', justifyContent: 'center', pt: 4 }}>
                  <CircularProgress />
                </Box>
              )}

              {!isLoaderRow && renderItem(item, virtualRow.index)}
            </div>
          );
        })}
      </div>
    </div>
  );
};
